import React, { useEffect } from 'react'
import {
  FaCalendar,
    FaEdit,
    FaPlus,
    FaTrashAlt
  } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { useState } from 'react';
import swal from 'sweetalert';
import { BASEURL } from './BASEURL';
import Swal from 'sweetalert2';
import DatePicker from "react-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';


const PunchTicket = () => {

    const [addShow,setaddShow] = useState(false);
    const [showTicket, setShowticket] = useState([])
    const [userdata] = useState(JSON.parse(localStorage.getItem("isAuth")));
    const [updateShow, setUpdateShow] = useState(false)


    const [data, setdata] = useState({
      
      date : format(new Date(), "yyyy-MM-dd"),
       
        punch_start_time: "00:00:00",
        punch_end_time: "00:00:00",
        lunch_start_time: "00:00:00",
        lunch_end_time: "00:00:00",
        reason: "",
        status: 0,       
      });

    
    const handleAdd = () =>{
        setaddShow(true);
        setUpdateShow(false)
        // setaddShow(false);
    }
    const handleCancel = () =>{
        setaddShow(false);
        setUpdateShow(false)
        setdata({
          date: "",    
          punch_start_time: "00:00:00",
          punch_end_time: "00:00:00",
          lunch_start_time: "00:00:00",
          lunch_end_time: "00:00:00",
          reason: "",
          status: 0,       
        });
    }
    const handlechange = (e) => {
        let value = e.target.value;
        setdata({
          ...data,
          [e.target.name]: value,
        });
      };

      const handleDatechage = (date) => {
       let sdate = format(new Date(date), "yyyy-MM-dd")

       setdata({
         ...data,
          date: sdate, 
        });
       

        // const adjustedDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    };
    
    const handleSubmit = async(e) => {
     setaddShow(false)
        e.preventDefault();
        try {
            const response = await fetch(BASEURL + 'add-emp-ticket', {
              method: 'POST', 
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({data, username: userdata.username})
            });
            if (response.ok) {
                        setdata({
                            date: "",    
                            punch_start_time: "00:00:00",
                            punch_end_time: "00:00:00",
                            lunch_start_time: "00:00:00",
                            lunch_end_time: "00:00:00",
                            reason: "",
                            status: 0,       
                          });
                          GetempTicket();
            }else if(!response.ok){
                throw new Error('Failed to submit form data');
            }
            swal({
              title: "Success",
              text: "Attendance ticket added successfully",
              icon: "success",
              timer: 2000,
            });
          } catch (error) {
            console.error('Error submitting form data:', error)
          }
              
    }
    const GetempTicket = async () => {
        try {
            const response = await fetch(BASEURL + 'show-emp-ticket', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
             body: JSON.stringify({username: userdata.username, role:userdata.role})
            });
            if (response.ok) {
             
                const data = await response.json();
                setShowticket(data?.data)
                // console.log('Response data:', data);
            } else {
                throw new Error('Failed to fetch data');
            }
    
        } catch (error) {
            console.error('Error fetching data:', error);
    };
}

const handleDelete = (id) =>{
   
    // console.log("delete this id data:", id);
    swal({
        title: "Delete",
        text: `Are you sure you want to delete you ticket?`,
        buttons: ["No", "Yes"],
        showCloseButton: true,
      }).then((willDelete) => {
        if (willDelete) {
          fetch(BASEURL + `delete-emp-ticket/${id}`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json'
            },
          })
            .then((response) => response.json())
            .then((res) => {
              GetempTicket();
              swal({
                title: "Success",
                text: res.msg,
                icon: "success",
                timer: 2000,
              });
            })
            .catch((err) => {
              console.log(err);
            });
         
        } else {
      
        }
      });
}
const editdata = (ticket) => {
    console.log("editing data of emp id", ticket);
    setaddShow(true);
    setUpdateShow(true)
    setdata({
        id:ticket.id,
        date: ticket.date,    
        punch_start_time: ticket.punch_start_time,
        punch_end_time: ticket.punch_end_time,
        lunch_start_time: ticket.lunch_start_time,
        lunch_end_time: ticket.lunch_end_time,
        reason: ticket.reason,  
        status: 0,    
    })
   
  };

  const UpdateTicket = async (id) => {
    // e.preventDefault();
    console.log("updating ticket id : " + id);
    try {
      const response = await fetch(BASEURL + `update-emp-ticket/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const data = await response.json();
     if(response)
        {
      Swal.fire({
        icon: "success",
        title: "Ticket updated successfully",
        showConfirmButton: false,
        timer: 1000
      });
      GetempTicket();
    }
    else{
     console.log("Error your leave request is not updated")
    }
    } catch (error) {
      console.error("Error:", error);
    }
  };

    useEffect(()=>{
      
     GetempTicket();
    },[])

    const [currentPage, setCurrentPage] = useState(0);
  const [dataPerPage] = useState(10);

  const indexOfLastData = (currentPage + 1) * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage; 
  const currentData = showTicket.slice(indexOfFirstData, indexOfLastData);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };


  return (
    <div className="intro-y box lg:mt-5">
      <div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
        <h2 className="font-medium text-base mr-auto">Add Punch Ticket</h2> 
        <button className="btn btn-primary btn-md mr-2" onClick={() => handleAdd()}>Add Ticket</button>
      </div>
      <div className="p-5">
        <div className="flex flex-col-reverse xl:flex-row flex-col text-left">
        
      
        {addShow === true ? ( <div className="flex-1 mt-6 xl:mt-0 p-3 mb-2" style={{ border: "1px solid lightgray", backgroundColor: "rgb(var(--color-slate-100) / var(--tw-bg-opacity))", borderRadius: "5px" }}>
              <form method='POST' onSubmit={handleSubmit}>
                <div className="grid grid-cols-12 gap-x-5">
                    <div className="col-span-12 2xl:col-span-2 mt-3 mb-3">
                      <div>
                      <label
                        htmlFor="update-profile-form-2"
                        className="form-label"
                      >
                        Date 
                      </label>
                      <DatePicker
                        value={data.date}
                    clearIcon=""
                    onChange={handleDatechage}
                    name="date"
                    calendarIcon={
                      <FaCalendar style={{ color: "rgb(30 64 175)" }} />
                    }
                    className='form-control '
                    // timeZone="Asia/kolkata "
                  />
                     </div>
                  </div>
                  <div className="col-span-12 2xl:col-span-2 mt-3 mb-3">
                    <div>
                      <label
                        htmlFor="update-profile-form-1"
                        className="form-label"
                      >
                        Punch Start Time <span className="text-danger">(*)</span> 
                      </label>
                      <input
                        id="update-profile-form-1"
                        type="text"
                        required
                        name="punch_start_time"
                        className="form-control"
                        onChange={handlechange}
                        value={data.punch_start_time}
                      />
                    </div>
                  </div>
                 
                  <div className="col-span-12 2xl:col-span-2 mt-3 mb-3">
                    <div>
                      <label
                        htmlFor="update-profile-form-1"
                        className="form-label"
                      >
                        Punch End Time <span className="text-danger">(*)</span> 
                      </label>
                      <input
                        id="update-profile-form-1"
                        type="text"
                        required
                        name="punch_end_time"
                        className="form-control"
                        onChange={handlechange}
                        value={data.punch_end_time}
                      />
                    </div>
                  </div>

                  <div className="col-span-12 2xl:col-span-2 mt-3 mb-3">
                    <div>
                      <label
                        htmlFor="update-profile-form-1"
                        className="form-label"
                      >
                        Lunch Start Time <span className="text-danger">(*)</span> 
                      </label>
                      <input
                        id="update-profile-form-1"
                        type="text"
                        required
                        name="lunch_start_time"
                        className="form-control"
                        onChange={handlechange}
                        value={data.lunch_start_time}
                        
                      />
                    </div>
                  </div>
                  <div className="col-span-12 2xl:col-span-2 mt-3 mb-3">
                    <div>
                      <label
                        htmlFor="update-profile-form-1"
                        className="form-label"
                      >
                        Lunch End Time <span className="text-danger">(*)</span> 
                      </label>
                      <input
                        id="update-profile-form-1"
                        type="text"
                        required
                        name="lunch_end_time"
                        className="form-control"
                        onChange={handlechange}
                        value={data.lunch_end_time}
                      />
                    </div>
                  </div>

                  <div className="col-span-12 2xl:col-span-3">
                    <label htmlFor="update-profile-form-2" className="form-label">
                      Reason <span className="text-danger">(*)</span> 
                    </label>
                    <br />
                    <textarea
                      name="reason"
                      className="form-control"
                      required
                      cols="80"
                      rows="2"
                      onChange={handlechange}
                      value={data.reason}
                    ></textarea>
                  </div>
                  
                
                  </div>

                  {updateShow &&(<button
                  type="submit"
                  className="btn btn-success text-white mr-2 w-20 mt-3"
                  onClick={UpdateTicket}
                >
                  Update
                </button>)}
               {!updateShow &&(<button
                  type="submit"
                  className="btn btn-primary w-20 mt-3"
                >
                  Save
                </button>)}
                <button
                  type="button"
                  onClick={() => handleCancel()}
                  className="btn btn-danger w-20 mt-3 ml-2"
                >
                  Cancel
                </button>
                <br />
                <br />
                <span
                  className={
                    // msg.type === "success" ? "text-success" : "text-danger"
                    "text-success"
                  }
                >
                 
                </span>
              </form>
            </div> ) : null}
        
        </div>
        <div className="flex flex-col sm:flex-row sm:items-end xl:items-start mb-4">
          <div className="flex mt-5 sm:mt-0">
            {/* <div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <input
                id="tabulator-html-filter-value"
                type="text"
               
                className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                placeholder="Search..."
              />
            </div> */}
          </div>
          <div className="hidden md:block ml-auto text-slate-500">
          </div>
        </div>
        <div className="overflow-x-auto">
  
            {/* <HashLoader
              color="#5755d9"
              size={30}
              style={{ position: "absolute", right: "50%" }}
            /> */}
     
            <table className="table table-bordered" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th className="whitespace-nowrap">Date</th>
                  <th className="whitespace-nowrap">Punch Start </th>
                  <th className="whitespace-nowrap">Punch End </th>
                  <th className="whitespace-nowrap">Lunch Start </th>
                  <th className="whitespace-nowrap">Lunch End </th>
                  <th className="whitespace-nowrap">Reason</th>
                  <th className="whitespace-nowrap">status</th>
                  <th className="whitespace-nowrap">Action</th>
                </tr>
              </thead>
              <tbody>
              {currentData.map((ticket, index) => (
      <tr key={index}>
         {/* {console.log("Ticket date:", ticket.date)} */}
        <td>{format(new Date(ticket.date), "yyyy-MM-dd")}</td>
        <td>{ticket.punch_start_time}</td>
        <td>{ticket.punch_end_time}</td>
        <td>{ticket.lunch_start_time}</td>
        <td>{ticket.lunch_end_time}</td>
        <td>{ticket.reason}</td>
        <td>
          {ticket.status === 0 && <button className="btn btn-warning btn-sm">Pending</button>}
          {ticket.status === 1 && <button className="btn btn-success text-white btn-sm">Approved</button>}
          {ticket.status === 2 && <button className="btn btn-danger btn-sm">Rejected</button>}
        </td>
        <td>
          <div className='flex'>
            <FaEdit className="text-primary ml-2" onClick={() => editdata(ticket)}/>
            <FaTrashAlt className="text-danger ml-2" onClick={()=>handleDelete(ticket.id)}/>
          </div>
        </td>
      </tr>  ))}
              </tbody>
            </table>
      

          <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
            <br />
            <nav className="w-full sm:w-auto sm:mr-auto">
            
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-4">
                  <ReactPaginate
        // previousLabel={"<<"}
        // nextLabel={">>"}
        breakLabel={"..."}
        pageCount={Math.ceil(showTicket.length / dataPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        activeClassName={"active"}
        pageClassName={"page-item"} // Style for pagination numbers
        previousClassName={"page-item"}
        nextClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousLinkClassName={"page-link"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        previousLabel={<>&laquo;</>}
        nextLabel={<>&raquo;</>}
      />
                  </div>
                  <div className="col-md-4"></div>
                </div>
           
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PunchTicket
