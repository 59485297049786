import React, { useEffect, useState } from "react";
import { BASEURL } from "./BASEURL";

import { FaCalendar, FaTimes } from "react-icons/fa";
import HashLoader from "react-spinners/HashLoader";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { format } from "date-fns";
import ReactMonthPicker from "react-month-picker";
import "react-month-picker/css/month-picker.css";
import Modal from "react-modal";
import { useLoadingContext } from "react-router-loading";
Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px",
    border: "1px solid rgb(187, 181, 181)",
    borderRadius: "14px",
    overflowX: "hidden",
    overflowY: "visible",
    width: "600px",
  },
};

const Leaves = () => {
  const loadingContext = useLoadingContext();
  let today = new Date();
  let month = [];
  month[0] = "1";
  month[1] = "2";
  month[2] = "3";
  month[3] = "4";
  month[4] = "5";
  month[5] = "6";
  month[6] = "7";
  month[7] = "8";
  month[8] = "9";
  month[9] = "10";
  month[10] = "11";
  month[11] = "12";

  let monthNumber = month[today.getMonth()];

  let [loading, setLoading] = useState(false);
  const [attdata, setattdata] = useState(null);
  const [leavedata, setleavedata] = useState({});
  const [isVisible, setisVisible] = useState(false);
  const [monthYear, setmonthYear] = useState({});
  const currmonth = monthNumber;
  const [isopen, setIsOpen] = useState(false);
  const curryear = today.getFullYear();

  useEffect(() => {
    getData({
      year: format(new Date(), "yyyy"),
      month: format(new Date(), "MM"),
    });
   
  }, []);

  const columns = [
    {
      title: "Employee",
      field: "employee",
      formatter: reactFormatter(<DetailVal />),
    },
    { title: "Day", field: "day" },
    {
      title: "Type",
      field: "type",
      width: 150,
      formatter: reactFormatter(<TypeVal />),
    },
    {
      title: "Status",
      field: "status",
      width: 120,
      formatter: reactFormatter(<StatusVal />),
    },
    { title: "From", field: "from" },
    { title: "To", field: "to" },
    { title: "Applied On", field: "appliedon" },
  ];

  function StatusVal(props) {
    const rowData = props.cell._cell.row.data;
    return (
      <>
        {rowData.status === 1 ? (
          <>
            <button className="btn btn-success btn-sm text-white">
              Approved
            </button>
          </>
        ) : rowData.status === 0 ? (
          <button
            onClick={() => ApproveLeave(rowData)}
            className="btn btn-danger btn-sm text-white"
          >
            Pending
          </button>
        ) : null}
      </>
    );
  }

  function ApproveLeave(rowData) {
    setleavedata(rowData);
    setIsOpen(true);
  }

  function DetailVal(props) {
    const rowData = props.cell._cell.row.data;
    if (rowData.employee !== "") {
      return (
        <a
          href={"employees/" + rowData.uuid}
          rel="noreferrer"
          target="_blank"
          alt=""
        >
          <u>{rowData.employee}</u>
        </a>
      );
    } else {
      return "No data Available";
    }
  }

  function TypeVal(props) {
    const rowData = props.cell._cell.row.data;
    if (rowData.employee === "") {
      return null;
    } else {
      let result = rowData ? rowData.type.split(",") : null;
      let resultcount = rowData ? rowData.leavecountbytype.split(",") : null;
      let typearr = [];
      result &&
        result.forEach((element) => {
          if (element === "LWP") {
            typearr.push({ type: element + " : " + resultcount[0] });
          } else if (element === "PL") {
            typearr.push({ type: element + " : " + resultcount[1] });
          } else if (element === "SL") {
            typearr.push({ type: element + " : " + resultcount[2] });
          } else if (element === "CL") {
            typearr.push({ type: element + " : " + resultcount[3] });
          } else if (element === "PTL") {
            typearr.push({ type: element + " : " + resultcount[4] });
          } else if (element === "MTL") {
            typearr.push({ type: element + " : " + resultcount[5] });
          } else {
            typearr.push({ type: "-" });
          }
        });

      return (
        <>
          {typearr &&
            typearr.map((item, i) => {
              return (
                <span className="ml-2">
                  <span className="leavetype">{item.type}</span>
                </span>
              );
            })}
        </>
      );
    }
  }

  const options = {
    pagination: "local",
    paginationSize: 50,
  };

  const getData = async ({ year, month }) => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-monthwise-leaves/all`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        month,
        year,
        type: 1,
        uuid: JSON.parse(localStorage.getItem("isAuth")).uuid,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          let attarr = [];
          if (res.data.length > 0) {
            res.data.forEach((element) => {
              let data = {
                uuid: element.uuid,
                leave_id: element.request_id,
                employee: element.name,
                type: element.leave_type,
                status: element.hrstatus,
                day:
                  element.noofdays > 1
                    ? element.noofdays + " Days"
                    : element.noofdays + " Day",
                from: format(new Date(element.start_date), "yyyy-MM-dd"),
                to: format(new Date(element.end_date), "yyyy-MM-dd"),
                appliedon: format(
                  new Date(element.request_date),
                  "yyyy-MM-dd HH:MM:SS"
                ),
                leavecountbytype: element.leavecountbytype,
                user_id: element.user_id,
                reason: element.reason,
              };

              attarr.push(data);
              // console.log(attarr);
            });
          }
          setattdata(attarr);
        } else {
          setattdata([
            {
              employee: "",
            },
          ]);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
    loadingContext.done();
  };

  const handleOnDismiss = () => {
    setisVisible(false);
  };

  const handleOnChange = (year, month) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let monthname = monthNames[month - 1];
    setisVisible(false);
    setmonthYear({ year, month: monthname });

    getData({ year, month });
  };

  const showMonthPicker = (e) => {
    setisVisible(true);
    e.preventDefault();
  };

  const getMonthValue = () => {
    const month = monthYear && monthYear.month ? monthYear.month : 0;
    const year = monthYear && monthYear.year ? monthYear.year : 0;

    let date = new Date();
    let curryear = date.getFullYear();

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return month && year
      ? `${month}-${year}`
      : monthNames[currmonth - 1] + "-" + curryear;
  };

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <>
      <div className="intro-y flex flex-col sm:flex-row items-center">
        <h2 className="text-lg font-medium mr-auto">All Leaves</h2>
      </div>
      <div className="intro-y box p-5 mt-5">
        <div className="flex flex-col sm:flex-row sm:items-end xl:items-start">
          <div className="flex mt-5 sm:mt-0">
            <div className="input-group mr-2 calenderdate">
              <input
                type="text"
                style={{ fontSize: "12px" }}
                onFocus={(e) => showMonthPicker(e)}
                value={getMonthValue()}
                className="form-control calenderdate1"
                id="basic-url"
                aria-describedby="basic-addon3"
                onChange={() => {}}
              />
              <div className="input-group-append calendaricon">
                <FaCalendar
                  style={{ fontSize: "12px" }}
                  onClick={(e) => showMonthPicker(e)}
                />
              </div>
            </div>
            <ReactMonthPicker
              show={isVisible}
              lang={[
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ]}
              value={{ year: curryear, month: currmonth }}
              onChange={handleOnChange}
              onDismiss={handleOnDismiss}
            />
          </div>
        </div>
        <div className="overflow-x-auto scrollbar-hidden">
          {loading ? (
            <div className="text-center m-5 p-5">
              <HashLoader
                color="#5755d9"
                size={30}
                style={{ position: "absolute", right: "50%" }}
              />
            </div>
          ) : (
            <ReactTabulator
              columns={columns}
              data={attdata}
              options={options}
              className="mt-5 table-report table-report--tabulator"
            />
          )}
        </div>
      </div>

      <Modal isOpen={isopen} style={customStyles} contentLabel="">
        <div className="box">
          <div className="flex flex-col sm:flex-row items-center p-3 border-b border-slate-200/60 dark:border-darkmode-400">
            <h2 className="font-medium text-base mr-auto">Approve Leave</h2>
            <button
              className="btn btn-outline-secondary hidden sm:flex"
              onClick={() => closeModal()}
            >
              <FaTimes />
            </button>
          </div>
          <div id="vertical-form" className="p-3">
            <div className="preview">
              <div>
                <label for="vertical-form-1" className="form-label">
                  Reason
                </label>
                <input
                  id="vertical-form-1"
                  type="text"
                  className="form-control"
                  disabled
                  value={leavedata.reason}
                />
              </div>
              <div className="mt-3">
                <label for="vertical-form-2" className="form-label">
                  Password
                </label>
                <input
                  id="vertical-form-2"
                  type="text"
                  className="form-control"
                  placeholder="secret"
                />
              </div>

              <button className="btn btn-primary mt-5">Approve</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Leaves;
