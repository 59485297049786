import React, { useState, useEffect } from "react";
import { BASEURL,SITEURL } from "../BASEURL";
import ReactPaginate from "react-paginate";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert2";
import HashLoader from "react-spinners/HashLoader";
import {
  FaCalendar,
  FaClone,
  FaFileDownload, FaPodcast, FaTasks, FaUserAlt
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Select from "react-select";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";
import { format } from "date-fns";

const Departments = () => {
  const [appdata, setappdata] = useState(null);
  const [bkpdata, setbkpdata] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [items, setitems] = useState([]);
  const [posdata, setposdata] = useState([]);
  const [pos, setpos] = useState("");
  const [InterviewStatus, setInterviewStatus] = useState(0);
 
  let [loading, setLoading] = useState(false);
  // let [msg, setmsg] = useState(false);
  let [master, ] = useState(JSON.parse(localStorage.getItem("isAuth")).master);
  // const [datevalue, onChange] = useState([
  //   new Date(),
  //   new Date(),
  // ]);

  const [currdate] = useState(new Date());
  const [datevalue, onChange] = useState([
    new Date(currdate.getFullYear(), currdate.getMonth(), 1),
    new Date(),
  ]);

  useEffect(() => {
    const endOffset = itemOffset + 20;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / 20));
    getPosData()
    getData(
      format(new Date(datevalue[0]), "yyyy-MM-dd"),
      format(new Date(datevalue[1]), "yyyy-MM-dd")
    );
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + 20;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / 20));
  }, [itemOffset]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 20) % items.length;

    setItemOffset(newOffset);
  };

  const furtherrounds = async(uuid) => {
    // alert(http://localhost:3000/view-job-application-status/900875de-0b17-4763-8eee-f032bbdb740b)
    await navigator.clipboard.writeText(SITEURL+'view-job-application-status/'+uuid);
    // alert(uuid)
    // swal({
    //   title: "Are you sure",
    //   text: `You want to send a link for further rounds to ${name}?`,
    //   buttons: ["No", "Yes"],
    //   showCloseButton: true,
    // }).then((willDelete) => {
    //   if (willDelete) {
    //     setLoading(true);
    //     const myHeaders = new Headers();
    //     let userdata = JSON.parse(localStorage.getItem("isAuth"));
    //     if (userdata != null) {
    //       let token = userdata.usertoken;
    //       myHeaders.append("Authorization", token);
    //     }
    //     myHeaders.append("Content-Type", "application/json");
    //     fetch(BASEURL + `delete-user/${uuid}`, {
    //       method: "DELETE",
    //       headers: myHeaders,         
    //     })
    //       .then((response) => response.json())
    //       .then((res) => {
            swal.fire({
              title: "URL copy success. Please send url to candidate to further rounds",
              icon: "success",
              timer: 3000,
            });
    //       })
    //       .catch((err) => console.log(err));
    //     setLoading(false);
    //   }
    // });
  };
 
  const interviewStatus = async(uuid,status,comment) => {

    // const inputOptions = new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve({
    //       "0": "Pending",
    //       "1": "Called",
    //       "2": "HR Round",
    //       "3": "Technical Round",
    //       "4": "Practical Round",
    //       "5": "Final Round",
    //       "6": "Hired",
    //       "7": "Rejected"
    //     });
    //   }, 1000);
    // });

    let htmldata = `<input type="radio" name="options" id="option1" value="0" ${status==0 && "checked"}>
        <label for="option1">Pending</label>
        <input type="radio" name="options" id="option2" value="1" ${status==1 && "checked"}>
        <label for="option2">Called</label>
        <input type="radio" name="options" id="option3" value="2" ${status==2 && "checked"}>
        <label for="option3">HR Round</label>
        <input type="radio" name="options" id="option3" value="3" ${status==3 && "checked"}>
        <label for="option3">Technical Round</label>
        <input type="radio" name="options" id="option3" value="4" ${status==4 && "checked"}>
        <label for="option3">Practical Round</label>
        <input type="radio" name="options" id="option3" value="5" ${status==5 && "checked"}>
        <label for="option3">Final Round</label>
        <input type="radio" name="options" id="option3" value="6" ${status==6 && "checked"}>
        <label for="option3">Hired</label>
        <input type="radio" name="options" id="option3" value="7" ${status==7 && "checked"}>
        <label for="option3">Rejected</label>        
        <br/><br/>
        <textarea id="inputBox" class="swal2-input" rows="3" style="width: 100%;" placeholder="Enter your comment">${comment}</textarea>`;

    const { value: fianlval } = await swal.fire({
      title: "Are you sure you want to change interview status?",
      width:1200,
      fontSize:10,
      // input: "radio",
      // inputValue:+status, 
      // inputOptions,
      confirmButtonText: "Yes, change it!",
      cancelButtonText: "No, cancel!",
      showCloseButton: true,
      title: 'Choose an option',
      html: htmldata,
      preConfirm: () => {
        const selectedOption = document.querySelector('input[name="options"]:checked');
        const inputBox = document.getElementById('inputBox').value;
        if (!selectedOption) {
          // Swal.showValidationMessage('Please select an option');
          alert("Please Select option")
          return false;
        }
        if (!inputBox) {
          // Swal.showValidationMessage('Please enter some text');
          alert("Please Enter comment")
          return false;
        }
        return {
          option: selectedOption.value,
          comment: inputBox
        };
      },
      // inputValidator: (value) => {
      //   if (!value) {
      //     return "You need to choose something!";
      //   }
      // }
    });
    if (fianlval) {
      // console.log(fianlval);return
      // swal.fire({ html: `You selected: ${fianlval}` });
      setLoading(true);
        const myHeaders = new Headers();
        let userdata = JSON.parse(localStorage.getItem("isAuth"));
        if (userdata != null) {
          let token = userdata.usertoken;
          myHeaders.append("Authorization", token);
        }
        myHeaders.append("Content-Type", "application/json");
        fetch(BASEURL + `interview_status/${uuid}/${fianlval.option}`, {
          method: "POST",
          headers: myHeaders, 
          body:JSON.stringify({comment:fianlval.comment})        
        })
          .then((response) => response.json())
          .then((res) => {
             swal.fire({
              title: res.msg,
              icon: "success",
              timer: 3000,
            });
            getData();
          })
          .catch((err) => console.log(err));
        setLoading(false);
      }
  };

  const getPosData = async (org="") => {
    // setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `getAllPositions/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        type:1,
        username: JSON.parse(localStorage.getItem("isAuth")).username,        
        company_uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,        
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setposdata(res.data);
          // let data4 = res.alluser ? res.alluser : [];
          // setalluser(data4);
        }
      })
      .catch((err) => console.log(err));
  };
  
  let data = [{ value: "", label: "Select All", active: "" }];
  for (let i = 0; i < posdata.length; i++) {
    // console.log(posdata[i].uuid);
    data.push({
      value: posdata[i].uuid,
      label: posdata[i].name,      
    });
  }

  const getData = async () => {

    setLoading(true);
    const myHeaders = new Headers();
    let appdata = JSON.parse(localStorage.getItem("isAuth"));
    if (appdata != null) {
      let token = appdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    // console.log(pos);
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `getAllJobApp/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        company_uuid: appdata.companydata[0].uuid,
        username: appdata.username,
        type: appdata.type,
        date:datevalue,
        position:pos!=="" ? pos.value :"",
        InterviewStatus
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          let arr = [];
          let arr1 = [];
          for (const key in res.data) {
            arr.push(key);
            if (key < 20) {
              arr1.push(key);
            }
          }
          setitems(arr);

          setCurrentItems(arr1);

          setPageCount(Math.ceil(arr.length / 20));

          setbkpdata(res.data);
          setappdata(res.data);
        } else {
          setappdata({});
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const filterdata = (event) => {
    if (event.target.value === "") {
      loading ? <HashLoader /> : setappdata(bkpdata);

      let arr = [];
      for (const key in bkpdata) {
        arr.push(key);
      }
      setitems(arr);

      setCurrentItems(arr);
      setPageCount(Math.ceil(arr.length / 20));
    } else {
      var matches = bkpdata.filter(function (s) {
        return s.name.toLowerCase().includes(`${event.target.value}`) ||
         s.contactno.toLowerCase().includes(`${event.target.value}`) ||
         s.email.toLowerCase().includes(`${event.target.value}`) ||
         s.position.toLowerCase().includes(`${event.target.value}`) ||
         s.noticeperiod.toLowerCase().includes(`${event.target.value}`) ||
         s.noticeperiod.toLowerCase().includes(`${event.target.value}`) ||
         s.city.toLowerCase().includes(`${event.target.value}`)
      });

      let arr = [];
      for (const key in matches) {
        arr.push(key);
      }
      setitems(arr);

      const newOffset = (matches.length * 20) % matches.length;
      setItemOffset(newOffset);
      setappdata(matches);
      const endOffset = itemOffset + 20;
      setCurrentItems(arr.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(matches.length / 20));
    }
  };

 const fun =(src)=>{
      var w = window.open('about:blank');  
      setTimeout(function(){ //FireFox seems to require a setTimeout for this to work.
        w.document.body.appendChild(w.document.createElement('iframe')).src = src;
        w.document.body.style.margin = 0;
        w.document.getElementsByTagName("iframe")[0].style.width = '100%';
        w.document.getElementsByTagName("iframe")[0].style.height = '100%';
        w.document.getElementsByTagName("iframe")[0].style.border = 0;
      }, 0);
  }

  const handledate = (e) => {
    onChange(e)    
  };
  // console.log(posdata);
  return (
    <div className="intro-y box lg:mt-5">
      <div className="flex items-center border-b border-slate-200/60 dark:border-darkmode-400">
        <h2 className="font-medium text-base p-2 mr-auto">Job Applications</h2>        
      </div>
      <div className="p-5">
      <div className="flex flex-col sm:flex-row sm:items-end xl:items-start mb-4">
          <div className="flex mt-5 sm:mt-0">
            <div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <input
                id="tabulator-html-filter-value"
                type="text"
                onChange={filterdata}
                className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                placeholder="Search..."
              />              
            </div>
          </div>
          <div className="lg:ml-2  ">
          <Select
                        defaultValue={posdata[0]}
                        value={pos}

                        className="basic-single text-center mr-2"
                        onChange={(e) => {
                          setpos(e);
                          // getData(e,"")
                        }}
                        options={data}
                        getOptionLabel={(option) =>
                          `${option.label}`
                        }
                        styles={{
                          option: (base) => ({
                            ...base,
                            height: "100%",
                            display: "flex",
                          }),
                        }}
                      />
          </div>   
          <div className="lg:ml-2  ">
            <select className="form-control" onChange={(e)=>{
              setInterviewStatus(e.target.value);
            }}>
                  <option value={0} selected>Pending</option>
                  <option value={1}>Called</option>
                  <option value={2}>HR Round</option>
                  <option value={3}>Technical Round</option>
                  <option value={4}>Practical Round</option>
                  <option value={5}>Final Round</option>
                  <option value={6}>Hired</option>
                  <option value={7}>Rejected</option>
            </select>
          </div>   
          <div className="lg:ml-2  ">
          <DateRangePicker
              
                  clearIcon=""
                  calendarIcon={
                    <FaCalendar style={{ color: "rgb(30 64 175)" }} />
                  }
                  className="form-control"
                  onChange={handledate}
                  value={datevalue}
                /></div>                   
                <button type="button" onClick={(e)=>getData(e)} class="btn btn-primary mx-2 pt-2 btn-sm invisible sm:visible">Get Data</button>
                <button type="button" onClick={(e)=>{
                setpos("");
                onChange([
                  format(new Date(datevalue[0]), "yyyy-MM-dd"),
                  format(new Date(datevalue[1]), "yyyy-MM-dd")
                ])
                }} class="btn btn-danger mx-3 pt-2 btn-sm invisible sm:visible">Clear</button>
          {/* <Link to={'/job-questionaries'}>
          
          </Link> */}
         
          {/* <div className="hidden md:block ml-auto text-slate-500">
            Showing {itemOffset + 1} to  {appdata.length > itemOffset + 20 ? itemOffset + 20 : appdata.length} of {appdata.length} entries
          </div> */}
        </div>
        <div className="overflow-x-auto">
          {loading ? (
            <HashLoader
              color="#5755d9"
              size={30}
              style={{ position: "absolute", right: "50%" }}
            />
          ) : (
            <table className="table table-bordered" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th className="whitespace-nowrap" style={{width:"20px"}}>Name</th>
                  <th className="whitespace-nowrap" >Email ID</th>
                  <th className="whitespace-nowrap">Contact Number</th>
                  {/* <th className="whitespace-nowrap">City</th> */}
                  {/* <th className="whitespace-nowrap">Address</th> */}
                  <th className="whitespace-nowrap">Position</th>
                  {/* <th className="whitespace-nowrap">Notice Period</th>                   */}
                  <th className="whitespace-nowrap">Assessment <br /> Status</th>                  
                  <th className="whitespace-nowrap">Interview<br /> Status</th>                  
                  <th className="whitespace-nowrap">Status<br /> Datetime</th>                  
                  <th className="whitespace-nowrap">Ref By</th>                

                  <th className="whitespace-nowrap">Date Time</th>                  
                  <th className="whitespace-nowrap">Actions</th> 
                </tr>
              </thead>
              <tbody>
              {currentItems &&
                  currentItems.length === 0 && 
                    <tr><td colSpan="8">No data available</td></tr>
                }
                {currentItems &&
                  currentItems.length > 0 &&
                  currentItems.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                        <Link title="Application Details" to={'/appdetails/'+appdata[item].uuid}>
                        <u>{appdata[item].name}</u></Link></td>
                        <td style={{width:"30px"}}>{appdata[item].email}</td>
                        <td>{appdata[item].contactno}</td>
                        {/* <td>{appdata[item].city===0?"Ahmedabad":appdata[item].city===0?"Gandhinagar":"Other"}</td> */}
                        {/* <td>{appdata[item].address}</td> */}
                        <td>
                        {appdata[item].position}
                        </td>
                        <td>
                        {appdata[item].isassessmentadded==1
                        ?<span className="btn btn-success btn-sm text-white" style={{fontSize:"10px"}}>Done</span>
                        :<span className="btn btn-warning btn-sm" style={{fontSize:"10px"}}>Pending</span> }
                        </td>
                        <td>
                        {appdata[item].interviewStatus===0
                        ?<><span onClick={()=>interviewStatus(appdata[item].uuid, appdata[item].interviewStatus, appdata[item].comment)} className="btn btn-warning btn-sm" style={{fontSize:"10px"}}>Pending</span>
                        </>
                        : appdata[item].interviewStatus===1 ?
                        <><span onClick={()=>interviewStatus(appdata[item].uuid, appdata[item].interviewStatus, appdata[item].comment)} className="btn btn-default btn-sm" style={{fontSize:"10px"}}>Called</span> </>
                        : appdata[item].interviewStatus===2 ?
                        <><span onClick={()=>interviewStatus(appdata[item].uuid, appdata[item].interviewStatus, appdata[item].comment)} className="btn btn-primary btn-sm" style={{fontSize:"10px"}}>HR Round</span> </>
                        : appdata[item].interviewStatus===3 ?
                        <><span onClick={()=>interviewStatus(appdata[item].uuid, appdata[item].interviewStatus, appdata[item].comment)} className="btn btn-primary btn-sm" style={{fontSize:"10px"}}>Technical Round</span> </>
                        : appdata[item].interviewStatus===4 ?
                        <><span onClick={()=>interviewStatus(appdata[item].uuid, appdata[item].interviewStatus, appdata[item].comment)} className="btn btn-primary btn-sm" style={{fontSize:"10px"}}>Practical Round</span> </>
                        : appdata[item].interviewStatus===5 ?
                        <><span onClick={()=>interviewStatus(appdata[item].uuid, appdata[item].interviewStatus, appdata[item].comment)} className="btn btn-success btn-sm" style={{fontSize:"10px"}}>Final Round</span> </>
                        : appdata[item].interviewStatus===6 ?
                        <><span onClick={()=>interviewStatus(appdata[item].uuid, appdata[item].interviewStatus, appdata[item].comment)} className="btn btn-success btn-sm" style={{fontSize:"10px"}}>Hired</span> </>
                        : <><span onClick={()=>interviewStatus(appdata[item].uuid, appdata[item].interviewStatus, appdata[item].comment)} className="btn btn-danger btn-sm text-white" style={{fontSize:"10px"}}>Rejected</span></>}
                        </td>
                       
                        <td>{appdata[item].interviewStatus_datetime!="undefined" ? format(new Date(appdata[item].interviewStatus_datetime), "yyyy-MM-dd HH:mm:ss"):"-"}</td>
                        <td>{appdata[item].knowvavancy!="undefined" ? appdata[item].knowvavancy:"-"}</td>
                       
                        <td>{appdata[item].applicationdate}</td>
                       
                        <td >
                          {/* <button className="btn btn-primary btn-sm p-1"> */}
                          <div style={{
                            display: "flex"
                          }}>
                           <FaClone onClick={()=>furtherrounds(appdata[item].uuid)} className="cursor-pointer ml-3 mr-3" style={{fontSize:"17px"}} title="Send a link for further rounds"/>
                           {/* <FaPodcast onClick={()=>furtherrounds(appdata[item].uuid,appdata[item].name)} className="cursor-pointer ml-3 mr-3" style={{fontSize:"17px"}} title="Send a link for further rounds"/> */}

                            <span className="mr-2">
                            {appdata[item].resume!=="" || appdata[item].cvname!==""? 
                          <Link title="Job Assessment" to={'/job-assessment/'+appdata[item].uuid}>
                            <FaTasks className="cursor-pointer" style={{fontSize:"17px"}}/>
                          </Link>:null }
                            </span>

                            <span>
                            {appdata[item].resume!=="" && appdata[item].is_from_othersite===0? 
                            <a target="_blank" title="Download CV" href={BASEURL+'uploads/cv/'+appdata[item].resume}>
                            <FaFileDownload className="cursor-pointer" style={{fontSize:"17px"}}/>
                          </a>:null }
                          
                            {appdata[item].cvname!=="" && appdata[item].is_from_othersite===1? 
                            <a target="_blank" title="Download CV" href={"https://www.rayvat.com/assets/contactfiles/"+appdata[item].cvname}>
                            <FaFileDownload className="cursor-pointer" style={{fontSize:"17px"}}/>
                          </a>:null }
                            </span>
                            <span>
                            {appdata[item].profilepic!==""? 
                            <FaUserAlt onClick={()=>fun(appdata[item].profilepic)} className="cursor-pointer ml-3" style={{fontSize:"17px"}}/>
                            :null }
                            </span>
                          </div>
                          
                          
                          
                          
                          {/* </button> */}
                          </td>                        
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}

<div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
            <br />
            <nav className="w-full sm:w-auto sm:mr-auto">
              {!loading ? (
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-4">
                    <ReactPaginate
                      pageCount={pageCount}
                      pageRangeDisplayed={20}
                      marginPagesDisplayed={1}
                      onPageChange={handlePageClick}
                      containerClassName="pagination"
                      activeClassName="active"
                      pageLinkClassName="page-link"
                      breakLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      pageClassName="page-item"
                      breakClassName="page-item"
                      nextClassName="page-item"
                      previousClassName="page-item"
                      previousLabel={<>&laquo;</>}
                      nextLabel={<>&raquo;</>}
                    />
                  </div>
                  <div className="col-md-4"></div>
                </div>
              ) : null}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Departments;
