import React, { useState, useEffect } from "react";
import { BASEURL } from "../BASEURL";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import { format } from "date-fns";
import HashLoader from "react-spinners/HashLoader";
import Select from "react-select";

const ProjectHrs = () => {
  const [hrsdata, sethrsdata] = useState(null);

  const [isadd, setisadd] = useState(false);
  let [loading, setLoading] = useState(false);
  let [msg, setmsg] = useState(false);
  const [proj, setproj] = React.useState([]);
  const [id, setid] = React.useState("");
  const [selectedproj, setselectedproj] = React.useState();
  const [selectedact, setselectedact] = React.useState();
  const [projects, setprojects] = React.useState([]);
  const [activity, setactivity] = React.useState([]);
  const [Hrs, setHrs] = React.useState("");
  const [sdate, setsdate] = React.useState("");
  const [ldate, setldate] = React.useState("");

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `getAllProjhrs/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          sethrsdata(res.data);
          setprojects(res.projects);
        } else {
          sethrsdata({});
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  
  const getproj = async (proj) => {
    setproj(proj);
    proj = proj.value
    setselectedproj(proj);
    getActivity(proj);    

  }
  const getact = async (act) => {
    // setactivity(act);
    // console.log(act.value);
    // act = proj.act
    // console.log(act.value);
    setselectedact(act);
  }

  const getActivity = async(proj) => {
    // console.log(proj);
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-project-activity/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        proj
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        // console.log(res); return
        if (res.status === 201) {
          setactivity(res.data);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  }

  // const handlechange = (e) => {
  //   let value = e.target.value;
  //   setdata({ ...data, [e.target.name]: value });
  // };

  const savedata = async () => {
    // console.log(selectedact); return
    // console.log(selectedproj);
    // return
    // console.log(Hrs);
//  console.log(format(new Date(sdate), "yyyy-MM-dd")); return
    // return
    // selectedact === "" || 
    if (selectedproj === "" || Hrs==="") {
      swal({
        title: "Error",
        text: "Please Enter value",
        icon: "error",
        timer: 2000,
      });
      return;
    }
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `save-hrs/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        // uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
        // data,
        selectedact:selectedact?selectedact.value:0, 
        id,
        selectedproj, Hrs,start_date:format(new Date(sdate), "yyyy-MM-dd"), last_date: format(new Date(ldate), "yyyy-MM-dd")
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setmsg(res.message);
          swal({
            title: "Success",
            text: res.message,
            icon: "success",
            timer: 2000,
          });
          getData();
          setTimeout(() => {
            setisadd(!isadd);
            setmsg("");
          }, 1000);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const editdata = async(data) => {
    setLoading(true)
    let proj = projects.filter(item=>item.value===data.proj_id)
    // let act = projects.filter(item=>item.value===data.proj_id)
    // console.log(data); 
    await getActivity(data.proj_id);
    let act = activity.filter(item=>item.value===Number(data.act_id))
    // console.log(act);
    // console.log(data);
    setselectedact(act)
    setid(data.id)
    setselectedproj(data.proj_id);
    setisadd(true);
    setproj(proj);
    setsdate(format(new Date(data.start_date), "yyyy-MM-dd"))
    setldate(format(new Date(data.end_date), "yyyy-MM-dd"))
    setHrs(Number(data.hrs))
    setLoading(false)
  };

  const adddata = (data) => {
    setid("")
    setselectedact()
    setsdate("")
    setldate("")
    setHrs("")
    setisadd(!isadd);
  };

  const deletedata = (id) => {
    swal({
      title: "Delete",
      text: `Please confirm if you want to Delete Hrs data?`,
      buttons: ["No", "Yes"],
      showCloseButton: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        const myHeaders = new Headers();
        let userdata = JSON.parse(localStorage.getItem("isAuth"));
        if (userdata != null) {
          let token = userdata.usertoken;
          myHeaders.append("Authorization", token);
        }
        myHeaders.append("Content-Type", "application/json");
        fetch(BASEURL + `delete-projecthrs/`, {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            id,
          }),
        })
          .then((response) => response.json())
          .then((res) => {
            getData();
            swal({
              title: "Success",
              text: res.msg,
              icon: "success",
              timer: 2000,
            });
          })
          .catch((err) => console.log(err));
        setLoading(false);
      }
    });
  };

  return (
    <div className="intro-y box lg:mt-5">
      <div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
        <h2 className="font-medium text-base mr-auto">Project HRS</h2>
        <button
          type="button"
          onClick={() => adddata()}
          className="btn btn-primary w-50"
        >
          Add Project HRS
        </button>
      </div>
      <div className="p-5">
        <div className="flex flex-col-reverse xl:flex-row flex-col text-left">
          {isadd === true ? (
            <div className="flex-1 mt-6 xl:mt-0">
              <div className="grid grid-cols-12 gap-x-5">
                <div className="col-span-12 2xl:col-span-2">
                  <div>
                    <label
                      htmlFor="update-profile-form-1"
                      className="form-label"
                    >
                      Select Project
                    </label> <br />
                    <Select
                        value={proj}
                        className="basic-single mr-2"
                        onChange={(e) => {
                          getproj(e);
                        }}
                        options={projects}
                        styles={{
                          option: (base) => ({
                            ...base,
                            // height: "100%",
                            display: "flex",
                            textAlign: "left",
                          }),
                        }}
                      />
                  </div>
                </div>
                <div className="col-span-12 2xl:col-span-2">
                  <label htmlFor="update-profile-form-2" className="form-label">
                    Select Activity
                  </label>
                  <br />
                     <Select
                        value={selectedact}
                        className="basic-single mr-2"
                        onChange={(e) => {
                          getact(e);
                        }}
                        options={activity}
                        styles={{
                          option: (base) => ({
                            ...base,
                            // height: "100%",
                            display: "flex",
                            textAlign: "left",
                          }),
                        }}
                      />
                </div>
               
                <div className="col-span-12 2xl:col-span-2">
                  <label htmlFor="update-profile-form-2" className="form-label">
                    Start Date
                  </label>
                  <br />
                     <input type="date" value={sdate} onChange={(e)=>setsdate(e.target.value)} />
                </div>
                <div className="col-span-12 2xl:col-span-2">
                  <label htmlFor="update-profile-form-2" className="form-label">
                    End Date
                  </label>
                  <br />
                     <input type="date" value={ldate} onChange={(e)=>setldate(e.target.value)} />
                </div>
                <div className="col-span-12 2xl:col-span-2">
                  <label htmlFor="update-profile-form-2" className="form-label">
                    Enter hours 
                  </label>
                  <br />
                     <input type="number" value={Hrs} onChange={(e)=>setHrs(e.target.value)} />
                </div>
              </div>
              <button
                type="button"
                onClick={() => savedata()}
                className="btn btn-primary w-20 mt-3"
              >
                Save
              </button>
              <button
                type="button"
                onClick={() => adddata()}
                className="btn btn-danger w-20 mt-3 ml-2"
              >
                Cancel
              </button>
              <br />
              <br />
              <span className="text-success">{msg ? msg : null}</span>
            </div>
          ) : null}
        </div>
        <div className="overflow-x-auto">
          {loading ? (
            <HashLoader
              color="#5755d9"
              size={30}
              style={{ position: "absolute", right: "50%" }}
            />
          ) : (
            <table className="table table-bordered" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th className="whitespace-nowrap">Project</th>
                  <th className="whitespace-nowrap">Activity</th>
                  <th className="whitespace-nowrap">Date</th>
                  <th className="whitespace-nowrap">Hours to complete</th>
                  <th className="whitespace-nowrap">Actions</th>
                </tr>
              </thead>
              <tbody>
                {hrsdata && hrsdata.length > 0 ? (
                  hrsdata.map((item, i) => {
                    return (
                      <tr>
                        <td>{item.projname}</td>
                        <td>{item.activity_name ? item.activity_name:"-"}</td>
                        <td>{format(new Date(item.start_date), "yyyy-MM-dd")} &nbsp;&nbsp; | &nbsp;&nbsp; {format(new Date(item.end_date), "yyyy-MM-dd")}</td>
                        <td>{item.hrs}</td>
                        <td>
                          <div className="flex lg:justify-center items-center">
                            <div
                              className="edit flex items-center mr-3"
                              onClick={() => editdata(item)}
                              style={{ cursor: "pointer" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                icon-name="check-square"
                                data-lucide="check-square"
                                className="lucide lucide-check-square w-4 h-4 mr-1"
                              >
                                <polyline points="9 11 12 14 22 4"></polyline>
                                <path d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"></path>
                              </svg>{" "}
                              Edit
                            </div>
                            <duv
                              className="delete flex items-center text-danger"
                              onClick={() => deletedata(item.id)}
                              style={{ cursor: "pointer" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                icon-name="trash-2"
                                data-lucide="trash-2"
                                className="lucide lucide-trash-2 w-4 h-4 mr-1"
                              >
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path d="M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2"></path>
                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                <line x1="14" y1="11" x2="14" y2="17"></line>
                              </svg>{" "}
                              Delete
                            </duv>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="5">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectHrs;
