import React, { useEffect, useState } from "react";
import { BASEURL } from "./BASEURL";
import { useParams } from "react-router-dom";
import {
  FaPhone,
  FaCalendar,
  FaEnvelope,
  FaMapMarker,
  FaAddressCard,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import CallDetails from "../componunt/admin/CallDetails";
import SMS from "../componunt/admin/Sms";
import AssessmentData from "../componunt/admin/JobAssessment";
import { FaUserCircle } from "react-icons/fa";

const Employees_details = () => {
  const [jobdata, setjobdata] = useState([]);
  const [position, setposition] = useState(null);
  
  let [isAuth, ] = useState(
    JSON.parse(localStorage.getItem("isAuth"))
  );
  const params = useParams();

  let [isTab, setisTab] = useState(0);
 
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const myHeaders = new Headers();
    let jobdata = JSON.parse(localStorage.getItem("isAuth"));
    if (jobdata != null) {
      let token = jobdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    // console.log(params.id);
    await fetch(BASEURL + `get-jobdata`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        uuid: params.id,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setjobdata(res.data);
          setposition(res.position);
        }
      })
      .catch((err) => console.log(err));
  };


  return (
    <>
      <div className="intro-y flex items-center">
        <h2 className="text-lg font-medium mr-auto">Application Details</h2>
      </div>
      <div className="intro-y chat grid grid-cols-12 gap-5">
                    
      <div className="col-span-12 lg:col-span-12 2xl:col-span-12">
        <div className="intro-y box px-5 pt-2 mt-2">
        
          <div className="flex flex-col lg:flex-row border-b border-slate-200/60 dark:border-darkmode-400 pb-5 -mx-5">
            <div className="flex flex-1 px-5 items-center justify-center lg:justify-start">
              <div className="">
                {jobdata && jobdata.length>0 && jobdata[0].profilepic==="" ?
                <FaUserCircle className="text-5xl" style={{ color: "#1d45ab" }} />
                : 
                <div>
                <img src={jobdata && jobdata[0] && jobdata[0].profilepic} style={{borderRadius: "52px"}} alt="" srcset="" />
                </div>
                }
              </div>
              <div className="ml-5 text-left">
                <div className="w-30 sm:w-60 truncate sm:whitespace-normal font-medium text-lg d-flex">
                  {jobdata && jobdata.length>0 && jobdata[0].name}
                </div>
                <div className="text-slate-500">
                  {position && position[0].name}
                </div>
              
              </div>
            </div>
            <div className="mt-6 lg:mt-0 flex-1 px-5 border-l border-r border-slate-200/60 dark:border-darkmode-400 border-t lg:border-t-0 pt-5 lg:pt-0">
              <div className="font-medium text-center lg:text-left lg:mt-3">
                Contact Details
              </div>
              <div className="flex flex-col justify-center items-center lg:items-start mt-2">
                <div className="truncate sm:whitespace-normal flex items-center">
                  <i data-lucide="mail" className="w-4 h-4 mr-2">
                    <FaPhone />
                  </i>
                  {jobdata && jobdata.length>0 &&  jobdata[0].contactno}                 
                </div>

                <div className="truncate sm:whitespace-normal flex items-center">
                  <i data-lucide="mail" className="w-4 h-4 mr-2">
                    <FaEnvelope />
                  </i>
                  {jobdata && jobdata.length>0 && jobdata[0].email}
                </div>
              </div>

              <div className="font-medium text-center lg:text-left lg:mt-3">
                Application Date time
              </div>
              <div className="flex flex-col justify-center items-center lg:items-start mt-2">
                <div className="truncate sm:whitespace-normal flex items-center">
                  <i data-lucide="mail" className="w-4 h-4 mr-2">
                    <FaCalendar />
                  </i>
                  {jobdata && jobdata.length>0 &&
                    format(new Date(jobdata[0].applicationdate), "yyyy-MM-dd HH:mm:ss")}
                </div>
              </div>
              
              
            </div>

            <div className="mt-6 lg:mt-0 flex-1 px-5 border-l border-r border-slate-200/60 dark:border-darkmode-400 border-t lg:border-t-0 pt-5 lg:pt-0">
              <div className="font-medium text-center lg:text-left lg:mt-3">
                Address
              </div>
              <div className="flex flex-col justify-center items-center lg:items-start mt-2">
                  {jobdata && jobdata.length>0 && jobdata[0].address=="Other" ? "":  
                <div className="truncate sm:whitespace-normal flex items-center">
                  <i data-lucide="mail" className="w-4 h-4 mr-2">
                    <FaMapMarker />
                  </i>
                  {jobdata && jobdata.length>0 && jobdata[0].address}
                </div>}

                <div className="truncate sm:whitespace-normal flex items-center">
                  <i data-lucide="mail" className="w-4 h-4 mr-2">
                    <FaAddressCard />
                  </i>
                  {jobdata && jobdata.length>0 && jobdata[0].city===0?"Ahmedabad":jobdata && jobdata.length>0 && jobdata[0].city===1?"Gandhinagar":""}, {jobdata && jobdata.length>0 && jobdata[0].state}, {jobdata && jobdata.length>0 && jobdata[0].zipcode}
                </div>

              </div>

              <div className="font-medium text-center lg:text-left lg:mt-3">
                Notice Period
              </div>
              <div className="flex flex-col justify-center items-center lg:items-start mt-2">
                <div className="truncate sm:whitespace-normal flex items-center">
                  <i data-lucide="mail" className="w-4 h-4 mr-2">
                    <FaCalendar />
                  </i>
                  {jobdata && jobdata.length>0 &&
                    jobdata[0].noticeperiod}
                </div>
              </div>

              
            </div>

              <div className="mt-6 lg:mt-0 flex-1 px-5 border-l border-r border-slate-200/60 dark:border-darkmode-400 border-t lg:border-t-0 pt-5 lg:pt-0">
                <div className="font-medium text-center lg:text-left lg:mt-5">
                  Salary
                </div>
                <div className="flex items-center justify-center lg:justify-start mt-2">
                  <div className="mr-2 w-50 flex">
                    Current Salary: <span className="ml-3 font-medium ">{jobdata && jobdata.length>0 &&
                      jobdata[0].currentsalary}</span>
                  </div>
                </div>
                <div className="flex items-center justify-center lg:justify-start">
                  <div className="mr-2 w-50 flex">
                  Expected Salary: <span className="ml-3 font-medium">{jobdata && jobdata.length>0 &&
                      jobdata[0].expectedsalary}</span>
                  </div>
                </div>
                <div className="flex items-center justify-center lg:justify-start">
                  <div className="mr-2 w-50 flex">
                  Willing to relocate?: <span className="ml-3 font-medium">{jobdata && jobdata.length>0 &&
                      jobdata[0].isrelocate==1 ? "Yes":"No"}</span>
                  </div>
                </div>
                <div className="flex items-center justify-center lg:justify-start">
                  <div className="mr-2 w-50 flex">
                  Is Taken Training?: <span className="ml-3 font-medium">{jobdata && jobdata.length>0 &&
                      jobdata[0].istraining==1 ? "Yes":"No"}</span>
                  </div>
                </div>
                {jobdata && jobdata.length>0 && jobdata[0].istraining==1 &&
                <div className="flex items-center justify-center lg:justify-start">
                  <div className="mr-2 w-50 flex">
                  From where Taken Training: <span className="ml-3 font-medium">{
                       jobdata[0].fromwheretraining}</span>
                  </div>
                </div>
                }

                <div className="font-medium text-center lg:text-left lg:mt-3">
                Form Submitted at : {jobdata && jobdata.length>0 &&
                    format(new Date(jobdata[0].form_submitted_at), "yyyy-MM-dd HH:mm:ss")}
              </div>             
              </div>
          </div>
          
          <ul
            className="nav nav-link-tabs  flex-wrap flex-col sm:flex-row justify-center sm:justify-start text-center"
            role="tablist"
            style={{ cursor: "pointer" }}
          >
            <li id="dashboard-tab" className="nav-item" role="presentation">
              <div
                className={isTab === 0 ? "nav-link py-1 active" : "nav-link py-1"}
                onClick={() => setisTab(0)}
                data-tw-target="#dashboard"
                aria-controls="dashboard"
                aria-selected="true"
                role="tab"
              >
                Call/SMS History
              </div>
            </li>

            {/* <li id="dashboard-tab" className="nav-item" role="presentation">
              <div
                className={isTab === 1 ? "nav-link py-4 active" : "nav-link py-4"}
                onClick={() => setisTab(1)}
                data-tw-target="#dashboard"
                aria-controls="dashboard"
                aria-selected="true"
                role="tab"
              >
                SMS History
              </div>
            </li> */}
          
            <li id="dashboard-tab" className="nav-item" role="presentation">
              <div
                className={isTab === 2 ? "nav-link py-1 active" : "nav-link py-1"}
                onClick={() => setisTab(2)}
                data-tw-target="#dashboard"
                aria-controls="dashboard"
                aria-selected="true"
                role="tab"
              >
                Assessment Details
              </div>
            </li>

          
          </ul>
        </div>
      </div>
      </div>

      {/* <div className="col-span-3 lg:col-span-3 2xl:col-span-3">
            
      </div> */}
      <div className="intro-y tab-content mt-5">
        <div
          id="dashboard"
          className="tab-pane active"
          role="tabpanel"
          aria-labelledby="dashboard-tab"
        >
          {isTab === 0 ? (
            <CallDetails id={params.id} />
          ) : isTab === 1 ? (
             <><SMS id={params.id} /> </>            
          ) : isTab === 2 ? (
             <><AssessmentData id={params.id} /> </>            
          ) : null}
          
        </div>
      </div>
      {isAuth.role === 0 && (
        <div className="text-left mt-3">
          <Link to="/admin/12" className="btn btn-primary btn-sm text-white">
            Back to All Applications
          </Link>
        </div>
      )}
   
    </>
  );
};

export default Employees_details;
