import React, { useState, useEffect } from "react";
import { SITEURL, BASEURL } from "../BASEURL";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";
import HashLoader from "react-spinners/HashLoader";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Masteradmin from "../Masteradmin";
import {
  FaSignInAlt,
  FaTrashAlt,
  FaEdit,
  FaToggleOff,
  FaToggleOn
} from "react-icons/fa";

const Departments = () => {
  const [userdata, setuserdata] = useState([]);
  const [dept, setdept] = useState(null);
  const [bkpdata, setbkpdata] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [items, setitems] = useState([]);
  const [add, setadd] = useState(2);
  const [dept_selected, setdept_selected] = useState();
  let [tabtype, settabtype] = useState(false);
  let [seledept, setseledept] = useState("");
  
  const [getdata, setgetdata] = useState([]);
  const [data, setdata] = useState({
    id: "",
    name: "",
    department: "0",
    status: true,
    uuid:``
  });

  const [isadd, setisadd] = useState(false);
  let [loading, setLoading] = useState(false);
  let [msg, setmsg] = useState({
    type: "",
    msg: "",
  });

  let [master, ] = useState(JSON.parse(localStorage.getItem("isAuth")).master);

  useEffect(() => {
    setadd(JSON.parse(localStorage.getItem("isAuth")).type);
    const endOffset = itemOffset + 10;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / 10));
    getdepartments();
    getData();
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + 10;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / 10));
  }, [itemOffset]);

  const getdepartments = async () => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata !== "00:00:00") {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `getAllDepartments/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          // console.log(res.data);
          if(userdata.role == 2){
            let dept = res.data.filter((item)=>item.department_id === userdata.department)
            setdept(dept);
            getUserData(dept && dept[0].department_id);
          }else{
            setdept(res.data);
            getUserData(res.data && res.data[0].department_id);
          }          
          
        //   setdept_selected(res.data[0].department_id)
        // console.log(res.data);
          
        }
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const getData = async () => {
    settabtype(false);
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    let data = await fetch(BASEURL + `addorganization`, {
      method: "GET",
      headers: myHeaders,
    });
    let data2 = await data.json();
    setgetdata(data2.data);
    setLoading(false);
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 10) % items.length;

    setItemOffset(newOffset);
  };

  const getUserData = async (e = "") => {
    setseledept(e);
    setdata({
      id: "",
      name: "",
      department: e,
      status: true,
      uuid:``
    });

    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));

    if (userdata !== "00:00:00") {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    // console.log(master);
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-projects/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        department:e,
        listtype: 1,
        role: 1,
        master,
        // isactive,
        uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          let arr = [];
          let arr1 = [];
          for (const key in res.projects) {
            arr.push(key);
            if (key < 9) {
              arr1.push(key);
            }
          }
          setitems(arr);

          setCurrentItems(arr1);

          setPageCount(Math.ceil(arr.length / 10));

          setuserdata(res.projects);
          setbkpdata(res.projects);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const handlechange = (e) => {
    
    let value = e.target.value;
    if (e.target.name === "status") {
      value = e.target.checked;
    }
    setdata({
      ...data,
      [e.target.name]: value,
    });
    // console.log(data);
  };

  const savedata = async (elm) => {
    
    if (
      data.name === "" ||
      data.department === ""      
    ) {
      swal({
        title: "Error",
        text: "Please Enter value Required value",
        icon: "error",
        timer: 2000,
      });
      return;
    }
    // console.log(data); return
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `add-project/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        company_uuid: userdata.companydata[0].uuid,
        company: userdata,
        data
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          getUserData(data.department);
          userdata.companydata[0].name = data.name;
          localStorage.setItem("isAuth", JSON.stringify(userdata));
          setmsg({
            type: res.type,
            msg: res.message,
          });          
          setdata({
            id: "",
            name: "",
            department: 1,
            status: true,
          });

          swal({
            title: "Success",
            text: res.message,
            icon: "success",
            timer: 2000,
          });
          setTimeout(() => {
            setmsg({
              type: "",
              msg: "",
            });
            setisadd(!isadd);
          }, 1000);
        } else {
          swal({
            title: "Error",
            text: res.message,
            icon: "error",
            timer: 2000,
          });
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const editdata = (data) => {
    setisadd(true);
    let status;
    if (data.status === 1) {
      status = true;
    } else {
      status = false;
    }
    // console.log(data.companyuuid);
    setdata({
      id: data.project_id,
      name: data.name,
      department: data.department,
      status: status,
    });
  };

 
  const adddata = (data) => {
    // console.log(11);
    settabtype(false);
    setisadd(!isadd);
    setdata({
      id: "",
      name: "",
      department: 0,
      status: true,
    });
  };

  const filterdata = (event) => {
    if (event.target.value === "") {
      loading ? <HashLoader /> : setuserdata(bkpdata);

      let arr = [];
      for (const key in bkpdata) {
        arr.push(key);
      }
      setitems(arr);

      setCurrentItems(arr);
      setPageCount(Math.ceil(arr.length / 10));
    } else {
      var matches = bkpdata.filter(function (s) {
        return s.name.toLowerCase().includes(`${event.target.value}`);
      });

      let arr = [];
      for (const key in matches) {
        arr.push(key);
      }
      setitems(arr);

      const newOffset = (matches.length * 10) % matches.length;
      setItemOffset(newOffset);
      setuserdata(matches);
      const endOffset = itemOffset + 10;
      setCurrentItems(arr.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(matches.length / 10));
    }
  };


  const deletedata = (id) => {
    swal({
      title: "Delete",
      text: `Please confirm if you want to Delete Project?`,
      buttons: ["No", "Yes"],
      showCloseButton: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        const myHeaders = new Headers();
        let userdata = JSON.parse(localStorage.getItem("isAuth"));
        if (userdata != null) {
          let token = userdata.usertoken;
          myHeaders.append("Authorization", token);
        }
        myHeaders.append("Content-Type", "application/json");
        fetch(BASEURL + `delete-project/${id}`, {
          method: "DELETE",
          headers: myHeaders,         
        })
          .then((response) => response.json())
          .then((res) => {
            getUserData(seledept);
            swal({
              title: "Success",
              text: res.msg,
              icon: "success",
              timer: 2000,
            });
          })
          .catch((err) => console.log(err));
        setLoading(false);
      }
    });
  };

  return (
    <div className="intro-y box lg:mt-5">
      <div className="flex items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
        <h2 className="font-medium text-base mr-auto">All Projects</h2>
        {/* {master === 1 && 
          <button
            type="button"
            onClick={() => addorg()}
            className="btn btn-primary w-50 mr-2"
          >
            Add Organization
          </button>
        } */}
        {/* {add === 0 ? ( */}
          <button
            type="button"
            onClick={() => adddata(0)}
            className="btn btn-primary w-50"
          >
            Add Project
          </button>
        {/* ) : null} */}
      </div>
      {tabtype ? <Masteradmin getData={getData}  /> : null}

      <div className="p-5">
        <div className="flex flex-col-reverse xl:flex-row flex-col text-left">
          {isadd === true ? (
            <div className="flex-1 mt-6 xl:mt-0">
              <form>
                <div className="grid grid-cols-12 gap-x-5">
                  <div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
                    <div>
                      <label
                        htmlFor="update-profile-form-1"
                        className="form-label"
                      >
                        Project Name <span className="text-danger">(*)</span> 
                      </label>
                      <input
                        id="update-profile-form-1"
                        value={data.name}
                        required
                        type="text"
                        name="name"
                        className="form-control"
                        onChange={handlechange}
                      />
                    </div>
                  </div>
                  <div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
                    <label
                      htmlFor="update-profile-form-2"
                      className="form-label"
                    >
                      Select Department
                    </label>
                    <select
                      className="form-select"
                      name="department"
                      onChange={handlechange}
                      value={data.department}
                    >
                      <option key={-1} value="0">
                        All department
                      </option>
                      {dept &&
                        dept.length > 0 &&
                        dept.map((item, i) => {
                          return (
                            <option key={i} value={item.department_id}>
                              {item.deptname}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                 
                  <div className="col-span-12 2xl:col-span-6 mt-3 mb-3">
                    <div className="">
                      <label>Status</label>
                      <div className="form-switch mt-2">
                        {loading ? (
                          <div className="text-center m-5 p-5">
                            <HashLoader
                              color="#5755d9"
                              size={30}
                              style={{ position: "absolute", right: "50%" }}
                            />
                          </div>
                        ) : data && data.status === false ? (
                          <input
                            type="checkbox"
                            name="status"
                            onChange={handlechange}
                            className="form-check-input"
                          />
                        ) : (
                          <input
                            type="checkbox"
                            name="status"
                            onChange={handlechange}
                            value={data.status}
                            checked
                            className="form-check-input"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => savedata()}
                  className="btn btn-primary w-20 mt-3"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={() => adddata(1)}
                  className="btn btn-danger w-20 mt-3 ml-2"
                >
                  Cancel
                </button>
                <br />
                <br />
                <span
                  className={
                    msg.type === "success" ? "text-success" : "text-danger"
                  }
                >
                  {msg.msg ? msg.msg : null}
                </span>
              </form>
            </div>
          ) : null}
        </div>
        <div className="flex flex-col sm:flex-row sm:items-end xl:items-start mb-4">
          <div className="flex mt-5 sm:mt-0">
            <div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <input
                id="tabulator-html-filter-value"
                type="text"
                onChange={filterdata}
                className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                placeholder="Search..."
              />
            </div>
          </div>
          {/* {master == true ? ( */}
            <div className="flex mt-5 sm:mt-0">
              <select
                      className="form-select"
                      name="department"
                      onChange={(e)=>getUserData(e.target.value)}
                      value={seledept}
                      // value={dept && dept[0].department_id}
                    >
                      <option key={-1} value="0">
                        All department
                      </option>
                      {dept &&
                        dept.length > 0 &&
                        dept.map((item, i) => {
                          return (
                            <option key={i} value={item.department_id}>
                              {item.deptname}
                            </option>
                          );
                        })}
                    </select>
            </div>
          {/* ) : null} */}
          <div className="hidden md:block ml-auto text-slate-500">
            Showing {itemOffset + 1} to  {userdata.length > itemOffset + 10 ? itemOffset + 10 : userdata.length} of {userdata.length} entries
          </div>
        </div>
        <div className="overflow-x-auto">
          {loading ? (
            <HashLoader
              color="#5755d9"
              size={30}
              style={{ position: "absolute", right: "50%" }}
            />
          ) : (
            <table className="table table-bordered" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th className="whitespace-nowrap">#</th>
                  <th className="whitespace-nowrap">Name</th>
                  <th className="whitespace-nowrap">Status</th>
                  <th className="whitespace-nowrap">Actions</th>
                </tr>
              </thead>
              <tbody>
              {currentItems &&
                  currentItems.length === 0 && 
                    <tr><td colSpan="8">No data available</td></tr>
                }
                {currentItems &&
                  currentItems.length > 0 &&
                  currentItems.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{userdata[item].project_id}</td>
                        <td>
                          <Link
                            // target="_blank"
                            to={"/admin/22/" + userdata[item].project_id}
                          >
                            <u>{userdata[item].name}</u>
                          </Link>
                        </td>
                        <td>
                          {userdata[item].status === 1 ? 
                          <span className='btn btn-success btn-sm text-white'>Active</span> :
                          <span className='btn btn-danger btn-sm text-white'>In - Active</span>}
                        </td>
                        <td>
                          <div className="flex lg:justify-center items-center cursor-pointer">
                            {/* <div
                              className="edit flex items-center mr-3"
                              onClick={() => editdata(userdata[item])}
                              style={{ cursor: "pointer" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                icon-name="check-square"
                                data-lucide="check-square"
                                className="lucide lucide-check-square w-4 h-4 mr-1"
                              >
                                <polyline points="9 11 12 14 22 4"></polyline>
                                <path d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"></path>
                              </svg>
                              Edit

                              
                            </div> */}
                            {userdata[item].type!==0 || master===1?<>
                            {/* <FaToggleOn title="Active" style={{color:'gray',fontSize:"25px"}} className="mr-2" onClick={() => deletedata(userdata[item].project_id)} /> */}
                            <FaEdit title="Edit" style={{color:'gray'}} className="mr-2" onClick={() => editdata(userdata[item])} />
                            <FaTrashAlt onClick={() => deletedata(userdata[item].project_id)} title="Delete" className="text-danger mr-2"/>                            
                            </>:"-" }
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}

          <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
            <br />
            <nav className="w-full sm:w-auto sm:mr-auto">
              {!loading ? (
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-4">
                    <ReactPaginate
                      pageCount={pageCount}
                      pageRangeDisplayed={10}
                      marginPagesDisplayed={1}
                      onPageChange={handlePageClick}
                      containerClassName="pagination"
                      activeClassName="active"
                      pageLinkClassName="page-link"
                      breakLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      pageClassName="page-item"
                      breakClassName="page-item"
                      nextClassName="page-item"
                      previousClassName="page-item"
                      previousLabel={<>&laquo;</>}
                      nextLabel={<>&raquo;</>}
                    />
                  </div>
                  <div className="col-md-4"></div>
                </div>
              ) : (
                <HashLoader
                  color="#5755d9"
                  size={30}
                  style={{ position: "absolute", right: "50%" }}
                />
              )}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Departments;
