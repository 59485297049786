import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import Axios from "../../../utils/Axios";
import { wssServer,API_BASEURL } from "../../../componunt/BASEURL";
import { AddSettings, getConfigSettings, userSettings } from "../../../data/userSettings";

export let tocken = localStorage.getItem('access_tocken')
let access_tocken = null;
const config_axios = {
    headers: {
        'Authorization': `Barear ${tocken}`
    }
}
const device_token = uuidv4().replaceAll("-", "");
const device_type = 'web';
const instanceId = "dddd" + device_token;

// -------------------------------------------------
// ----------- Get Global Config Settings ---------
// -------------------------------------------------
export const getGlobalConfigData = createAsyncThunk('auth/get-switch-config', async (_, { rejectWithValue }) => {
    try {
        let res = await Axios.get('auth/get-switch-config')
        localStorage.setItem('_globalConfigSettings', JSON.stringify(res.data.data));
        return res.data;
    } catch (error) {
        throw rejectWithValue(error);
    }
})


// ------------------------- AUTH Login --------------------
export const authLogin = createAsyncThunk(
    "auth/login",
    async (values, { rejectWithValue }) => {

        try {
            let res = await Axios.post(API_BASEURL+"auth/login", values);
            const { access_token } = res.data
            tocken = access_token;
            storedata(res.data);
            if (tocken) {
                await getUserProfileDataAPI();
            }
            return res.data;
        } catch (error) {
            //console.log(error)
            throw rejectWithValue(error.message);
        }
    }
);

/*---------------------------------------------------
------------ Auth Otp login to send otp-------------*/
export const SendOtp = createAsyncThunk(
    "auth/send-otp",
    async (values, { rejectWithValue }) => {
        try {
            let res = await Axios.post(API_BASEURL+"auth/send-otp", values, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            let { status, message } = res.data;
            if (status == "ERROR") {
                toast.error(message)
            }

            localStorage.setItem('otp_auth_tocken', res.data.data.otp_auth_token);
            return res.data;
        } catch (error) {
            console.log(error)
            throw rejectWithValue(error.message);
        }
    }
);

/*---------------------------------------------------
------------ Auth logout ----------------------------*/
export const autlogout = createAsyncThunk(
    "auth/logout",
    async (_, { rejectWithValue }) => {
        //console.log(tocken)
        let accountCreds = JSON.parse(localStorage.getItem("_accountCreds"))
        let instanceId = accountCreds?._instanceId || null;
        let user = JSON.parse(localStorage.getItem('user'));
        let auth_type = user.auth_type;
        let device_type = 'web';
        let value = {
            auth_type: auth_type,
            instance_id: instanceId,
            device_type: device_type
        }
        try {
            await deletePushSubscriber(value)

            let res = await Axios.post(API_BASEURL+"auth/logout", _, {
                headers: {
                    'Authorization': `Barear ${tocken}`,
                    "Content-Type": "application/json"
                }
            });
            removedata();

            return res.data;
        } catch (error) {
            //console.log(error);
            throw rejectWithValue(error.message);
        }
    }
);

// ----------------------------- PASSWORD RESET -------------------------
export const passwordReset = createAsyncThunk(
    "auth/reset-password",
    async (values, { rejectWithValue }) => {
        try {
            // console.log(values);
            let res = await Axios.post(
                "/auth/reset-password",
                values
            );
            res = res.data;
            if (res.status === "ERROR") {
                toast.error(res.message);
            } else {
                toast.success(res.message);
            }
            return res;
        } catch (error) {
            throw rejectWithValue(error.message);
        }
    }
);


//// -------------------------- Verify Otp -----------------------------
export const VerifyOtp = createAsyncThunk(
    "auth/verify-otp",
    async (values, { rejectWithValue }) => {
        try {
            let res = await Axios.post(API_BASEURL+"auth/verify-otp", values);
            const { access_token } = res.data
            tocken = access_token;
            storedata(res.data);
            if (tocken) {
                await getUserProfileDataAPI();
            }
            return res.data;
        } catch (error) {
            throw rejectWithValue(error.message);
        }
    }
);


export const ResendOtp = createAsyncThunk(
    "auth/resend-otp",
    async (values, { rejectWithValue }) => {
        try {
            let res = await Axios.post(API_BASEURL+"auth/resend-otp", values);
            localStorage.setItem('otp_auth_tocken', res.data.data.otp_auth_token);
            return res.data;
        } catch (error) {
            throw rejectWithValue(error.message);
        }
    }
);

/////// get profile details api function 
export const getUserProfileData = async () => {
    try {
        // let user_id = 6;
        // let user_id = localStorage.getItem('user_id');
        // let value = { "user_id": 6 }
        // let res = await Axios.post(API_BASEURL+"profile/get", { user_id }, value);
        // console.debug('urs');
        let res ={
            status: "SUCCESS",
            message: "User Profile data get successfully.",
            data: {
              account_properties: {
                audio: {
                  audiocodecs: {
                    label: "Audio Codecs",
                    value: [
                      "PCMU",
                      "PCMA",
                      "G722",
                      "OPUS"
                    ],
                    alias: "audio.audioCodecs",
                    options: [
                      "PCMU",
                      "PCMA",
                      "G722",
                      "OPUS"
                    ],
                    accessbility: "Y",
                    visibility: "Y",
                    type: {
                      multiselect: "Y",
                      length: "0-255"
                    }
                  }
                },
                video: {
                  videocodecs: {
                    label: "Video Codecs",
                    value: [
                      "H264",
                      "VP8",
                      "VP9"
                    ],
                    alias: "video.videoCodecs",
                    options: [
                      "H264",
                      "VP8",
                      "VP9"
                    ],
                    accessbility: "Y",
                    visibility: "Y",
                    type: {
                      multiselect: "Y"
                    }
                  },
                  enablevideo: {
                    label: "Enable Video",
                    value: [
                      "true"
                    ],
                    alias: "video.enableVideo",
                    options: [
                      "FALSE"
                    ],
                    accessbility: "N",
                    visibility: "Y",
                    type: {
                      boolean: "Y"
                    }
                  },
                  selfview: {
                    label: "Self View",
                    value: [
                      "true"
                    ],
                    alias: "video.selfView",
                    options: [
                      "FALSE"
                    ],
                    accessbility: "N",
                    visibility: "Y",
                    type: {
                      boolean: "Y"
                    }
                  }
                },
                call: {
                  dtmftype: {
                    label: "DTMF Type",
                    value: [
                      "RTP",
                      "INFO"
                    ],
                    alias: "call.dtmfType",
                    options: [
                      "RTP",
                      "INFO"
                    ],
                    accessbility: "Y",
                    visibility: "Y",
                    type: {
                      radio: "Y"
                    }
                  },
                  outboundcalleridname: {
                    label: "Outbound Caller Id name",
                    value: [
                      "test3333"
                    ],
                    alias: "call.outboundCallerName",
                    options: [
                      ""
                    ],
                    accessbility: "Y",
                    visibility: "Y",
                    type: {
                      textinput: "N"
                    }
                  },
                  earlymedia: {
                    label: "Early Media",
                    value: [
                      "true"
                    ],
                    alias: "call.earlyMedia",
                    options: [
                      "TRUE"
                    ],
                    accessbility: "N",
                    visibility: "Y",
                    type: {
                      boolean: "Y"
                    }
                  },
                  extraheaders: {
                    label: "Extra Headers",
                    value: [
                      "none"
                    ],
                    alias: "call.extraHeaders",
                    options: [
                      ""
                    ],
                    accessbility: "Y",
                    visibility: "Y",
                    type: {
                      textinput: "N"
                    }
                  },
                  encryption: {
                    label: "Encryption",
                    value: [
                      "RTP"
                    ],
                    alias: "call.encryption",
                    options: [
                      "RTP"
                    ],
                    accessbility: "Y",
                    visibility: "Y",
                    type: {
                      radio: "Y"
                    }
                  },
                  allowholdcontrols: {
                    label: "Allow Hold Controls",
                    value: [
                      "true"
                    ],
                    alias: "call.allowHold",
                    options: [
                      "TRUE",
                      "FALSE"
                    ],
                    accessbility: "N",
                    visibility: "Y",
                    type: {
                      boolean: "Y"
                    }
                  },
                  allowtransfercontrols: {
                    label: "Allow Transfer Controls",
                    value: [
                      "false"
                    ],
                    alias: "call.allowTransfer",
                    options: [
                      "TRUE"
                    ],
                    accessbility: "N",
                    visibility: "Y",
                    type: {
                      boolean: "Y"
                    }
                  }
                },
                sip: {
                  sipserver: {
                    label: "SIP Server",
                    value: [
                      "s2.netcitrus.com"
                    ],
                    alias: "sip.auth.sipServer",
                    options: [
                      "s1.netcitrus.com"
                    ],
                    accessbility: "Y",
                    visibility: "Y",
                    type: {
                      textinput: "N",
                      length: "100"
                    }
                  },
                  sipport: {
                    label: "SIP Port",
                    value: [
                      "7443"
                    ],
                    alias: "sip.auth.sipPort",
                    options: [
                      ""
                    ],
                    accessbility: "Y",
                    visibility: "Y",
                    type: {
                      textinput: "N",
                      length: "65535",
                      numeric: true
                    }
                  },
                  proxyserver: {
                    label: "Proxy Server",
                    value: [
                      ""
                    ],
                    alias: "sip.auth.outboundProxyServer",
                    options: [
                      ""
                    ],
                    accessbility: "Y",
                    visibility: "Y",
                    type: {
                      textinput: "N"
                    }
                  },
                  proxyport: {
                    label: "Proxy Port",
                    value: [
                      "5090"
                    ],
                    alias: "sip.auth.outboundProxyPort",
                    options: [
                      ""
                    ],
                    accessbility: "Y",
                    visibility: "Y",
                    type: {
                      textinput: "N",
                      length: "65535"
                    }
                  },
                  registerexpires: {
                    label: "Register Expires",
                    value: [
                      "3600"
                    ],
                    alias: "sip.register.interval",
                    options: [
                      "3600"
                    ],
                    accessbility: "Y",
                    visibility: "Y",
                    type: {
                      textinput: "Y",
                      numeric: true,
                      allow_positive_num: true
                    }
                  },
                  respectserverexpires: {
                    label: "Respect Server expires",
                    value: [
                      "TRUE"
                    ],
                    alias: "sip.register.respectServerExpires",
                    options: [
                      "TRUE"
                    ],
                    accessbility: "N",
                    visibility: "Y",
                    type: {
                      boolean: "N"
                    }
                  },
                  username: {
                    label: "SIP Username",
                    value: [
                      "4727985745"
                    ],
                    alias: "sip.auth.username",
                    options: [
                      "505050"
                    ],
                    accessbility: "Y",
                    visibility: "Y",
                    type: {
                      textinput: "N"
                    }
                  },
                  password: {
                    label: "SIP Password",
                    value: [
                      "x#^@6wVa"
                    ],
                    alias: "sip.auth.password",
                    options: [
                      "505060@5050A1"
                    ],
                    accessbility: "Y",
                    visibility: "Y",
                    type: {
                      passwordinput: "N"
                    }
                  }
                },
                sms: {
                  enablesms: {
                    label: "Enable SMS",
                    value: [
                      "true"
                    ],
                    alias: "sms.status",
                    options: [
                      "FALSE"
                    ],
                    accessbility: "N",
                    visibility: "Y",
                    type: {
                      boolean: "N"
                    }
                  },
                  callerid: {
                    label: "Caller ID",
                    value: [
                      "400"
                    ],
                    alias: "sms.callerId",
                    options: [
                      ""
                    ],
                    accessbility: "Y",
                    visibility: "Y",
                    type: {
                      textinput: "N"
                    }
                  }
                },
                pcs: {
                  pcsdomain: {
                    label: "PCS Domain",
                    value: [
                      "wss://s2.netcitrus.com:7443"
                    ],
                    alias: "pcs.domain",
                    options: [
                      ""
                    ],
                    accessbility: "Y",
                    visibility: "Y",
                    type: {
                      textinput: "N",
                      length: "100"
                    }
                  }
                },
                im: {
                  enableim: {
                    label: "Enable IM",
                    value: [
                      "true"
                    ],
                    alias: "im.status",
                    options: [
                      "FALSE"
                    ],
                    accessbility: "N",
                    visibility: "Y",
                    type: {
                      boolean: "N"
                    }
                  }
                },
                ldap: {
                  ldapname: {
                    label: "LDAP Name",
                    value: [
                      "test"
                    ],
                    alias: "ldap.name",
                    options: [
                      ""
                    ],
                    accessbility: "Y",
                    visibility: "Y",
                    type: {
                      textinput: "N"
                    }
                  },
                  ldappassword: {
                    label: "LDAP Password",
                    value: [
                      "1234"
                    ],
                    alias: "ldap.password",
                    options: [
                      ""
                    ],
                    accessbility: "Y",
                    visibility: "Y",
                    type: {
                      passwordinput: "N"
                    }
                  }
                }
              }
            }
          }
        let { status, data, message } = res.data;
        // console.log(res1);
        // console.debug("data.account_properties");
        AddSettings(data.account_properties);
        return { data, status, message };
    } catch (error) {
        console.log(error);
    }
};

export const setUsersSettings = createAsyncThunk('usersetting', async (_, { rejectWithValue }) => {
    try {
        let res = await getUserProfileData();
        console.debug('set user Settings', res.data)
    } catch (error) {
        throw rejectWithValue(error);
    }
})



///// get profile detils get and this function user for store response in redux
export const getUserProfileDetails = createAsyncThunk('getUserProfileDetails', async (_, { rejectWithValue }) => {
    try {
        let res = await getUserProfileData();
        // console.debug('res', res)
        let { account_properties } = res.data;

        let sipUsername = account_properties?.sip?.username?.value?.[0] || '';
        let sipUsernameWithInstance = sipUsername;
        let sipDomain = account_properties?.sip?.sipserver?.value?.[0] || '';
        let sipPassword = account_properties?.sip?.password?.value?.[0] || '';
        let sipHa1 = account_properties?.sip?.password?.value?.[0] || '';
        let sipPort = account_properties?.sip?.sipport?.value?.[0] || '';
        let sipProtocol = account_properties?.sip?.sipprotocol?.value?.[0] || '';
        let outboundProxy =
            account_properties?.sip?.outboundProxyServer?.value?.[0] || '';
        let obProxyPort = account_properties?.sip?.outboundProxyPort?.value[0] || '';
        
        let sipData = {
            "_sipExtension": sipUsername,
            "_sipPassword": sipPassword,
            "_sipServer": sipDomain,
            "_sipUsernameWithInstance": sipUsernameWithInstance,
            "_wssServer": `wss://${sipDomain}:${sipPort}`,
            "_extNumber": sipUsername,
            "_instanceId": instanceId,
            "_sipAor": sipUsernameWithInstance + '@' + sipDomain
        };
      
        // console.debug('get profile datail call')
        // ACCOUNT CREDENTIALS
        localStorage.setItem('_accountCreds', JSON.stringify(sipData));

        return { account_properties, sipData }
    } catch (error) {
        throw rejectWithValue(error)
    }
});


//pushSunscriber api call function
export const pushSubscribe = async (values) => {
    // console.log(values);
    // value ===> { "device_token": "r8pbxrrfp4pkdilr5af7t92474r0m9je", "device_type": "deskapp", "instance_id": "ddddr8pbxrrfp4pkdilr5af7t92474r0m9je", "auth_type": "auto", "device_voip_token": "", "device_apns_token": "", "sip_username_with_instance": "ddddr8pbxrrfp4pkdilr5af7t92474r0m9je-9112345678", "sip_domain": "81.22.16.245", "sip_password": "X04?Ogl3", "sip_ha1": "X04?Ogl3", "sip_port": "5060", "sip_protocol": "udp", "outbound_proxy": "", "ob_proxy_port": "" }
    try {
        let res = await Axios.post(API_BASEURL+"push/subscribe", values, {
            headers: {
                'Authorization': `Barear ${tocken}`
            }
        });
        // return res.data
        return res.data;
    } catch (error) {
        console.log(error);
    }
};


/// deletePushSubscriber api call function this function call when user try to logout
export const deletePushSubscriber = async (values) => {
    try {
        let res = await Axios.delete(API_BASEURL+"push/subscribe", values, {
            headers: {
                'Authorization': `Barear ${tocken}`
            }
        })
        // console.debug('res', res)
        return res.data;
    } catch (error) {
        console.log('delete pushSubscriber', error)
    }
}



///// pcs Register api call function
export const pcsRegister = async (data, action) => {
    let aor = data.instance_id + '-' + data.sip_username_with_instance + "@" + data.sip_domain;
    let values = { aor, action: action };
    try {
        let res = await Axios.post(API_BASEURL+"pcs/register", values);
        return res.data
    } catch (error) {
        console.log(error);
    }
}

///// pcs status api function 
export const pcsStatus = async (data) => {
    let aor = data.instance_id + '-' + data.sip_username_with_instance + "@" + data.sip_domain
    let values = { aor };
    try {
        let res = await Axios.post(API_BASEURL+"pcs/status", values);
        return res.data
    } catch (error) {
        console.log('pcs status api error', error)
    }
}

export const profileUpdate = async (values) => {
    try {
        let res = await Axios.post(API_BASEURL+"profile/update", values, {
            headers: {
                'Authorization': `Barear ${tocken}`
            }
        });
        return res.data
    } catch (error) {
        console.log(error)
    }
}

export const updateUserSettings = createAsyncThunk('update_user_settings', async (value, { rejectWithValue }) => {
    try {
        let res = await profileUpdate(value);
        let getsettinngs = await getUserProfileData();
    } catch (error) {
        throw rejectWithValue(error)
    }
})

/* Get Profile Configuration Data API Called */
export const getconfigData = async () => {
    try {
        let res = await Axios.get('profile/get-config-data', {
            headers: {
                'Authorization': `Barear ${tocken}`
            }
        });
        return res.data
    } catch (error) {
        console.log(error)
    }
}


///store user data to local storage
function storedata(user) {
    if (user.data) {
        // console.debug('login', user.data)
        localStorage.setItem('user_id', user.data.usr_id)
        localStorage.setItem("user", JSON.stringify(user.data));
        localStorage.setItem("access_tocken", user.access_token);
        localStorage.setItem("refresh_tocken", user.refresh_tocken);
    }
}

function removedata() {
    let lRemove = ['_profileConfigSettings', 'connected', '_customSettings', 'user', 'access_tocken', 'refresh_tocken', '_accountCreds', 'user_id']
    lRemove.forEach(function (key) {
        localStorage.removeItem(key)
    })
}

export const getUserProfileDataAPI = async () => {
    // console.debug('getusr profile fun call')
    getUserProfileData().then((data) => {
        // console.info('  getUserProfileData api calling ===>', data)
        let { account_properties } = data.data;

        let sipUsername = account_properties?.sip?.username?.value?.[0] || '';
        let sipUsernameWithInstance = sipUsername;
        let sipDomain = account_properties?.sip?.sipserver?.value?.[0] || '';
        let sipPassword = account_properties?.sip?.password?.value?.[0] || '';
        let sipHa1 = account_properties?.sip?.password?.value?.[0] || '';
        let sipPort = account_properties?.sip?.sipport?.value?.[0] || '';
        let sipProtocol = account_properties?.sip?.sipprotocol?.value?.[0] || '';
        let outboundProxy =
            account_properties?.sip?.outboundProxyServer?.value?.[0] || '';
        let obProxyPort = account_properties?.sip?.outboundProxyPort?.value[0] || '';

        let values = {
            device_token: device_token,
            device_type: device_type,
            instance_id: instanceId,
            auth_type: "auto",
            device_voip_token: "",
            device_apns_token: "",
            sip_username_with_instance: sipUsernameWithInstance,
            sip_domain: sipDomain,
            sip_password: sipPassword,
            sip_ha1: sipHa1,
            sip_port: sipPort,
            sip_protocol: sipProtocol,
            outbound_proxy: outboundProxy,
            ob_proxy_port: obProxyPort,
        };

        // pushSubscribe(values).then(({ data }) => {
        //     // console.log("datta ="+ data);
        //     //  console.info('pushSubscriber api calling ===>', data)
        //     pcsRegister(data, 'enable').then((res) => {

        //         let val = {
        //             "audio.audioCodecs": getConfigSettings("audio.audioCodecs"),
        //             "video.videoCodecs": getConfigSettings("video.videoCodecs"),
        //             "call.encryption": getConfigSettings("call.encryption"),
        //             "call.dtmfType": getConfigSettings("call.dtmfType"),
        //             "sip.register.interval": getConfigSettings("sip.interval"),
        //             "sip.register.respectServerExpires": getConfigSettings("sip.respectServerExpires")
        //         }
        //         profileUpdate(val).then((res) => {
        //             // console.info('  profileUpdate api calling ===>', res)
        //             getconfigData().then(({ data }) => {

        //                 let sms_service_status = getConfigSettings("sms.status");
        //                 let profileConfigData = {
        //                     "sms_service_status": sms_service_status,
        //                     "balance_status": data.balance_status,
        //                     "recharge_status": data.recharge_status,
        //                     "toll_free_flag": data.toll_free_flag,
        //                     "toll_free_number": data.toll_free_number,
        //                     "im_service_status": data.im_status
        //                 };
        //                 AddSettings(data)


        //                 /**  Profile Config Settings */
        //                 localStorage.setItem('_profileConfigSettings', JSON.stringify(profileConfigData));
        //                 //  SIP data for local storage
        //                 let sipData = {
        //                     "_sipExtension": sipUsername,
        //                     "_sipPassword": sipPassword,
        //                     "_sipServer": sipDomain,
        //                     "_sipUsernameWithInstance": sipUsernameWithInstance,
        //                     "_wssServer": `wss://${sipDomain}:${sipPort}`,
        //                     "_extNumber": sipUsername,
        //                     "_instanceId": instanceId,
        //                     "_sipAor": sipUsernameWithInstance + '@' + sipDomain
        //                 };
        //                 // console.debug('sipData')

        //                 // ACCOUNT CREDENTIALS
        //                 localStorage.setItem('_accountCreds', JSON.stringify(sipData));

        //                 //  Custom Settings Data for local storage
        //                 let customSettingsData = {
        //                     "_dnd": false,
        //                     "_autoAnswer": false,
        //                     "_account": true,
        //                     "_pcsRegStatus": "NOT_REGISTERED_STATE",
        //                     "_sipRegStatus": "NOT_REGISTERED_STATE",
        //                     "_i18n": "en"
        //                 };
        //                 // Custom Settings
        //                 localStorage.setItem('_customSettings', JSON.stringify(customSettingsData));
        //             }).catch((error) => {
        //                 console.log(error)
        //             })
        //         }).catch((error) => {
        //             console.log(error)
        //         })
        //     }).catch((error) => {
        //         console.log(error);
        //     })
        // }).catch((error) => {
        //     console.log(error)
        // })
    }).catch((error) => console.log('error', error));

}

