import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Registerer, UserAgent } from "sip.js";
import dateFormat, { masks } from "dateformat";
import {
    ConnectedStatus,
    getAllSessionData,
    hideCallingScreeenFun,
    removeSessionData,
    setCallIncomingOutGoingSession,
    setCallCancel,
    setConncetInfo,
    setRegisterer,
    setSessionState,
    setUserAgent,
    setregState,
    showCallingScreeenFun,
    toggleVideoCallingScreen,
} from "../redux/reducers/sipjs/sipjsSlice";
import { setTimer } from '../redux/reducers/themeSlice';

import { toast } from "react-toastify";
import { addToCallHistory } from '../redux/reducers/Contacts/contactSlice'
// import useCheckPcsStaus from "./useCheckPcsStaus";
import { getUserProfileDataAPI } from "../redux/reducers/Auth/authService";
import { getConfigSettings } from "../data/userSettings";
import { UpdatecustomSettings } from "../redux/reducers/Auth/authSlice";
import { cleanupMedia, getObjectCount } from "../utils/Utils";
import { appName, sipConfig } from "../componunt/BASEURL";
import { sessionData } from "./usePhonedial";
import { acceptIncomingCall, callHangUp, setupRemoteMedia } from "../redux/reducers/sipjs/sipjsService";

const setProfileData = async () => {
    if (!localStorage.getItem('_accountCreds')) {
        await getUserProfileDataAPI();
    }
}
let _accountCreds = {};

// Number of times to attempt reconnection before giving up
const reconnectionAttempts = 3;
// Number of seconds to wait between reconnection attempts
const reconnectionDelay = 4;

// Used to guard against overlapping reconnection attempts
let attemptingReconnection = false;
// If false, reconnection attempts will be discontinued or otherwise prevented
let shouldBeConnected = true;

const now = new Date();
let UA = {}
const useCreateUa = () => {

    let userAgentOptions = {}
    let registererOptions = {};
    
    if (localStorage.getItem('_accountCreds')) {

        const _accountCreds = JSON.parse(localStorage.getItem('_accountCreds'));
     
        let _sipExtension = _accountCreds._sipExtension;
        let _sipServer = _accountCreds._sipServer;
        let _sipPassword = _accountCreds._sipPassword;
        let _deviceUID = _accountCreds._instanceId;
        let _wssServer = _accountCreds._wssServer;
        let sipTrace = sipConfig._logEnable;
        let earlyMedia = "true";
        const displayName = getConfigSettings('call.outboundcalleridname') != '' ? getConfigSettings('call.outboundcalleridname') : _sipExtension;
        // _sipPassword = 'nimit123'
        // _sipExtension = '919898259865'
        // _sipServer = "switch.nyerhosmobile.com:7443"
        userAgentOptions = {
            uri: UserAgent.makeURI(`sip:${_sipExtension}@${_sipServer}`),
            transportOptions: {
                server: _wssServer,//'wss://switch.nyerhosmobile.com:7443',// wssServer,
                traceSip: true,
            },
            userAgentString: appName,
            authorizationUser: _sipExtension,
            authorizationPassword: _sipPassword,
            sipExtension100rel: "Supported",
            register: true,
            dtmfType: getConfigSettings('call.dtmftype') != "" ? getConfigSettings('call.dtmftype') : 'info',
            contactTransport: "wss",
            noAnswerTimeout: getConfigSettings(' call.noAnswertimeout') != "" ? getConfigSettings(' call.noAnswertimeout') : 60,
            displayName: displayName,
            contactParams: { transport: "wss" },
            hackIpInContact: true,
            sessionDescriptionHandlerFactoryOptions: {
                peerConnectionOptions: {
                    rtcpMuxPolicy: "negotiate",
                    iceCheckingTimeout: 1000,
                    iceTransportPolicy: "all",
                    iceServers: [{ urls: sipConfig._iceServer }],
                }
            },
            logBuiltinEnabled: sipTrace, //	Boolean - true or false - If true throws console logs
        };

        // console.log(userAgentOptions);  return

        registererOptions = {
            expires: getConfigSettings('sip.registerexpires') != "" ? getConfigSettings('sip.registerexpires') : 600,
            extraContactHeaderParams: [], // ["transport=wss"],
        };
    } else {
        setProfileData();
    }
    // console.warn(userAgentOptions)
    const dispatch = useDispatch();
    const [incomingCall, setincomingCall] = useState(false);
    const [showIncomingCallScreen, setShowIncomingCallScreen] = useState(false)
    const [allSessions, setSessions] = useState();
    let connectedTime = dateFormat(now, "dddd, mmmm dS, yyyy, h:MM:ss TT");
    // const checkPcsStatus = useCheckPcsStaus();

    const createUA = async () => {
        try {
            const userAgent = new UserAgent(userAgentOptions);
            const registerer = new Registerer(userAgent, registererOptions);
            UA = userAgent;
            userAgent
                .start()
                .then(() => {
                    registerer.stateChange.addListener((newState) => {
                        dispatch(setregState(newState))
                        // console.debug("=====11111");
                        // console.debug(newState);
                        switch (newState) {
                            case "Initial":
                                dispatch(UpdatecustomSettings({ key: "_sipRegStatus", value: 'REGISTERING_STATE' }))
                                console.log("UserAgent ==> Initial");
                                break;
                            case "Registered":
                                dispatch(UpdatecustomSettings({ key: "_sipRegStatus", value: 'REGISTERED_STATE' }))
                                console.log("UserAgent ==> Registered");
                                break;
                            case "Unregistered":
                                dispatch(UpdatecustomSettings({ key: "_sipRegStatus", value: 'UNREGISTERED_STATE' }))
                                console.log("UserAgent ==> Unregistered");
                                break;
                            case "Terminating":
                                console.log("UserAgent ==> Terminating");
                                break;
                            case "Terminated":
                                dispatch(UpdatecustomSettings({ key: "_sipRegStatus", value: 'UNREGISTERED_STATE' }))
                                console.warn("UserAgent ==> Terminated");
                                userAgent.stop();
                                break;
                            default:
                                console.log("UserAgent ==> Unidentified - ", newState);
                                break;
                        }
                    });
                    registerer
                        .register()
                        .then((request) => {
                            console.log("Successfully sent REGISTER, object is here");
                        })
                        .catch((error) => {
                            dispatch(UpdatecustomSettings({ key: "_sipRegStatus", value: 'UNREGISTERED_STATE' }))
                            console.log("Failed to send REGISTER", error);
                        });
                    let connection_information = {
                        connectedTime: connectedTime,
                        connectingStatus: ConnectedStatus.connected,
                        connected: true
                    }
                    localStorage.setItem('connected', true);
                    dispatch(setConncetInfo(connection_information))
                    dispatch(setUserAgent(userAgent));
                    dispatch(setRegisterer(registerer));
                })
                .catch((error) => {
                    let connection_information = {
                        connectedTime: connectedTime,
                        connectingStatus: ConnectedStatus.failed,
                        connected: false
                    }
                    localStorage.setItem('connected', false);
                    dispatch(setConncetInfo(connection_information))
                    dispatch(setUserAgent(userAgent));
                    dispatch(setRegisterer(registerer));
                    console.debug("user agent connect error", error);
                    attemptReconnection();
                });

            /*
             * Setup handling for incoming INVITE requests
             */

            userAgent.delegate = {

                async onInvite(invitation) {

                    let customSettings = JSON.parse(localStorage.getItem('_customSettings'))
                    console.debug("customSettings"+customSettings)
                    
                    let incomingUser = invitation.remoteIdentity.displayName;
                    let userNumber = invitation.remoteIdentity.uri.normal.user

                    // console.debug('invitationReq', invitationReq)
                    // TODO =>
                    if (getObjectCount(sessionData) > 0) {
                        callHangUp(invitation, 'incoming')
                        dispatch(addToCallHistory({ number: userNumber, missedCall: true }))
                        console.debug('ongoig call is present', getObjectCount(sessionData))
                    }

                    if (getObjectCount(sessionData) === 0) {
                        dispatch(addToCallHistory({ number: userNumber, incoming: true }))
                        console.debug('isdfc dvgjdfvxc  dsxcv ', getObjectCount(sessionData))

                        let session = {
                            callDirection: "incoming",
                            session: invitation,
                            incomingUser: incomingUser,
                            sessionId: invitation.id,
                        };


                        dispatch(setCallIncomingOutGoingSession(session))
                        // if (customSettings._dnd) {

                        //     var rejectOptions = {
                        //         'statusCode': 603,
                        //         'reasonPhrase': "Decline"
                        //     };
                        //     invitation.reject(603);

                        //     dispatch(addToCallHistory({ number: userNumber, missedCall: true }))
                        //     toast.error('You have missed call from' + incomingUser)
                        //     // dispatch(showDialpadeSection())
                        //     dispatch(toggleVideoCallingScreen(false))
                        //     dispatch(removeSessionData(invitation.id))
                        // } else {
                            var has_video = false;
                            // let sessionObj = invitation.incomingInv  iteRequest.message.data.toString();

                            // if (sessionObj.includes('m=video') && sessionObj.includes('m=audio')) {
                            //     console.log('include Video');
                                has_video = true;
                            //     // dispatch(hideDialpadeSection())
                            //     dispatch(toggleVideoCallingScreen(true))
                            // }

                            // if (customSettings._autoAnswer) {
                            //     acceptIncomingCall(invitation)
                            //     dispatch(addToCallHistory({ number: userNumber, missedCall: true }))
                            //     toast.success('Auto accepted Incoming call from ' + incomingUser)

                            //     dispatch(showCallingScreeenFun())
                            // } else {
                                setShowIncomingCallScreen(true)
                                dispatch(setCallIncomingOutGoingSession(session))
                            // }

                        // }




                        ///////////////////////////////////////////////////////
                        //  TRAG-1886 - Manage call answered elsewhere
                        //  Invitation session delegate
                        // console.log("Negative response ....");
                        invitation.delegate = {
                            
                            //  Handle incoming onCancel request
                            requestDelegate: {
                                onAccept: (response) => {
                                    console.log("Positive response ....");
                                    dispatch(setCallIncomingOutGoingSession(session))
                                    // console.log(response);
                                },
                                onReject: (response) => {

                                    console.log("Negative response ....");
                                    dispatch(removeSessionData(invitation.id))
                                    cleanupMedia()
                                },
                                // Handle incoming REFER request.
                                onRefer(referral) {
                                    console.debug('incoming refer', referral)                                    // ...
                                }
                            },



                            onCancel(message) {
                                dispatch(setCallCancel(1))
                                // console.debug("ON CANCEL - message ==> ", message);
                                // console.debug("ON CANCEL - message.incomingCancelRequest ==> ", message.incomingCancelRequest);
                                //   isCallAnsweredElsewhere = true;   //  TRAG-1886 - Manage call answered elsewhere
                            }



                        };




                        invitation.stateChange.addListener((state) => {
                            console.debug(`Session state changed to ${state}`);
                            dispatch(setSessionState(state))
                            switch (state) {
                                case 'Initial':
                                    console.debug('incomming session state Initial');
                                    break;
                                case 'Establishing':
                                    console.debug('Incoming Session state Establishing');
                                    break;
                                case 'Established':
                                    console.debug('Incoming Session state Established')
                                    // setupRemoteMedia(session);
                                    dispatch(setTimer(1))
                                    setupRemoteMedia(invitation, has_video)
                                    break;
                                case 'Terminating':
                                    dispatch(removeSessionData(invitation.id))
                                    dispatch(setTimer(2))
                                    break;
                                case 'Terminated':
                                    dispatch(removeSessionData(invitation.id))
                                    setShowIncomingCallScreen(false)
                                    // dispatch(showDialpadeSection())
                                    dispatch(hideCallingScreeenFun())
                                    cleanupMedia();
                                    console.debug('Incoming Session state Terminated')
                                    break;
                                default:
                                    console.debug('Unknow Incomming Session state')
                            }
                        })

                    }
                },
                onConnect(error) {
                    let connection_information = {
                        connectedTime: connectedTime,
                        connectingStatus: ConnectedStatus.connected,
                        connected: true
                    }
                    localStorage.setItem('connected', true);
                    console.debug('onConnect - UA Delegate.');

                },

                onDisconnect(error) {
                    let connection_information = {
                        connectedTime: connectedTime,
                        connectingStatus: ConnectedStatus.failed,
                        connected: false
                    }
                    localStorage.setItem('connected', false);
                    dispatch(setConncetInfo(connection_information))
                    console.warn('disconncet dfvdcgvfcv')
                    dispatch(UpdatecustomSettings({ key: "_sipRegStatus", value: 'UNREGISTERED_STATE' }))

                    registerer.unregister().then(() => {
                        console.warn("onDisconnect - Unregistered event success");
                    }).catch(e => {
                        console.warn('onDisconnect - Unregister failed with cause ' + e)
                    });




                    if (error) {
                        console.info('trying to reconnect')
                        attemptReconnection();
                    }
                }
            };
            // checkPcsStatus();
        } catch (error) {
            let connection_information = {
                connectedTime: connectedTime,
                connectingStatus: ConnectedStatus.failed,
                connected: false
            }
            localStorage.setItem('connected', false);
            dispatch(setConncetInfo(connection_information))

            dispatch(setregState('Unregistered'))
            dispatch(UpdatecustomSettings({ key: "_sipRegStatus", value: 'UNREGISTERED_STATE' }))
            console.debug("userAgent create Error", error);
        }
    };
    return [createUA, showIncomingCallScreen, setShowIncomingCallScreen, setSessions];
};






export const attemptReconnection = (reconnectionAttempt = 1) => {
    console.debug('reconnection')
    // If not intentionally connected, don't reconnect.
    if (!shouldBeConnected) {
        return;
    }

    // Reconnection attempt already in progress
    if (attemptingReconnection) {
        return;
    }

    // Reconnection maximum attempts reached
    if (reconnectionAttempt > reconnectionAttempts) {
        return;
    }

    // We're attempting a reconnection
    attemptingReconnection = true;

    setTimeout(() => {
        // If not intentionally connected, don't reconnect.
        if (!shouldBeConnected) {
            attemptingReconnection = false;
            return;
        }
        // Attempt reconnect
        UA.reconnect()
            .then(() => {
                // Reconnect attempt succeeded
                attemptingReconnection = false;
            })
            .catch((error) => {
                // Reconnect attempt failed
                attemptingReconnection = false;
                attemptReconnection(++reconnectionAttempt);
            });
    }, reconnectionAttempt === 1 ? 0 : reconnectionDelay * 1000);

};


export default useCreateUa;