import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { hangup, setupRemoteMedia } from '../redux/reducers/sipjs/sipjsService';
import { hideCallingScreeenFun, hideTransferStrip, removeSessionData, setActiveSessionId, showCallingScreeenFun } from '../redux/reducers/sipjs/sipjsSlice';
import { showDialpadeSection } from '../redux/reducers/themeSlice';
import { cleanupMedia, getObjectCount } from '../utils/Utils';
import useHoldUnholdCall from './useHoldUnholdCall';
import { removeSessionRecord } from './usePhonedial';

const mediaElement = document.getElementById("mediaElement");
const phonetime = document.getElementById("phonetime");
const connectinfRigton = document.getElementById('connectinfRigton')


const useCallHangup = () => {
    const { sessions, sessionId, callDirection } = useSelector((state) => state.sipjs)
    const [showHoldButton, toggeCallHoldUnhold] = useHoldUnholdCall(true);
    const dispatch = useDispatch();

    let session = sessions[sessionId];


    const callHangUp = () => {
        // session.reject(603);
        // 
        switch (session && session.state) {
            
            case "Initial":
            case "Establishing":
                if (callDirection === "outgoing") {
                    session.cancel();
                }
                if (callDirection === "incoming") {
                    console.log(callDirection+"================callDirection");
                    session.reject(603);
                }
                // callHangUp();
                //     mediaElement.srcObject = null;
                // mediaElement.currentTime = null;
                break;
            case "Established":
                // mediaElement.currentTime = null;
                session.bye();
                //mediaElement.srcObject = null;
                dispatch(removeSessionData(session.id))
                // dispatch(showDialpadeSection())
                dispatch(hideCallingScreeenFun())
                cleanupMedia();
                break;
            case "Terminating":
                break;
            case "Terminated":
                cleanupMedia();
                break;
        }


        if (getObjectCount(sessions) > 1) {
            removeSessionRecord(sessionId);
            let activeSessionId = sessions[Object.keys(sessions)[0]].id;
            let activeSession = sessions[activeSessionId];
            console.debug('active sessions', activeSession)
            setupRemoteMedia(activeSession)
            dispatch(showCallingScreeenFun())
        }


        // if (activeSessionId != '') {
        //     let activeSession = sessions[activeSessionId];
        //     console.debug('active sessions', activeSession)
        //     setupRemoteMedia(activeSession);
        // }


    }

    return callHangUp
}

export default useCallHangup


// const callCut = async () => {
//     console.debug('call hangup fun hook call')
//     console.debug('sessions', sessions)
//     console.debug('session_id', sessionId)
//     console.debug('call direction', callDirection)
//     let res = await dispatch(hangup({ sessions: sessions, callDirection, sessionId }));
//     dispatch(showDialpadeSection())
//     dispatch(hideCallingScreeenFun())
//     removeSessionRecord(sessionId)
// }