import React, { useState, useEffect } from "react";
import OrgDetails from "./OrgDetails";
import Departments from "./Departments";
import Employee from "./Employee";
import Projects from "./Projects";
import WorkingShifts from "./WorkingShifts";
import DesktopApps from "./DesktopApps";
import Notifications from "./Notifications";
import Holidays from "./Holidays";
import Alert from "./Alert";
import LeaveType from "./LeaveType";
import AccountSettings from "./AccountSettings";
import Masteradmin from "../Masteradmin";
import JobVacancies from "../admin/JobVacancies";
import JobApplication from "../admin/JobApplications";
import JobQuestionaries from "../admin/jobQuestionaries";
import CallDetails from "../admin/CallDetailsList";
import ProjectHrs from "../admin/ProjectHrs";
import ProjectToClient from "../admin/ProjectToClient";
import Clients from "../admin/Clients";
import Sms from "../admin/Sms";
import { Link, useParams,useNavigate } from "react-router-dom";
import  SecurityEntries from "../admin/SecurityEntries";
import Activity from "./Activity";
import EvolutionPoints from "./EvolutionPoints";
import PunchTicket from "./PunchTicket";

const Profile = () => {
  let [tabtype, settabtype] = useState(JSON.parse(localStorage.getItem("isAuth")).master==1 ? 3 : 0 );
  let [master, ] = useState(JSON.parse(localStorage.getItem("isAuth")).master);
  const [isAuth, ] = useState(JSON.parse(localStorage.getItem("isAuth")));
  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if(isAuth.role===1 || isAuth.username==="1150"){
      settabtype(12); 
    }
    if(isAuth.role===2 && isAuth.username!=="1150"){
      navigate('/'); 
    }
    
    if(params.type){
      settabtype(+params.type); 
    }
  }, [])
  
  useEffect(() => {
    if(params.type){
     settabtype(+params.type);   
    }
  }, [params.type])
 
  return (
    <>
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-12 lg:col-span-2 2xl:col-span-2 flex lg:block flex-col-reverse">
          <div className="intro-y box p-3 mt-5">
            {isAuth.role === 0 &&
            <div className="mt-1" style={{ cursor: "pointer" }}>
              {master === 0 ? <>
                <Link
                to={'/admin/0'}
                className={
                  tabtype === 0
                    ? "flex items-center  p-2 rounded-md activebg"
                    : "flex items-center p-2  rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/org-detail-icon.png"
                  alt=""
                />
                <div className="text-left "> Organization Details </div>
              </Link>
              
              <Link
                to={'/admin/1'}
                className={
                  tabtype === 1
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/department-icon.png"
                  alt=""
                />
                <div className="text-left">Departments </div>
              </Link>
{/* 
              <div
                onClick={() => settabtype(2)}
                className={
                  tabtype === 2
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/designation-icon.png"
                  alt=""
                />
                Designations
              </div> */}
              </> :null }

              {master === 1 ? (
                <Link
                to={'/admin/11'}
                  className={
                    tabtype === 11
                      ? "flex items-center px-3 py-2 rounded-md activebg"
                      : "flex items-center px-3 py-2 rounded-md"
                  }
                >
                  <img
                    width="17"
                    className="mr-2"
                    src="./images/icons/account-setting-icon.png"
                    alt=""
                  />
                  <div className="text-left">Organizations</div>
                </Link>
              ) : null}

              <Link
                to={'/admin/3'}
                className={
                  tabtype === 3
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/employee-type-icon.png"
                  alt=""
                />
                Employee
              </Link>
             
              <Link
                to={'/admin/21'}
                className={
                  tabtype === 21 || tabtype === 22
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/employee-type-icon.png"
                  alt=""
                />
                Projects
              </Link>

              {/* <div className={tabtype==0 ? 'flex items-center px-3 py-2 rounded-md activebg' : 'flex items-center px-3 py-2 rounded-md'}> <img width="17" className='mr-2' src="./images/icons/employee-type-icon.png" alt="" /> Employee Type </div> */}

              {/* <div className={tabtype==0 ? 'flex items-center px-3 py-2 rounded-md activebg' : 'flex items-center px-3 py-2 rounded-md'}> <img width="17" className='mr-2' src="./images/icons/employee-type-icon.png" alt="" /> Employment Type</div> */}

              {master === 0 ? <>
                <Link
                to={'/admin/4'}
                className={
                  tabtype === 4
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/working-shift-icon.png"
                  alt=""
                />
                <div className="text-left"> Working Shifts</div>
              </Link>

              <Link
                to={'/admin/5'}
                className={
                  tabtype === 5
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/desktop-app-icon.png"
                  alt=""
                />
                <div className="text-left"> Desktop Apps</div>
              </Link>

              </>: null}
              
{/* 
              <Link
                to={'/admin/6'}
                className={
                  tabtype === 6
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/admin-notification-setting-icon.png"
                  alt=""
                />
                Notifications
              </Link> */}
              {master === 1 ? <>
              {/* <Link
                to={'/admin/12'}
                // onClick={() => settabtype(12)}
                className={
                  tabtype === 12
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/department-icon.png"
                  alt=""
                />
                Job Applications
              </Link>

              <Link
                to={'/admin/13'}
                className={
                  tabtype === 13
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/department-icon.png"
                  alt=""
                />
                Job Vacancies 
              </Link> */}
              
              <Link
                to={'/admin/15'}
                className={
                  tabtype === 15
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/admin-notification-setting-icon.png"
                  alt=""
                />
                Call History
              </Link>
              
              <Link
                to={'/admin/16'}
                className={
                  tabtype === 16
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/designation-icon.png"
                  alt=""
                />
                SMS History
              </Link>
              
              <Link
                to={'/admin/17'}
                className={
                  tabtype === 17
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/designation-icon.png"
                  alt=""
                />
                Security Entries
              </Link>
              
              </>:null}

              {master === 0 ? <>
              
              

                {/* <Link
                to={'/admin/7'}
                className={
                  tabtype === 7
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/alert-icon.png"
                  alt=""
                />
                Alerts
              </Link> */}
             
              {/* <Link
                to={'/admin/8'}
                className={
                  tabtype === 8
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/leave-type-icon.png"
                  alt=""
                />
                <div className="text-left"> Leave Types</div>
              </Link> */}

              <Link
                to={'/admin/9'}
                className={
                  tabtype === 9
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/holiday-icon.png"
                  alt=""
                />
                Holidays
              </Link>

              <Link
                to={'/admin/20'}
                className={
                  tabtype === 20
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/working-shift-icon.png"
                  alt=""
                />
                Clients
              </Link>

              <Link
                to={'/admin/18'}
                className={
                  tabtype === 18
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/working-shift-icon.png"
                  alt=""
                />
                Manage Project Hours
              </Link>
             
              <Link
                to={'/admin/19'}
                className={
                  tabtype === 19
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/working-shift-icon.png"
                  alt=""
                />
                Assign Project to Client
              </Link>
            
{/* 
              <div
                onClick={() => settabtype(10)}
                className={
                  tabtype === 10
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/account-setting-icon.png"
                  alt=""
                />
                <div className="text-left">Account Settings</div>
              </div> */}
              </> :null}
              
            </div>}
              
            {isAuth.role === 0 || isAuth.role === 1 || isAuth.username==="1150" ? <>
              <Link
                to={'/admin/12'}
                className={
                  tabtype === 12
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/department-icon.png"
                  alt=""
                />
                Job Applications
              </Link>
              <Link
                to={'/admin/13'}
                className={
                  tabtype === 13
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/admin-notification-setting-icon.png"
                  alt=""
                />
                Job Vacancies 
              </Link>
              </>
              :null}
               <Link
                to={'/admin/23'}
                className={
                  tabtype === 23
                    ? "flex items-center px-3 py-2 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/evolution.png"
                  alt=""
                />
                Evolution points
              </Link>
              <Link
                to={'/admin/24'}
                className={
                  tabtype === 24
                    ? "flex items-center px-3 rounded-md activebg"
                    : "flex items-center px-3 py-2 rounded-md"
                }
              >
                <img
                  width="17"
                  className="mr-2"
                  src="./images/icons/attendance.png"
                  alt=""
                />
                Attendance Ticket
              </Link>
              
          </div>
        </div>
        <div className="col-span-12 lg:col-span-10 2xl:col-span-10">
          {tabtype === 0 ? <OrgDetails /> : null}
          {tabtype === 1 ? <Departments /> : null}
          {/* {tabtype === 2 ? <Designations /> : null} */}
          {tabtype === 3 ? <Employee /> : null}
        

          {tabtype === 4 ? <WorkingShifts /> : null}
          {tabtype === 5 ? <DesktopApps /> : null}
          {tabtype === 6 ? <Notifications /> : null}
          {tabtype === 7 ? <Alert /> : null}
          {tabtype === 8 ? <LeaveType /> : null}
          {tabtype === 9 ? <Holidays /> : null}
          {tabtype === 10 ? <AccountSettings /> : null}
          {tabtype === 11 ? <Masteradmin /> : null}
          {tabtype === 12 ? <JobApplication/> : null}
          {tabtype === 13 ? <JobVacancies/> : null}
          {tabtype === 14 ? <JobQuestionaries/> : null}
          {tabtype === 15 ? <CallDetails/> : null}
          {tabtype === 16 ? <Sms/> : null}
          {tabtype === 17 ? <SecurityEntries/> : null}
          {tabtype === 18 ? <ProjectHrs/> : null}
          {tabtype === 19 ? <ProjectToClient/> : null}
          {tabtype === 20 ? <Clients/> : null}
          {tabtype === 21 ? <Projects/> : null}
          {tabtype === 22 ? <Activity/> : null}
          {tabtype === 23 ? <EvolutionPoints /> : null}
          {tabtype === 24 ? <PunchTicket /> : null}

        
        </div>
      </div>
    </>
  );
};

export default Profile;
