import { createSlice, nanoid } from '@reduxjs/toolkit'
import { getEnterPriseContact } from './ContactService';
import dateFormat, { masks } from "dateformat";
import { toast } from 'react-toastify';
const now = new Date();
const user_id = localStorage.getItem('user_id');
const callHistory = localStorage.getItem('callhistory') ? JSON.parse(localStorage.getItem('callhistory')) : [];

export const callType = Object.freeze({
    incoming: "incoming",
    outgoing: "outgoing",
    missedCall: 'missedCall'

})


const initialState = {
    contacts: [],
    isLoading: false,
    isSucess: true,
    isError: false,
    status: "ERROR",
    callhistory: callHistory
}

export const contactSlice = createSlice({
    name: "contacts",
    initialState,
    reducers: {
        searchContact: (state, action) => {
            state.contacts = action.payload
        },
        removeFromHistory: (state, action) => {
            state.callhistory = state.callhistory.filter((ele) => ele.id != action.payload);
            localStorage.setItem('callhistory', JSON.stringify(state.callhistory))
            toast.success("Call history Deleted successfully");
        },
        clearHistory: (state, action) => {
            state.callhistory = state.callhistory.filter((ele) => ele.user_id != localStorage.getItem('user_id'));
            localStorage.setItem('callhistory', JSON.stringify(state.callhistory))
            toast.success("Call history Clear successfully");
        },
        addToCallHistory: (state, action) => {
            let call = {
                "number": `${action.payload.number}`,
                "time": dateFormat(now, "d  mmm, yyyy h:MM TT"),
                "incoming": action.payload.incoming || false,
                "otgoing": action.payload.otgoing || false,
                'missedCall': action.payload.missedCall || false,
                "id": nanoid(),
                "user_id": localStorage.getItem('user_id')

            }
            // console.debug('call history', call)
            state.callhistory = [...state.callhistory, call];
            localStorage.setItem('callhistory', JSON.stringify(state.callhistory))
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getEnterPriseContact.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(getEnterPriseContact.fulfilled, (state, action) => {
                const { data, status } = action.payload

                if (status === "SUCCESS") {
                    state.contacts = data;
                    state.isError = false;
                } else {
                    state.contacts = null;
                    state.isError = true;
                }
                state.isLoading = false;
            })
    }

})


export default contactSlice.reducer;
export const { searchContact, removeFromHistory, clearHistory, addToCallHistory } = contactSlice.actions