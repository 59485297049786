
import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../../../utils/Axios";

let tocken = localStorage.getItem('access_tocken');
export const getEnterPriseContact = createAsyncThunk(
    'directory/getConatct',
    async (access_tocken = null, { rejectWithValue }) => {
        try {
            if (access_tocken == null) {
                access_tocken = tocken
            }
            let res = await Axios.get('/directory/get-enterprise_directory-detail', {
                headers: {
                    'Authorization': `Barear ${access_tocken}`
                }
            });
            return res.data;
        } catch (error) {
            throw rejectWithValue(error.message)
        }
    });

