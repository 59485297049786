import React, { useState, useEffect } from "react";
import { BASEURL,SITEURL } from "../BASEURL";
import ReactPaginate from "react-paginate";
import "react-toastify/dist/ReactToastify.css";
// import swal from "sweetalert";
import HashLoader from "react-spinners/HashLoader";
import {
  FaFileDownload, FaTasks, FaUserAlt
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Departments = () => {
  const [appdata, setappdata] = useState(null);
  const [bkpdata, setbkpdata] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [items, setitems] = useState([]);

  let [loading, setLoading] = useState(false);
  // let [msg, setmsg] = useState(false);
  let [master, ] = useState(JSON.parse(localStorage.getItem("isAuth")).master);

  useEffect(() => {
    const endOffset = itemOffset + 10;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / 10));
    getData();
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + 10;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / 10));
  }, [itemOffset]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 10) % items.length;

    setItemOffset(newOffset);
  };

  const getData = async () => {
    setLoading(true);
    const myHeaders = new Headers();
    let appdata = JSON.parse(localStorage.getItem("isAuth"));
    if (appdata != null) {
      let token = appdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `getAllSecurityData/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        company_uuid: appdata.companydata[0].uuid,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          let arr = [];
          let arr1 = [];
          for (const key in res.data) {
            arr.push(key);
            if (key < 9) {
              arr1.push(key);
            }
          }
          setitems(arr);

          setCurrentItems(arr1);

          setPageCount(Math.ceil(arr.length / 10));

          setbkpdata(res.data);
          setappdata(res.data);
        } else {
          setappdata({});
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const filterdata = (event) => {
    if (event.target.value === "") {
      loading ? <HashLoader /> : setappdata(bkpdata);

      let arr = [];
      for (const key in bkpdata) {
        arr.push(key);
      }
      setitems(arr);

      setCurrentItems(arr);
      setPageCount(Math.ceil(arr.length / 10));
    } else {
      var matches = bkpdata.filter(function (s) {
        return s.name.toLowerCase().includes(`${event.target.value}`) ||
         s.phone.toLowerCase().includes(`${event.target.value}`) ||
         s.email.toLowerCase().includes(`${event.target.value}`) ||
         s.reason.toLowerCase().includes(`${event.target.value}`) ||
         s.to_whom_wantto_meet.toLowerCase().includes(`${event.target.value}`) ||
         s.created_at.toLowerCase().includes(`${event.target.value}`)
      });

      let arr = [];
      for (const key in matches) {
        arr.push(key);
      }
      setitems(arr);

      const newOffset = (matches.length * 10) % matches.length;
      setItemOffset(newOffset);
      setappdata(matches);
      const endOffset = itemOffset + 10;
      setCurrentItems(arr.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(matches.length / 10));
    }
  };

 const fun =(src)=>{
      var w = window.open('about:blank');  
      setTimeout(function(){ //FireFox seems to require a setTimeout for this to work.
        w.document.body.appendChild(w.document.createElement('iframe')).src = src;
        w.document.body.style.margin = 0;
        w.document.getElementsByTagName("iframe")[0].style.width = '100%';
        w.document.getElementsByTagName("iframe")[0].style.height = '100%';
        w.document.getElementsByTagName("iframe")[0].style.border = 0;
      }, 0);
  }


  return (
    <div className="intro-y box lg:mt-5">
      <div className="flex items-center border-b border-slate-200/60 dark:border-darkmode-400">
        <h2 className="font-medium text-base p-2 mr-auto">Security Entry List</h2>        
      </div>
      <div className="p-5">
      <div className="flex flex-col sm:flex-row sm:items-end xl:items-start mb-4">
          <div className="flex mt-5 sm:mt-0">
            <div className="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
              <input
                id="tabulator-html-filter-value"
                type="text"
                onChange={filterdata}
                className="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
                placeholder="Search..."
              />              
            </div>
          </div>
          {/* <Link to={'/job-questionaries'}>
          <button class="btn btn-primary mx-3 pt-2 btn-sm invisible sm:visible">Add Job Questions</button>
          </Link> */}
         
          {/* <div className="hidden md:block ml-auto text-slate-500">
            Showing {itemOffset + 1} to  {appdata.length > itemOffset + 10 ? itemOffset + 10 : appdata.length} of {appdata.length} entries
          </div> */}
        </div>
        <div className="overflow-x-auto">
          {loading ? (
            <HashLoader
              color="#5755d9"
              size={30}
              style={{ position: "absolute", right: "50%" }}
            />
          ) : (
            <table className="table table-bordered" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th className="whitespace-nowrap" style={{width:"20px"}}>Name</th>
                  <th className="whitespace-nowrap" >Email ID</th>
                  <th className="whitespace-nowrap">Contact Number</th>
                  <th className="whitespace-nowrap">Reason</th>
                  <th className="whitespace-nowrap">To Whom Want to Meet</th>                  
                  <th className="whitespace-nowrap">Date Time</th>                  
                  <th className="whitespace-nowrap">Photo</th> 
                </tr>
              </thead>
              <tbody>
              {currentItems &&
                  currentItems.length === 0 && 
                    <tr><td colSpan="8">No data available</td></tr>
                }
                {currentItems &&
                  currentItems.length > 0 &&
                  currentItems.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                        <Link title="Application Details" to={'/appdetails/'+appdata[item].application_id}>
                        {appdata[item].name}</Link></td>
                        <td style={{width:"30px"}}>{appdata[item].email}</td>
                        <td>{appdata[item].phone}</td>
                        <td>{appdata[item].reason}</td>
                        <td>{appdata[item].to_whom_wantto_meet}</td>
                        <td>{appdata[item].created_at}</td>
                        <td >
                          <div style={{
                            display: "flex"
                          }}>
                         {appdata[item].photo!==""? 
                            <FaUserAlt onClick={()=>fun(appdata[item].photo)} className="cursor-pointer ml-3" style={{fontSize:"17px"}}/>
                          :null }
                        </div>
                          
                          
                          
                          
                          {/* </button> */}
                          </td>                        
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}

<div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
            <br />
            <nav className="w-full sm:w-auto sm:mr-auto">
              {!loading ? (
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-4">
                    <ReactPaginate
                      pageCount={pageCount}
                      pageRangeDisplayed={10}
                      marginPagesDisplayed={1}
                      onPageChange={handlePageClick}
                      containerClassName="pagination"
                      activeClassName="active"
                      pageLinkClassName="page-link"
                      breakLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      pageClassName="page-item"
                      breakClassName="page-item"
                      nextClassName="page-item"
                      previousClassName="page-item"
                      previousLabel={<>&laquo;</>}
                      nextLabel={<>&raquo;</>}
                    />
                  </div>
                  <div className="col-md-4"></div>
                </div>
              ) : null}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Departments;
