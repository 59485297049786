import React,{useState, useEffect} from 'react'
import swal from "sweetalert";
import { BASEURL } from "./BASEURL";
import HashLoader from "react-spinners/HashLoader";
import axios from "axios";
import { useNavigate, useParams } from 'react-router-dom';

const JobAssessment = (props) => {
    const params = useParams();
    const [assdata,setassdata] = useState();
    const [jobdata,setjobdata] = useState([]);
    const [quedata,setquedata] = useState([]);
    const [quedata1,setquedata1] = useState([]);
    const [interviewername,setinterviewername] = useState("");
    const [overallassessment,setoverallassessment] = useState("");
    const [IsAssessment,setIsAssessment] = useState(false);
    const [IsAssessment1,setIsAssessment1] = useState(false);
    const [positions, setpositions] = useState([]);
    let navigate = useNavigate();

    const handlechange = (evnt,index="")=>{
      const { name, value } = evnt.target;
      const rowsInput = [...quedata];
      if(rowsInput[index]['question_type']==1){
        if(rowsInput[index][name] && rowsInput[index][name]!=undefined){
           rowsInput[index][name].push(value);
        }else{
          rowsInput[index][name] = [value];
        }
      }else{
        rowsInput[index][name] = value;
      }
      // console.log(rowsInput);
      setquedata([...rowsInput]);     
    }

    useEffect(() => {
      getPositions()
      getData()
    }, [])
    
    const getPositions = async() => {
      setLoading(true);
      const myHeaders = new Headers();
      let userdata = JSON.parse(localStorage.getItem("isAuth"));
      if (userdata != null) {
        let token = userdata.usertoken;
        myHeaders.append("Authorization", token);
      }
      myHeaders.append("Content-Type", "application/json");
      await fetch(BASEURL + `getAllPositions/`, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          company_uuid: "abc3bab0-2b6e-4fb7-a453-4049c8157ac5",
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.status === 201) {
            setpositions(res.data);
          } else {
            setpositions([]);
          }
        })
        .catch((err) => console.log(err));

      setLoading(false);
    }

    const getData = async()=>{
        const formData = new FormData()

        // console.log(window.location.pathname);
        let path = window.location.pathname.replace('/job-application-general/','')
        if(path===""){
          navigate("/job-application");
          navigate(0);
        }
        formData.append('uuid',path)
        // console.log(path);
        const res = await axios.post(BASEURL + "get-jobdata", formData, {
            headers: {
              "Content-Type": "application/json",
            },
        });

        if (res.data.status === 201) {
          if(res.data.quedata.length>0){
            setquedata(JSON.parse(res.data.quedata[0]["questions_data"]));            
            setquedata1(JSON.parse(res.data.quedata[0]["questions_data"]));            
          }
          setjobdata(res.data.data[0]);          
          setassdata(res.data.assdata);          
        }
    }

    let [loading, setLoading] = useState(false); 

    // const setIsAssessment = async (e) => {

    // }

    const savedata = async (e) => {
      e.preventDefault();
      // console.log(quedata); return
        if(quedata.length>0){
          // for (let index = 0; index < quedata.length; index++) {
          //   let ans = "answer_0";
          //   if(quedata[index]?.[ans] === "" || quedata[index]?.[ans] === undefined){
            if(quedata[0].answer_0 === undefined){
              swal({
                title: "Error",
                text: "Please Enter value all Required value",
                icon: "error",
                timer: 2000,
              });
              return;
            }
        //   }
          //   }
          }
         
        // }
        // console.log(quedata); return
        // return
        swal({
          title: "Submit Application",
          text: `Are you sure you want to Submit Application? You can't change after submit application`,
          buttons: ["No", "Yes"],
          showCloseButton: true,
        }).then((willDelete) => {
          if (willDelete) {
            finalsubmitform()
          }
        });       
        
      };



      // console.log(quedata);
    
    const finalsubmitform = async() => {
      const formdata = new FormData();
      formdata.append("interviewername", "Own");        
      formdata.append("overallassessment", "");        
      formdata.append("formdata", JSON.stringify(quedata));  
      let path = window.location.pathname.replace('/job-application-general/','')      
      formdata.append("uuid", path);        
   
      const res = await axios.post(BASEURL + "job-assessment-add", formdata, {
          headers: {
            "Content-Type": "application/json",
          },
      });

      if (res.data.status === 201) {
        swal({
          title: "Job Application added successfully! We will contact you soon.",
          text: res.message,
          icon: "success",
          timer: 2000,
        });
        // setIsAssessment(false)
        navigate("/job-application");
        navigate(0);
        // getData();
      } else {
        swal({
          title: "Error occured! Please try again",
          text: res.message,
          icon: "error",
          timer: 2000,
        });
      }
      setLoading(false);
    }


    const getassdata = (item) => {
      setinterviewername(item.interviewername)
      setoverallassessment(item.overallassessment)
      setquedata(JSON.parse(item.answer_data));
      setIsAssessment(true)
      setIsAssessment1(true)
    }
  return ( <>
    <div className="p-0">
    <div className="container " style={{
          height: "100vh",
          overflow: "scroll"
    }}>
      <div className="block xl:grid grid-cols-2 ">
        <div className="hidden xl:flex flex-col min-h-screen">
          <div className="-intro-x flex items-center pt-5">
            <img
              alt="Midone - HTML Admin Template"
              className="w-6"
              src="../images/logo.svg"
            />
            <span className="text-white text-lg ml-3">Jenya Tracking</span>
          </div>
          <div className="my-auto">
            <img alt='' className="-intro-x w-1/2 " src="../images/illustration.svg"/>
            <div className="-intro-x text-white font-medium text-3xl leading-tight mt-10 text-left">
              A few more clicks to
              <br />
              submit your job application form
            </div>         
          </div>
        </div>

        <div className="h-screen xl:h-auto py-lg-5 xl:py-0 my-lg-10 xl:my-0" style={{overflow:"scroll"}}>
          <div className="">
            <div className="grid grid-cols-24 gap-12 mt-5">
                    <div className="intro-y col-span-12 lg:col-span-6">
                        
                        <div className="intro-y box">
                            <div className="flex flex-col sm:flex-row items-center p-2 border-b border-slate-200/60 dark:border-darkmode-400">
                                <h2 className="text-base mr-auto" style={{fontSize:"15px",fontWeight:500}}>
                                Job Application - Skills Evaluation Criteria   
                                </h2>                               
                            </div>
                            <div id="form-validation" className="p-5">
                          
                                  <div className="preview">                                 
                                    <div className="flex-1 mt-6 xl:mt-0 mb-5 pb-5">
                                    <form className='pb-5 mb-5' onSubmit={(e) => savedata(e)}>
                                      <div className="grid grid-cols-12 gap-x-5 text-left">
                                       
                                        <div className="col-span-12 2xl:col-span-12">
                                        
                                        {quedata && quedata.length>0 && quedata.map((item,i)=>{
                                        return(<>
                                            <label htmlFor="">{item.question}</label>
                                          
                                            <div className='p-0 py-5' style={{display:"flex"}}>
                                            {item.question_type=="0" || item.question_type==""?
                                            
                                                item.options.map((listitem,ind2)=>{
                                                  {/* console.log(item["answer_"+i]) */}
                                               return(
                                                <>
                                                  <div className="form-check form-check-inline mr-4">
                                                    <input checked={item["answer_"+i]==ind2} className="form-check-input" type="radio" name={"answer_"+i} id="inlineRadio1" value={ind2} onChange={(e)=>handlechange(e,i)}/>
                                                    <label className="form-check-label" for="inlineRadio1">{listitem}</label>
                                                  </div>                                        
                                                </> )
                                                })

                                            : item.question_type=="1"?
                                              item.options.map((listitem,ind)=>{

                                                let iscontain=false;
                                                if(item["answer_"+i] && item["answer_"+i].length>0){
                                                  iscontain = (item["answer_"+i].includes(String(ind)))
                                                }                                                
                                                return(
                                                <>
                                                  <div className="form-check form-check-inline mr-4">
                                                    <input className="form-check-input" type="checkbox" name={"answer_"+i} id="inlineRadio1" value={ind} checked={iscontain} onChange={(e)=>handlechange(e,i)}/>
                                                    <label className="form-check-label" for="inlineRadio1">{listitem}</label>
                                                  </div>                                        
                                                </> )
                                                })
                                            : item.question_type=="2" ?
                                            <>
                                              <select className="form-select" aria-label="Default select example" name={"answer_"+i} onChange={(e)=>handlechange(e,i)} >
                                              {item.options.map((listitem,ind3)=>{
                                                return(
                                                <>
                                                <option value={ind3} selected={item["answer_"+i]==ind3}>{listitem}</option>
                                                </> )
                                                }) }
                                              </select>
                                            </> 
                                            :
                                            <textarea
                                              name={"answer_"+i}
                                              className="form-control"
                                              cols="80"
                                              rows="2"
                                              onChange={(e)=>handlechange(e,i)}
                                              value={item["answer_"+i]}
                                          ></textarea>
                                          }</div>
                                          </>);
                                        }) }    


                                        
                                          </div>    

                                      
                                        {/* <div className="col-span-12 2xl:col-span-12">
                                          <label htmlFor="update-profile-form-2" className="form-label">
                                          Overall Assessment
                                          </label>
                                          <br />
                                          <textarea
                                              name="overallassessment"
                                              className="form-control"
                                              cols="80"
                                              rows="5"
                                              onChange={(e)=>setoverallassessment(e.target.value)}
                                              value={overallassessment}
                                          ></textarea>
                                          </div>
                                       */}
                                      
                                      </div>
                                      
                                      {loading ? (
                                  <HashLoader
                                    color="#5755d9"
                                    size={30}
                                    style={{ position: "absolute", left: "10%",paddingTop: "10%" }}
                                  />
                                ) : 

                                      <div className="text-left">
                                      <button
                                        type="submit"
                                        className="btn btn-primary w-50 mt-3 mb-5"
                                      >
                                        Submit Application
                                      </button> 
                                      </div>        
                              }       
                                    </form>
                                  </div>
                                
                                 </div>
                                
                            </div>
                        
                    </div>
                </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>



</>

  )
}

export default JobAssessment