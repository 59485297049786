import React, { useEffect, useState } from "react";
import { BASEURL } from "./BASEURL";
import { FaCalendar, FaEdit } from "react-icons/fa";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { format } from "date-fns";
import "react-month-picker/css/month-picker.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";
import Modal from "react-modal";
import { FaTrashAlt } from "react-icons/fa";
import swal from "sweetalert";

Modal.setAppElement("#root");

const Leaves = () => {
	const [showForm, setShowForm] = useState(false);
	const [currdate] = useState(new Date());
	const [datevalue, onChange] = useState([
		new Date(currdate.getFullYear(), currdate.getMonth(), 1),
		new Date(currdate.getFullYear(), currdate.getMonth() + 1, 0),
	]);

	const [showHalfDayOptions, setShowHalfDayOptions] = useState(false);
	const [leaveRequests, setLeaveRequests] = useState([]);



	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize] = useState(10); // Number of items per page
	const [totalPages, setTotalPages] = useState(1); // Total number of pages
	const [dateChange, setDateRange] = useState(null)

	useEffect(() => {
		// Calculate total pages

		setTotalPages(Math.ceil(leaveRequests.length / pageSize));
	}, [leaveRequests, pageSize]);

	useEffect(() => {
		// Fetch initial leave requests
		fetchLeaveRequests();
	}, []);

	// const fetchLeaveRequests = async () => {
	// 	const myHeaders = new Headers();
	// 	let userdata = JSON.parse(localStorage.getItem("isAuth"));
	// 	if (userdata != null) {
	// 		let token = userdata.usertoken;
	// 		myHeaders.append("Authorization", token);
	// 	}
	// 	try {
	// 		// Fetch leave requests from API
	// 		const response = await fetch(BASEURL + `leave_request_new/ `, {
	// 			method: "GET",
	// 			headers: myHeaders,
	// 		});
	// 		if (!response.ok) {
	// 			throw new Error("Failed to fetch leave requests");
	// 		}
	// 		const data = await response.json();
	// 		setLeaveRequests(data);
	// 	} catch (error) {
	// 		console.error("Error fetching leave requests:", error);
	// 	}
	// };



	const fetchLeaveRequests = async () => {
		const myHeaders = new Headers();
		let userdata = JSON.parse(localStorage.getItem("isAuth"));
		if (userdata != null) {
			let token = userdata.usertoken;
			myHeaders.append("Authorization", token);
		}
		try {
			// Fetch leave requests from API
			const response = await fetch(BASEURL + `leave_request_new/ `, {
				method: "GET",
				headers: myHeaders,
			});
			if (!response.ok) {
				throw new Error("Failed to fetch leave requests");
			}
			const data = await response.json();
			
			// Filter leave requests for the logged-in user
			const filteredLeaveRequests = data.filter(request => request.user_id === userdata.id);
	
			setLeaveRequests(filteredLeaveRequests);
		} catch (error) {
			console.error("Error fetching leave requests:", error);
		}
	};
	
	// ####################################### delete api ########################################

	const handleDelete = async (requestId) => {
		console.log("Deleting request with ID:", requestId);
		// Show SweetAlert confirmation dialog
		swal({
			title: "Are you sure?",
			text: "Are you sure you want to delete this request!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then(async (willDelete) => {
			if (willDelete) {
				try {
					const response = await fetch(
						BASEURL + `leave_request_new/${requestId}`,
						{
							method: "DELETE",
						}
					);
					if (!response.ok) {
						throw new Error("Failed to delete leave request");
					}
					// Remove the deleted leave request from the state
					setLeaveRequests((prevLeaveRequests) =>
						prevLeaveRequests.filter(
							(request) => request.request_id !== requestId
						)
					);

					// Show success message with SweetAlert
					swal(
						"Success!",
						"Leave request deleted successfully!",
						"success"
					);
				} catch (error) {
					console.error("Error deleting leave request:", error);
					// Show error message with SweetAlert
					swal("Error!", "Failed to delete leave request", "error");
				}
			} else {
				// User clicked cancel, do nothing
			}
		});
	};

	const [startDate, setStartDate] = useState(
		new Date(currdate.getFullYear(), currdate.getMonth(), 1)
	);
	const [endDate, setEndDate] = useState(
		new Date(currdate.getFullYear(), currdate.getMonth() + 1, 0)
	);

	const handledate = (e) => {
		onChange(e);
		setStartDate(e[0]); // Set the start date
		setEndDate(e[1]); // Set the end date
	};

	const handleApplyLeave = () => {
		setShowForm(true);
		setEditMode(false); // Ensure edit mode is false when applying for leave
	};

	const handleBack = () => {
		setShowForm(false);
	};

	const [reason, setReason] = useState("");
	const [selectedDay, setSelectedDay] = useState("");
	const [isFullDay, setIsFullDay] = useState(true); // State to track full day option
	const [isHalfDay, setIsHalfDay] = useState(false); // State to track half day option
	const [isFirstHalfSelected, setIsFirstHalfSelected] = useState(false);
	const [isSecondHalfSelected, setIsSecondHalfSelected] = useState(false);
	const [selectedTable, setSelectedTable] = useState();

	const handleChange = (event) => {
		setSelectedDay(event.target.value);
	};

	const handleFullDayChange = () => {
		setShowHalfDayOptions(false);
		setIsFullDay(true);
		setIsHalfDay(false); // Deselect half day option
	};

	const handleHalfDayChange = (e) => {
		const isChecked = e.target.checked;
		setShowHalfDayOptions(isChecked);
		setIsHalfDay(isChecked); // Update isHalfDay state
		setIsFullDay(false); // Deselect full day option
		setIsFirstHalfSelected(false); // Deselect first half
		setIsSecondHalfSelected(false); // Deselect second half
	};

	const handleFirstHalfChange = () => {
		setIsFirstHalfSelected(true);
		setIsSecondHalfSelected(false); // Deselect second half
	};

	const handleSecondHalfChange = () => {
		setIsSecondHalfSelected(true);
		setIsFirstHalfSelected(false); // Deselect first half
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const userData = JSON.parse(localStorage.getItem("isAuth"));
		if (!userData) {
			console.error("User data not found");
			return;
		}
	  // Find the company UUID from the companydata array
		const company_uuid = userData.companydata[0].uuid;

		// Calculate leave day count based on the selected date range and leave type
		var leaveDayCount = 0;
		if (isHalfDay) {
			// If half day, count as 0.5 day
			leaveDayCount = 0.5;
		} else {
			// If full day, count the number of days in the date range
			const diffInDays = Math.ceil(
				(endDate - startDate) / (1000 * 60 * 60 * 24)
			);
			leaveDayCount = diffInDays + 1; // Add 1 to include both start and end dates
		}
			
		const formData = {
			user_id: userData.id,
			uuid: userData.uuid,
			department: userData.department,
			reason: reason,
			startDate: startDate,
			endDate: endDate,
			day: isHalfDay ? "Half Day" : "Full Day",
			halfDayType: isHalfDay
				? isFirstHalfSelected
					? "first-half"
					: "second-half"
				: "",
			noofdays: leaveDayCount,
			request_date: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
			leaveday: selectedDay,
			company_uuid: company_uuid, // Add company_uuid to formData
		};
		console.log("login user data: ", userData)
		
		console.log("user inserted data by user : ", userData)
		const myHeaders = new Headers();
		// Append authorization token to headers
		if (userData) {
			myHeaders.append("Authorization", userData.usertoken);
		}
		myHeaders.append("Content-Type", "application/json");

		try {
			const response = await fetch(BASEURL + "leave-requests/", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(formData),
			});

			if (!response.ok) {
				throw new Error("Failed to submit form");
			}

			  
        // Update the leaveRequests state with the new leave request data
        setLeaveRequests(prevLeaveRequests => [...prevLeaveRequests, formData]);

			const data = await response.json();
			console.log(data);
		} catch (error) {
			console.error("Error submitting form:", error);
		}
		setShowForm(false);
	};

	const handleDateChange = () => {
		setDateRange({
			start: startDate.format("YYYY-MM-DD"),
			end: endDate.format("YYYY-MM-DD"),
		});
	};

	const handleReasonChange = (e) => {
		setReason(e.target.value);
	};

	const [editMode, setEditMode] = useState(false); // State to track whether the form is in edit mode

	// Function to handle when the user clicks the Edit button in the table
	const handleEdit = (requestId) => {
		// Find the leave request by requestId

		console.log(
			" this is my data which we are fetching by requestId ",
			requestId
		);
		const leaveRequest = leaveRequests.find(
			(request) => request.request_id === requestId
		);

		// Populate the form fields with the leave request data
		console.log("my id data from database :", leaveRequest);

		setReason(leaveRequest.reason);
		setStartDate(new Date(leaveRequest.start_date));
		setEndDate(new Date(leaveRequest.start_date));
		setSelectedDay(leaveRequest.selectedDay);
		setShowForm(true); // Show the form
		setEditMode(true); // Set edit mode to true
	};

	// const handleUpdate = async (requestId) => {
	// 	// console.log(requestId);return
	// 	// Prepare the updated data
	// 	const updatedData = {
	// 		reason: reason,
	// 		startDate: startDate,
	// 		endDate: endDate,
	// 		day: isHalfDay ? "Half Day" : "Full Day",
	// 		halfDayType: isHalfDay
	// 			? isFirstHalfSelected
	// 				? "first-half"
	// 				: "second-half"
	// 			: "",
	// 		noofdays: 0,
	// 		leaveday: selectedDay,
	// 	};

	// 	console.log("this is my new updated form data", updatedData);
	// 	try {
	// 		const response = await fetch(
	// 			BASEURL + `leave_requests_update/${requestId}`,
	// 			{
	// 				method: "PUT",
	// 				headers: {
	// 					"Content-Type": "application/json",
	// 				},
	// 				body: JSON.stringify(updatedData),
	// 			}
	// 		);
	// 		if (!requestId) {
	// 			console.error("Error: Request ID is undefined");
	// 			return;
	// 		}
	// 		if (!response.ok) {
	// 			throw new Error("Failed to update leave request");
	// 		}

	// 		// Optionally, you can handle success response here
	// 		// For example, display a success message or update the UI
	// 	} catch (error) {
	// 		console.error("Error updating leave request:", error);
	// 	}
	// 	setShowForm(false);
	// };
	
	const handleUpdate = async (requestId) => {
		const updatedData = {
			reason: reason,
			startDate: startDate,
			endDate: endDate,
			day: isHalfDay ? "Half Day" : "Full Day",
			halfDayType: isHalfDay ? (isFirstHalfSelected ? "first-half" : "second-half") : "",
			noofdays: 0,
			leaveday: selectedDay,
		};
	
		try {
			const response = await fetch(BASEURL + `leave_requests_update/${requestId}`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(updatedData),
			});
	
			if (!requestId) {
				console.error("Error: Request ID is undefined");
				return;
			}
	
			if (!response.ok) {
				throw new Error("Failed to update leave request");
			}
	
			// Fetch the updated data again from the server
			await fetchLeaveRequests();
	
			// Optionally, you can handle success response here
			// For example, display a success message or update the UI
		} catch (error) {
			console.error("Error updating leave request:", error);
		}
	
		setShowForm(false);
	};
	
	return (
		<>
			{showForm ? (
				<div className="container mt-5 p-4 pb-2 rounded	">
					<h1
						className="pt-4 flex ml-3 fw-bold text-uppercase"
						style={{ fontWeight: "900" }}
					>
						{" "}
						Leave Request
					</h1>
					<form method="post">
						<div className="container">
							<div className="form-group flex">
								<div className="form-check p-3">
									<input
										className="form-check-input"
										type="checkbox"
										id="fullDayCheckbox"
										value="full-day"
										onChange={handleFullDayChange}
										checked={isFullDay}
									/>
									<label
										className="form-check-label"
										htmlFor="fullDayCheckbox leaveDays"
									>
										Full Day
									</label>
								</div>
								<div className="form-check p-3">
									<input
										className="form-check-input"
										type="checkbox"
										id="halfDayCheckbox"
										onChange={handleHalfDayChange}
										checked={isHalfDay}
									/>
									<label
										className="form-check-label"
										htmlFor="halfDayCheckbox leaveDays "
									>
										Half Day
									</label>
								</div>
							</div>
							{showHalfDayOptions && (
								<div className="form-group flex">
									<div className="form-check p-3">
										<input
											className="form-check-input"
											type="checkbox"
											id="firstHalfCheckbox"
											value="first-half"
											onChange={handleFirstHalfChange}
											checked={isFirstHalfSelected}
										/>
										<label
											className="form-check-label"
											htmlFor="firstHalfCheckbox"
										>
											First Half
										</label>
									</div>
									<div className="form-check p-3">
										<input
											className="form-check-input"
											type="checkbox"
											id="secondHalfCheckbox"
											value="second-half"
											onChange={handleSecondHalfChange}
											checked={isSecondHalfSelected}
										/>
										<label
											className="form-check-label"
											htmlFor="secondHalfCheckbox"
										>
											Second Half
										</label>
									</div>
								</div>
							)}
						</div>
						{!isHalfDay && (
							<div className="form-group p-3">
								<label htmlFor="leaveDays" className="flex">
									Leave Days:
								</label>
								<select
									className="form-control"
									id="leaveDays"
									value={selectedDay}
									onChange={handleChange}
								>
									<option value="Select Leave Day">
										Select Leave Day
									</option>
									<option value="Single-Day">
										Single Day
									</option>
									<option value="Multiple-Day">
										Multiple Days
									</option>
								</select>
							</div>
						)}
						<div className="form-group p-3">
							<label htmlFor="leaveDateRange" className="flex">
								Leave Date Range:
							</label>
							<div className="input-group">
								<div>
									<DateRangePicker
										clearIcon=""
										calendarIcon={
											<FaCalendar
												style={{
													color: "rgb(30 64 175)",
												}}
											/>
										}
										className="form-control"
										onChange={handledate}
										value={datevalue}
										onApply={handleDateChange}
									/>
								</div>
							</div>
						</div>
						<div className="form-group p-3">
							<label htmlFor="reason" className="flex">
								Reason for Leave:
							</label>
							<textarea
								className="form-control"
								id="reason"
								rows="3"
								value={reason}
								onChange={handleReasonChange}
								placeholder="Enter reason for leave"
							></textarea>
						</div>
						<div className=" flex p-2">
							{editMode ? (
								// If in edit mode, show the Update button
								<button
									type="button"
									name="updateLeave"
									className="btn btn-primary waves-effect waves-light ml-2"
									onClick={() =>
										handleUpdate(selectedTable.request_id)
									}
								>
									Update
								</button>
							) : (
								// If not in edit mode, show the Submit button
								<button
									onClick={handleSubmit}
									type="button"
									name="addleave"
									className="btn btn-primary waves-effect waves-light ml-2"
								>
									Submit
								</button>
							)}

							<button
								type="submit"
								name="addleave"
								class="btn btn-primary waves-effect waves-light ml-2"
								onClick={handleBack}
							>
								Back
							</button>
						</div>
					</form>
				</div>
			) : (
				<div className="intro-y box p-5 mt-5">
					<div className="flex flex-col  sm:flex-row sm:items-end xl:items-start">
						<div className="flex sm:items-end mt-5 sm:mt-0">
							<button
								className="btn btn-primary "
								onClick={handleApplyLeave}
							>
								Apply for Leave
							</button>
						</div>
					</div>
					<div className="container">
						<h1
							className="pt-4 ml-3 fw-bold text-uppercase"
							style={{ fontWeight: "900" }}
						>
							Leave Request
						</h1>
						<div className="table-responsive">
							<table className="table table-striped">
								<thead className="bg-primary text-white ">
									<tr>
										<th
											style={{
												borderRight: "1px solid white",
											}}
										>
											ID
										</th>
										<th
											style={{
												borderRight: "1px solid white",
											}}
										>
											user id
										</th>
										<th
											style={{
												borderRight: "1px solid white",
											}}
										>
											No of Days
										</th>
										<th
											style={{
												borderRight: "1px solid white",
											}}
										>
											Reason
										</th>
										<th
											style={{
												borderRight: "1px solid white",
											}}
										>
											Leave Type
										</th>
										<th
											style={{
												borderRight: "1px solid white",
											}}
										>
											Leave Date
										</th>
										<th
											style={{
												borderRight: "1px solid white",
											}}
										>
											Start Date
										</th>
										<th
											style={{
												borderRight: "1px solid white",
											}}
										>
											End Date
										</th>
										<th
											style={{
												borderRight: "1px solid white",
											}}
										>
											Action
										</th>
									</tr>
								</thead>
								<tbody>
									{leaveRequests
										.slice(
											(currentPage - 1) * pageSize,
											currentPage * pageSize
										)
										.map((table) => (
											<tr key={table.user_id}>
												<td>{table.request_id}</td>
												<td>{table.user_id}</td>
												<td>{table.noofdays}</td>
												<td>{table.reason}</td>
												<td>{table.leave_type}</td>
												<td>{table.request_date}</td>
												<td>{table.start_date}</td>
												<td>{table.end_date}</td>
												<td>
													<tr className="flex">
														<FaEdit
															onClick={() => {
																handleEdit(
																	table.request_id
																);
																setSelectedTable(
																	table
																); // Update selected table data
															}}
														/>

														<FaTrashAlt
															onClick={() =>
																handleDelete(
																	table.request_id
																)
															}
															className="text-danger ml-2"
														/>
													</tr>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>

					<div className="container mt-5  flex ">
						
						<button
							onClick={() =>
								setCurrentPage((prevPage) =>
									Math.max(prevPage - 1, 1)
								)
							}
							disabled={currentPage === 1}
							className="btn btn-primary btn ml-2 btn-xs btn-light"
						>
							Previous
						</button>
						<button
							onClick={() =>
								setCurrentPage((prevPage) =>
									Math.min(prevPage + 1, totalPages)
								)
							}
							className="btn btn-primary btn btn-xs btn-light ml-3"
							disabled={currentPage === totalPages}
						>
							Next
						</button>
						
					</div>
				</div>
			)}
		</>
	);
};
export default Leaves;
