import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { number } from "yup";
import { getObjectCount } from "../../../utils/Utils";
import { connectUA, dial, hangup, toggelHoldCall } from "./sipjsService";
import dateFormat, { masks } from "dateformat";
const sessionState = localStorage.getItem("sessionState")
  ? localStorage.getItem("sessionState")
  : null;


export const ConnectedStatus = Object.freeze({
  failed: "failed",
  connected: "connected",
  connecting: "connecting"
});
const connected = localStorage.getItem('connected') || false;
const now = new Date();

const initialState = {
  connected: connected,
  regState: "Unregistered",
  userAgent: {},
  registerer: {},
  incomingSession: {},
  outgoingSession: {},
  sessionId: "",
  sessions: {},
  contactData: {},
  holdUnholdStates: {},
  showCallingScreeen: false,
  showVideoCallngScreen: false,
  sessionState: sessionState,
  dialNumber: null,
  holdCall: false,
  unHoldCall: false,
  showCallTransferStrip: false,
  blindTransferCallBtn: false,
  attendantCalltransferBtn: false,
  invitationSession: {},
  callDirection: null,
  incomingUser: null,
  callCancel: 0,
  connectedTime: null,
  connectingStatus: ConnectedStatus.failed //  connecting connected
};

export const sipjsSlice = createSlice({
  name: "sipjs",
  initialState,
  reducers: {
    setregState: (state, action) => {
      state.regState = action.payload
    },
    callEnd: (state) => {
      state.showCallingScreeen = false;
    },
    hideCallingScreeenFun: (state) => {
      state.showCallingScreeen = false;
    },
    showCallingScreeenFun: (state) => {
      state.showCallingScreeen = true;
    },
    showTransferStrip: (state) => {
      state.showCallTransferStrip = true;
    },
    hideTransferStrip: (state) => {
      state.showCallTransferStrip = false;
    },
    removeSessionData: (state, action) => {
      console.debug('remove session data ==> ', action)
      let sessions = state.sessions;
      sessions = Object.keys(sessions)
        .filter((fkey) => fkey !== action.payload)
        .reduce((obj, key) => {
          obj[key] = sessions[key];
          return obj;
        }, {});
      state.sessions = sessions;
      if (getObjectCount(sessions)) {
        let activeSessionId = sessions[Object.keys(sessions)[0]].id;
        // console.log('active Sesssion id ', activeSessionId)
        // console.debug('remove session', sessions);
        state.sessionId = activeSessionId
      } else {
        state.sessionId = "";
      }
      // let activeSessionId = state.sessions[Object.keys()[0]].id;
      // state.sessionId = activeSessionId
    },
    tooggleBlindCallTransfer: (state, action) => {
      state.blindTransferCallBtn = action.payload;
    },
    toogleAttentendCallTrnasfer: (state, action) => {
      state.attendantCalltransferBtn = action.payload;
    },
    setUserAgent: (state, action) => {
      state.userAgent = action.payload;
    },
    setRegisterer: (state, action) => {
      state.registerer = action.payload;
    },
    setCallCancel: (state, action) => {
      state.callCancel = action.payload;
    },
    setCallIncomingOutGoingSession: (state, action) => {
      // console.debug('setCallIncomingOutGoingSession', action)
      const { callDirection, session,incomingUser, sessionId } = action.payload;
      if (callDirection == "incoming") {
        state.incomingSession = session;
      } else {
        state.outgoingSession = session;
      }
      state.callCancel = 0;
      state.incomingUser = incomingUser;
      state.callDirection = callDirection;
      state.sessionId = sessionId;
      state.sessions[sessionId] = session;
      state.holdUnholdStates[sessionId] = "unhold";
    },
    getAllSessionData: (state) => {
      return state.sessions
    },
    setConncetInfo: (state, action) => {
      state.connected = action.payload.connected;
      state.connectingStatus = action.payload.connectingStatus;
      state.connectedTime = action.payload.connectedTime;
    },
    toggleVideoCallingScreen: (state, action) => {
      state.showVideoCallngScreen = action.payload;
    },
    setSessionState: (state, action) => {

      state.sessionState = action.payload
    },
    testFunc: (state, action) => {
      // alert('tets')
      console.debug('tyest ==>', action.payload)
      console.debug('setCallIncomingOutGoingSession', action)
      const { callDirection, session, sessionId } = action.payload;
      state.callDirection = callDirection;
      state.sessionId = sessionId;
      state.sessions[sessionId] = session;
      state.holdUnholdStates[sessionId] = "unhold";
      console.debug(state.sessions)
    }
  },
  extraReducers: (builder) => {
    builder
      // .addCase(createUa.pending, (state) => {
      //   console.debug("action panding");
      // })
      // .addCase(createUa.fulfilled, (state, actions) => {
      //   const { userAgent, registerer, connected, invitationSession } =
      //     actions.payload;
      //   state.userAgent = userAgent;
      //   state.registerer = registerer;
      //   state.connected = connected;
      //   state.invitationSession = invitationSession;
      // })
      // .addCase(createUa.rejected, (state, action) => {
      //   console.debug("rejected ", action);
      // })
      .addCase(dial.pending, (state) => { })
      .addCase(dial.fulfilled, (state, action) => {
        const { sessionId, sessions, sessionState, callDirection, number } =
          action.payload;
        console.warn(action.payload);
        state.sessions[sessionId] = sessions;
        state.sessionId = sessionId;
        state.sessionState = sessionState;
        state.callDirection = callDirection;
        state.outgoingSession = sessions;
        state.holdUnholdStates[sessionId] = "unhold";
        state.dialNumber = number;
      })
      .addCase(dial.rejected, (state, action) => {
        console.debug("sesssion reajected");
      })
      .addCase(toggelHoldCall.pending, (state) => {
        console.debug("hold call state panding");
      })
      .addCase(toggelHoldCall.fulfilled, (state, action) => {
        console.debug("hold call fulfield", action);
        const { holdCall, unHoldCall } = action.payload;
        state.holdUnholdStates = action.payload.holdUnholdStates;
        state.holdCall = holdCall;
        state.unHoldCall = unHoldCall;
      })
      .addCase(toggelHoldCall.rejected, (state, action) => {
        console.debug("hold call rejected", action);
      })
      .addCase(hangup.fulfilled, (state, action) => {
        console.debug("call hangup session fulfilled", action);
        const { sessionId, sessions } = action.payload;
        console.debug("sessionid ", sessionId);
        state.sessionId = sessionId;
        state.sessions = sessions;
      })
      .addCase(hangup.rejected, (state, action) => {
        console.debug("call hangup session error", action);
      })
      .addCase(connectUA.pending, (state) => {
        state.connectindStatus = 'connecting'
        state.connected = false;
        state.connectedTime = dateFormat(now, "dddd, mmmm dS, yyyy, h:MM:ss TT");
      }).addCase(connectUA.fulfilled, (state, action) => {
        state.connected = true;
        state.connectindStatus = 'connected'
        state.connectedTime = dateFormat(now, "dddd, mmmm dS, yyyy, h:MM:ss TT");
      }).addCase(connectUA.rejected, (state, action) => {
        state.connected = false;
        state.connectindStatus = 'failed'
        state.connectedTime = dateFormat(now, "dddd, mmmm dS, yyyy, h:MM:ss TT");
      })
  },
});

// Action creators are generated for each case reducer function

export default sipjsSlice.reducer;
export const {
  setregState,
  callEnd,
  hideCallingScreeenFun,
  showCallingScreeenFun,
  showTransferStrip,
  hideTransferStrip,
  removeSessionData,
  toogleAttentendCallTrnasfer,
  tooggleBlindCallTransfer,
  setUserAgent,
  setRegisterer,
  setCallCancel,
  setCallIncomingOutGoingSession,
  getAllSessionData,
  setActiveSessionId,
  setConncetInfo,
  toggleVideoCallingScreen,
  setSessionState,
  testFunc
} = sipjsSlice.actions;
