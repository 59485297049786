import React, { useEffect, useState } from "react";
import { BASEURL } from "../BASEURL";
import { format } from "date-fns";
import DatePicker from "react-date-picker";
import { FaCalendar, FaTimes } from "react-icons/fa";
import {
  AiOutlineZoomIn,
  AiOutlineZoomOut,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { useLoadingContext } from "react-router-loading";
import Modal from "react-modal";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import HashLoader from "react-spinners/HashLoader";
import "react-circular-progressbar/dist/styles.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { GrNext, GrPrevious } from "react-icons/gr";
import reload from '../../reload.png'
import Select from "react-select";

const customStyles = {
  content: {
    padding: "0px",
    border: "1px solid rgb(187, 181, 181)",
    borderRadius: "14px",
  },
};

const Screenshots = () => {
  const loadingContext = useLoadingContext();

  let [loading, setLoading] = useState(false);
  const [bkpdata, setbkpdata] = useState([]);
  const [userdata, setuserdata] = useState([]);
  const [ssdata, setssdata] = useState([]);
  const [popupdata, setpopupdata] = useState([]);
  const [icons, seticons] = useState([]);
  const [date, setdate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [username, setusername] = useState();
  const [currdate, setcurrdate] = useState(new Date());
  const [isopen, setIsOpen] = useState(null);
  const [heading, setheading] = useState(" ");
  const [disable, setdisable] = useState(false);
  const [disablenext, setdisablenext] = useState(false);
  const [sort, setsort] = useState("Newest");
  const [orgs, setorgs] = React.useState([]);
  const [master, ] = React.useState(JSON.parse(localStorage.getItem("isAuth")).master);
  const [selectedorg, setselectedorg] = React.useState(JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid);
  const [orgval, setorgval] = React.useState([]);

  useEffect(() => {
    let date = format(new Date(), "yyyy-MM-dd");
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if(master===1){
      getOrganizations();
    }
    setusername(userdata.username);
    getUserData();
    getScreenshotData(userdata.username, date);

    return () => {
      setusername(userdata.username);
    };
  }, []);

  
  const getorg = async (org) => {
    setorgval(org);
    org = org.value

    setselectedorg(org);
      getUserData(org);
  }

  const reloadFun = ()=> {
    getScreenshotData(username, date);
  }
  const getOrganizations = async () => {
    setLoading(true);

    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata !== null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-organizations/`, {
      method: "POST",
      headers: myHeaders    
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setorgs(res.data);
        } else {
          setorgs([]);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };


  const getUserData = async (org="") => {
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `admin-getAllUsers/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        type:1,
        // uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
        uuid: org!=='' ? org : selectedorg,
        usertype:JSON.parse(localStorage.getItem("isAuth")).type,
        department:JSON.parse(localStorage.getItem("isAuth")).department,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          let finalusers = [];
          res.data.map((item, i) => {
            let isactive = res.alluser.filter(
              (itm) => Number(itm.username) === Number(item.username)
            );
            let obj = item;
            finalusers.push(obj);
            if (isactive.length > 0) {
              finalusers[i].isactive = 1;
            } else {
              finalusers[i].isactive = 0;
            }
          });

          let filterusers = finalusers.sort(function (a, b) {
            return b.isactive - a.isactive;
          });

          setuserdata(filterusers);

          setbkpdata(filterusers);
        }
      })
      .catch((err) => console.log(err));

    setLoading(false);
  };

  const getScreenshotData = async (username, date, name) => {
    setLoading(true);
    setheading(name);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-screenshotData/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        date,
        username,
        company_uuid: JSON.parse(localStorage.getItem("isAuth")).companydata[0].uuid,
        master:JSON.parse(localStorage.getItem("isAuth")).master
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setusername(username);
          setssdata(res.data);
          seticons(res.icons);
        } else {
          setssdata([]);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
    loadingContext.done();
  };

  const handledate = (e) => {
    let date;
    if (e !== null) {
      setcurrdate(e);
      date = format(new Date(e), "yyyy-MM-dd");
    } else {
      date = format(new Date(), "yyyy-MM-dd");
      setcurrdate(new Date());
    }
    setdate(date);
    getScreenshotData(username, date);
  };

  const nexTDay = () => {
    let tomorrow = currdate;
    let date2 = new Date(date);
    tomorrow.setDate(date2.getDate() + 1);
    setcurrdate(tomorrow);
    setdate(format(new Date(tomorrow), "yyyy-MM-dd"));
    let date3 = format(new Date(tomorrow), "yyyy-MM-dd");
    getScreenshotData(username, date3);
  };

  const prevDay = () => {
    let tomorrow = currdate;
    let date2 = new Date(date);
    tomorrow.setDate(date2.getDate() - 1);
    setcurrdate(tomorrow);
    setdate(format(new Date(tomorrow), "yyyy-MM-dd"));
    let date3 = format(new Date(tomorrow), "yyyy-MM-dd");
    getScreenshotData(username, date3);
  };

  const handlepopup = async (data) => {
    // console.log(data);
    await setpopupdata(data);
    appdetails();
    setIsOpen(true);    
  };

  function formatTime(seconds) {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.round(seconds % 60);
    return [h, m > 9 ? m : h ? "0" + m : m || "0", s > 9 ? s : "0" + s]
      .filter(Boolean)
      .join(":");
  }

  const appdetails = () => {
    if (popupdata) {
      let windetails = JSON.parse(popupdata.active_window_details);

      let totaldec = windetails.reduce(function (sum, current) {
        return sum + current.time;
      }, 0);

      let arr = [];

      windetails.forEach((element) => {
        let icon = icons.filter((item, i) => {
          return item.app_name === element.app;
        });
        let per = Math.round((element.time * 100) / totaldec);
        arr.push(
          <div className="box mt-2 ml-2" style={{ width: "230px" }}>
            <div className="col-span-2 sm:col-span-2 xl:col-span-2 intro-y">
              <div className="intro-y box pl-3 p-2">
                <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                  <b>
                    {element.app} <br />
                    <small className="cursor-pointer" title={element.title}>
                      {element.title.slice(0, 20)}...
                    </small>
                  </b>
                </div>
                <div className="topright">
                  <img width={20} src={icon[0].icon} alt="" srcSet="" />
                </div>
                <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                  <p>{formatTime(element.time)} mm:ss</p>
                </div>
                <div className="bottomright">
                  <CircularProgressbar
                    value={per}
                    text={`${per}%`}
                    styles={buildStyles({
                      strokeLinecap: "butt",
                      textSize: 35,
                      textColor: "black",
                      pathColor: "green",
                    })}
                    strokeWidth={10}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      });

      return arr;
    } else {
      return "";
    }
  };

  const progressbardata = (item) => {
    if (
      item.clicks === 0 &&
      item.keyboardevent === 0 &&
      item.mouseevent === 0
    ) {
      return (
        <div className="progress h-2  mr-1 mb-1 mt-1 ">
          <div
            className="progress-bar"
            style={{
              background: "rgb(255 0 0)",
              fontSize: "5px",
              width: "100%",
            }}
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      );
    }

    if (
      item.clicks !== 0 &&
      item.keyboardevent !== 0 &&
      item.mouseevent !== 0
    ) {
      return (
        <div className="progress h-2   mr-1 mb-1 mt-1  ">
          <div
            className="progress-bar"
            style={{ background: "#00a700", fontSize: "5px", width: "100%" }}
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      );
    }
    if (
      item.clicks === 0 &&
      item.keyboardevent === 0 &&
      item.mouseevent !== 0
    ) {
      return (
        <div className="progress h-2    mr-1 mb-1 mt-1">
          <div
            className="progress-bar"
            style={{ background: "#00a700", fontSize: "5px", width: "40%" }}
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="40"
          ></div>
        </div>
      );
    }
    if (
      item.clicks === 0 &&
      item.keyboardevent !== 0 &&
      item.mouseevent === 0
    ) {
      return (
        <div className="progress h-2   mr-1 mb-1 mt-1">
          <div
            className="progress-bar"
            style={{ background: "#00a700", fontSize: "5px", width: "40%" }}
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="40"
          ></div>
        </div>
      );
    }
    if (
      item.clicks !== 0 &&
      item.keyboardevent === 0 &&
      item.mouseevent !== 0
    ) {
      return (
        <div className="progress h-2   mr-1 mb-1 mt-1">
          <div
            className="progress-bar"
            style={{ background: "#00a700", fontSize: "5px", width: "60%" }}
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="60"
          ></div>
        </div>
      );
    }
    if (
      item.clicks !== 0 &&
      item.keyboardevent !== 0 &&
      item.mouseevent === 0
    ) {
      return (
        <div className="progress h-2   mr-1 mb-1 mt-1">
          <div
            className="progress-bar"
            style={{ background: "#00a700", fontSize: "5px", width: "70%" }}
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="70"
          ></div>
        </div>
      );
    }
    if (
      item.clicks !== 0 &&
      item.keyboardevent === 0 &&
      item.mouseevent === 0
    ) {
      return (
        <div className="progress h-2   mr-1 mb-1 mt-1">
          <div
            className="progress-bar"
            style={{ background: "#00a700", fontSize: "5px", width: "70%" }}
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="70"
          ></div>
        </div>
      );
    }
    if (
      item.clicks === 0 &&
      item.keyboardevent !== 0 &&
      item.mouseevent !== 0
    ) {
      return (
        <div className="progress h-2   mr-1 mb-1 mt-1">
          <div
            className="progress-bar"
            style={{ background: "#00a700", fontSize: "5px", width: "70%" }}
            role="progressbar"
            aria-valuenow="0"
            aria-valuemin="0"
            aria-valuemax="70"
          ></div>
        </div>
      );
    }
  };
  const getsort = (e) => {
    setsort(e.target.value);
    screenshotdata();
  };

  const screenshotdata = () => {
    if (sort === "Newest") {
      let content = [];
      for (let i = 23; i >= 0; i--) {
        const arr = ssdata[i];
        if (arr) {
          let amapm = "";
          let hrs = i;
          if (i < 10) {
            hrs = "0" + i;
          }
          if (i < 12) {
            amapm = "AM";
          } else {
            amapm = "PM";
          }
          content.push(
            <div className=" scrollbar-hidden px-5 pt-5 flex-1">
              <div className="intro-y block sm:flex items-center h-5">
                <strong>
                  {format(new Date(date), "yyyy-MM-dd") +
                    " " +
                    hrs +
                    " " +
                    amapm} {arr[0]?.project && <><span className="text-primary">- Project:</span> {arr[0]?.project}  ||  <span className="text-primary">Activity:</span> {arr[0]?.activity} || <span className="text-primary">Memo:</span> {arr[0]?.memo} 
                    </> }
                </strong>
              </div>

              <div className="grid grid-cols-12 gap-6 mt-5 pb-3 ">
                {arr &&
                  arr.map((item, index) => {
                    let url = `https://d3efhqyfy32gfi.cloudfront.net/screenshot/${username}/${date}/thumb/${item.image_name}`;
                    return (
                      <>
                        <div
                          className=" loading col-span-12 sm:col-span-6 xl:col-span-3 2xl:col-span-2 intro-y cursor-pointer "
                          style={{ width: "100%" }}
                          onClick={() => handlepopup(item)}
                          key={index}
                        >
                          <img
                            src={url}
                            style={{
                              borderRadius: "6px 6px 0 0",
                              width: "100%",
                            }}
                            alt=""
                            className="img_2Vjah"
                          />
                          <div
                            id="progressbar-height"
                            style={{
                              background: "#1e40af",
                              color: "white",
                              borderRadius: "0 0 6px 6px",
                            }}
                          >
                            <div
                              className="preview"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                {item.time.substring(0, 5)} {amapm}
                                {}
                              </div>
                              <div style={{ width: "90%" }}>
                                {progressbardata(item)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          );
        }
      }

      return content;
    } else {
      let content = [];
      for (let i = 0; i <= 23; i++) {
        const arr = ssdata[i];
        if (arr) {
          let amapm = "";
          let hrs = i;
          if (i < 10) {
            hrs = "0" + i;
          }
          if (i < 12) {
            amapm = "AM";
          } else {
            amapm = "PM";
          }
          content.push(
            <div className=" scrollbar-hidden px-5 pt-5 flex-1">
              <div className="intro-y block sm:flex items-center h-5">
                <strong>
                  {format(new Date(date), "yyyy-MM-dd") +
                    " " +
                    hrs +
                    " " +
                    amapm}
                </strong>
              </div>

              <div className="grid grid-cols-12 gap-6 mt-5 pb-3 ">
                {arr &&
                  arr.map((item, index) => {
                    let url = `https://d3efhqyfy32gfi.cloudfront.net/screenshot/${username}/${date}/thumb/${item.image_name}`;
                    return (
                      <>
                        <div
                          className="col-span-12 sm:col-span-6 xl:col-span-3 2xl:col-span-2 intro-y cursor-pointer "
                          style={{ width: "100%" }}
                          onClick={() => handlepopup(item)}
                          key={index}
                        >
                          <img
                            src={url}
                            style={{
                              borderRadius: "6px 6px 0 0",
                              width: "100%",
                            }}
                            alt=""
                            className="img_2Vjah"
                          />
                          <div
                            id="progressbar-height"
                            style={{
                              background: "#1e40af",
                              color: "white",
                              borderRadius: "0 0 6px 6px",
                            }}
                          >
                            <div
                              className="preview"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                {item.time.substring(0, 5)} {amapm}
                                {}
                              </div>
                              <div style={{ width: "90%" }}>
                                {progressbardata(item)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          );
        }
      }

      return content;
    }
  };

  function closeModal() {
    setIsOpen(false);
  }

  const nextimg = async () => {
    // setLoading(true);
    try {
      let time = Number(popupdata.time.slice(0, 2));
      let currtimedata = ssdata[`${time}`];
      let index = await currtimedata.findIndex(
        (p) => p.id == `${popupdata.id}`
      );
      if (index > currtimedata.length - 2) {
        setdisablenext(true);
        setdisable(false);
        setLoading(false);
      } else {
        setdisablenext(false);
        setdisable(false);
        setpopupdata(currtimedata[index + 1]);
        appdetails();
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const previmg = async () => {
    setLoading(true);
    let time = Number(popupdata.time.slice(0, 2));
    let currtimedata = ssdata[`${time}`];
    let index = await currtimedata.findIndex((p) => p.id == `${popupdata.id}`);
    if (index <= 0) {
      setdisable(true);
      setLoading(false);
    } else {
      setdisablenext(false);
      setpopupdata(currtimedata[index - 1]);
      appdetails();
      setLoading(false);
    }
  };

  const filterdata = (event) => {
    event.preventDefault();
    if (event.target.value === "") {
      setuserdata(bkpdata);
    } else {
      var matches = bkpdata.filter(function (s) {
        return s.name.toLowerCase().includes(`${event.target.value}`);
      });
      setuserdata(matches);
    }
  };

  return (
    <>
      <div className="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 className="text-lg font-medium mr-auto">Screenshots</h2>

        <div className="h-auto">
          <div className="text-left">
          {master === 1 &&
    <Select
      value={orgval}
      className="basic-single mr-2"
      onChange={(e) => {
        getorg(e);
      }}
      options={orgs}
      // getOptionLabel={(option) =>
      //   ` ${option.active}   ${option.label}`
      // }
      styles={{
        option: (base) => ({
          ...base,
          // height: "100%",
          display: "flex",
          textAlign: "left",
        }),
      }}
    />
  }

            <button className="mx-2" onClick={prevDay}>
              <GrPrevious />
            </button>
            <DatePicker
              clearIcon=""
              calendarIcon={<FaCalendar style={{ color: "rgb(30 64 175)" }} />}
              onChange={handledate}
              value={currdate}
            />
            <button className="mx-2" onClick={nexTDay}>
              <GrNext />
            </button>
            
            <button className="ml-1 btn btn-default">
                  <img width={15} onClick={reloadFun} src={reload} alt="" srcset="" />
                 </button>

          </div>
        </div>
      </div>

      <div className="intro-y chat grid grid-cols-12 gap-2 mt-2">
        <div className="col-span-12 lg:col-span-3 2xl:col-span-2">
          <div className="tab-content">
            <div
              id="chats"
              className="tab-pane active"
              role="tabpanel"
              aria-labelledby="chats-tab"
            >
              <div className="box p-3">
                <div className="  lg:pb-0 mt-0">
                  <div className="relative text-slate-500 ">
                    <input
                      type="text"
                      className="form-control py-3 px-4 border-transparent bg-slate-100 "
                      placeholder="Search for users..."
                      onChange={(e) => filterdata(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="chat__chat-list overflow-y-auto scrollbar-hidden ">
                {userdata &&
                  userdata.map((item, i) => {
                    return (
                      <div
                        className={
                          heading === item.name
                            ? "intro-x cursor-pointer box relative flex items-center mt-2 p-2 activeuser"
                            : "intro-x cursor-pointer box relative flex items-center mt-2 p-2"
                        }
                        onClick={() =>
                          getScreenshotData(item.username, date, item.name)
                        }
                        key={i}
                      >
                        <div className="w-12 h-12 flex-none image-fit mr-1 usericon">
                          {
                            <>
                              <div className="rounded-full w-3 bg-blue">
                                {item.name.slice(0, 1)}
                              </div>
                            </>
                          }
                        </div>

                        <div className="ml-2 overflow-hidden">
                          <div className="flex items-center">
                            <div className="font-medium text-left d-flex">
                              {item.name}
                            </div>
                          </div>
                        </div>
                          <div className="ml-2 overflow-hidden">
                            <div className="flex items-center">
                              <div className="font-medium text-left d-flex">
                                {item.isactive === 1 ? 
                                <div
                                  style={{
                                    borderRadius: "50%",
                                    background: "#0ff10f",
                                    width: "10px",
                                    height: "10px",
                                  }}
                                ></div>
                                :
                                <div
                                  style={{
                                    borderRadius: "50%",
                                    background: "red",
                                    width: "10px",
                                    height: "10px",
                                  }}
                                ></div>
                                }
                              </div>
                            </div>
                          </div>
                        
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>

        <div className="intro-y col-span-12 lg:col-span-9 2xl:col-span-10">
          <div className=" box">
            {loading === true ? (
              <div className="mt-5 " style={{ display: "-webkit-inline-box" }}>
                <HashLoader color="#5755d9" size={30} />
              </div>
            ) : (
              <>
                <div
                  className="flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>
                    <h2 className="intro-y text-lg text-left">{heading}</h2>
                  </div>
                  <div className="my-4 mx-4">
                    <select onChange={getsort} className="form-control">
                      <option>Newest</option>
                      <option defaultValue>Oldest</option>
                    </select>
                  </div>
                </div>
                <hr className="dropdown-divider " />
                <div
                  className=" overflow-y-auto scrollbar h-full flex flex-col"
                  style={{ maxHeight: "120vh" }}
                >
                  {screenshotdata()}
                  {ssdata && ssdata.length === 0 ? (
                    <div className="p-5 text-danger">No Screenshots Found</div>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {isopen ? (
        <Modal isOpen={isopen} onRequestClose={()=>closeModal()} style={customStyles} contentLabel="">
          <div
            className="row header sticky z-50"
            style={{ padding: "9px", background: "#1e40af", color: "white" }}
          >
            <div className="flex items-center">
              <h2 className="font-medium text-base mr-auto">
                {format(new Date(popupdata.date), "yyyy-MM-dd")} {popupdata.time.slice(0, 5)}
              </h2>

              <button disabled={disable} className="btn" onClick={previmg}>
                <span> Previous</span>
              </button>

              <button
                disabled={disablenext}
                className="btn mx-2"
                onClick={nextimg}
              >
                <span>Next</span>
              </button>

              <button
                className="btn btn-outline-secondary hidden sm:flex"
                onClick={() => closeModal()}
              >
                <FaTimes />
              </button>
            </div>
          </div>
          {loading ? (
            <div className="text-center m-5 p-5">
              <HashLoader
                color="#5755d9"
                size={30}
                style={{ position: "absolute", right: "50%" }}
              />
            </div>
          ) : (
            <div
              className="wrapper-grey pt-1"
              style={{ marginRight: "10px", padding: "10px" }}
            >
              <div className="intro-x chat grid grid-cols-12 gap-5 mt-2 ">
                <div
                  className="loading  col-span-12 lg:col-span-9 2xl:col-span-9 overflow-auto"
                  style={{ position: "relative" }}
                >
                  <TransformWrapper initialScale={1}>
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                      <React.Fragment>
                        <div
                          className="tools flex-auto "
                          style={{
                            position: "absolute",
                            zIndex: "1",
                            right: "5%",
                            top: "85%",
                          }}
                        >
                          <button
                            className="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                            data-te-toggle="tooltip"
                            title="ZoomIn"
                            onClick={() => zoomIn()}
                          >
                            <div
                              className="p-2 px-5 mx-1  "
                              style={{
                                backgroundColor: "#CBD5E0",
                                borderRadius: "8px",
                              }}
                            >
                              <AiOutlineZoomIn />
                            </div>
                          </button>
                          <button
                            className="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                            data-te-toggle="tooltip"
                            title="ZoomOut"
                            onClick={() => zoomOut()}
                          >
                            <div
                              className="p-2 px-5 mx-1 "
                              style={{
                                backgroundColor: "#CBD5E0",
                                borderRadius: "8px",
                              }}
                            >
                              <AiOutlineZoomOut />
                            </div>
                          </button>
                          <button
                            className="transititext-primary text-primary transition duration-150 ease-in-out hover:text-primary-600 focus:text-primary-600 active:text-primary-700 dark:text-primary-400 dark:hover:text-primary-500 dark:focus:text-primary-500 dark:active:text-primary-600"
                            data-te-toggle="tooltip"
                            title="Reset"
                            onClick={() => resetTransform()}
                          >
                            <div
                              className="p-2 px-5 mx-1 "
                              style={{
                                backgroundColor: "#CBD5E0",
                                borderRadius: "8px",
                              }}
                            >
                              <AiOutlineCloseCircle />
                            </div>
                          </button>
                        </div>
                        <TransformComponent>
                          <img
                            alt=""
                            className="img-fluid"
                            src={
                              loading ? (
                                <HashLoader />
                              ) : (
                                `https://d3efhqyfy32gfi.cloudfront.net/screenshot/${
                                  popupdata.username
                                }/${format(
                                  new Date(popupdata.date),
                                  "yyyy-MM-dd"
                                )}/${popupdata.image_name}`
                              )
                            }
                          />
                        </TransformComponent>
                      </React.Fragment>
                    )}
                  </TransformWrapper>
                </div>
                <div className="col-span-12 lg:col-span-3">
                  <div className="2xl:border-l -mb-10 pb-10">
                    <div className="2xl:pl-6 grid grid-cols-12 gap-x-6 2xl:gap-x-0 gap-y-6">
                      <div className="col-span-12 2xl:mt-8">
                        <div className="intro-x flex items-center h-5">
                          <h2 className="text-lg font-medium truncate mr-5">
                            Activity Level
                          </h2>
                        </div>
                        <div className="mt-5">
                          <div className="intro-x">
                            <div className="box">
                              <div className="col-span-12 sm:col-span-12 xl:col-span-2 intro-y">
                                <div className="intro-y box bg-blue">
                                  <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                                    <small>Activity Level in %</small>
                                  </div>
                                  <div
                                    id="progressbar-height"
                                    style={{ display: "flex" }}
                                    className="p-3 pl-0"
                                  >
                                    {progressbardata(popupdata)}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="box mt-2">
                              <div className="col-span-12 sm:col-span-12 xl:col-span-2 intro-y">
                                <div className="intro-y box pl-3 p-2">
                                  <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                                    <b>Duration : {popupdata.diff_prev_ss!=="" ? popupdata.diff_prev_ss : '00:00:00'} mm:ss</b>
                                  </div>
                                  <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                            <br />
                            <hr
                              style={{ border: "1px solid rgb(229 231 235)" }}
                            />
                            <br />
                          </div>
                        </div>

                        <div className="intro-x flex items-center h-5">
                          <h2 className="text-lg font-medium truncate mr-5">
                            Activity Details
                          </h2>
                        </div>

                        <div className="box mt-2">
                          <div className="col-span-12 sm:col-span-12 xl:col-span-2 intro-y">
                            <div className="intro-y box pl-3 p-2">
                              <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                                <b>Mouse Clicked : {popupdata.clicks} Count</b>
                              </div>                              
                            </div>
                          </div>
                        </div>

                        <div className="box mt-2">
                          <div className="col-span-12 sm:col-span-12 xl:col-span-2 intro-y">
                            <div className="intro-y box pl-3 p-2">
                              <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                                <b>Mouse Moved : {popupdata.mouseevent} Count</b>
                              </div>
                              
                            </div>
                          </div>
                        </div>

                        <div className="box mt-2">
                          <div className="col-span-12 sm:col-span-12 xl:col-span-2 intro-y">
                            <div className="intro-y box pl-3 p-2">
                              <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                                <b>Key Down : {popupdata.keyboardevent} Count</b>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                        {popupdata.project!=="" && <>
                        <br />
                            <hr
                              style={{ border: "1px solid rgb(229 231 235)" }}
                            />
                            <br />
                        <div className="intro-x flex items-center h-5">
                          <h2 className="text-lg font-medium truncate mr-5">
                            Project Details
                          </h2>
                        </div>
                      <br />
                        <div className="box mt-2">
                          <div className="col-span-12 sm:col-span-12 xl:col-span-2 intro-y">
                            <div className="intro-y box pl-3 p-2">
                              <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                                <b>Project : {popupdata.project}</b>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                        <div className="box mt-2">
                          <div className="col-span-12 sm:col-span-12 xl:col-span-2 intro-y">
                            <div className="intro-y box pl-3 p-2">
                              <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                                <b>Activity : {popupdata.activity}</b>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                        <div className="box mt-2">
                          <div className="col-span-12 sm:col-span-12 xl:col-span-2 intro-y">
                            <div className="intro-y box pl-3 p-2">
                              <div className="flex flex-col sm:flex-row items-center p-0 pt-2 ml-2">
                                <b>Memo : {popupdata.memo}</b>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                        </>    
                        }     
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-span-12 ">
                  <div className="2xl:border-l">
                    <div className="2xl:pl-6 grid grid-cols-12 gap-x-6 lg:gap-x-0 gap-y-6">
                      <div className="col-span-12 mt-3 2xl:mt-8">
                        <div className="intro-x flex items-center h-5">
                          <h2 className="text-lg font-medium truncate mr-5">
                            Application Usage
                          </h2>
                        </div>

                        <div
                          className="appdetailsdiv"
                          style={{ display: "flex", flexFlow: "wrap" }}
                        >
                          {appdetails()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
      ) : null}
    </>
  );
};

export default Screenshots;
