import React, { useEffect, useState } from "react";
import { BASEURL } from "../BASEURL";
import { format } from "date-fns";
import { FaCalendar, FaTimes } from "react-icons/fa";
import { useLoadingContext } from "react-router-loading";
import HashLoader from "react-spinners/HashLoader";
import "react-circular-progressbar/dist/styles.css";
import reload from '../../reload.png'
import ReactMonthPicker from "react-month-picker";
import "react-month-picker/css/month-picker.css";

const MonthlyAttendance = () => {
  const loadingContext = useLoadingContext();

  let today = new Date();
  let month = [];
  month[0] = "1";
  month[1] = "2";
  month[2] = "3";
  month[3] = "4";
  month[4] = "5";
  month[5] = "6";
  month[6] = "7";
  month[7] = "8";
  month[8] = "9";
  month[9] = "10";
  month[10] = "11";
  month[11] = "12";

  let [loading, setLoading] = useState(false);
  const [userdata, setuserdata] = useState([]);
  const [ssdata, setssdata] = useState([]);
  const [icons, seticons] = useState([]);
  const [projatt, setAttendance] = useState([]);
  const [totalduration, settotalduration] = useState("");
  const [date, setdate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [username, setusername] = useState();
  const [currdate, setcurrdate] = useState(new Date());
  const [heading, setheading] = useState(" ");
  const [orgval, setorgval] = React.useState([]);
  const [isVisible, setisVisible] = useState(false);
  const [monthYear, setmonthYear] = useState({});
  let monthNumber = month[today.getMonth()];
  const curryear = today.getFullYear();
  const currmonth = monthNumber;
  const [selectedmonth, setselectedmonth] = useState({ year: curryear, month: Number(currmonth) });    

  useEffect(() => {
    // let date = format(new Date(), "yyyy-MM-dd");
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    let value = JSON.parse(localStorage.getItem("monthyear"));
    const month = value ? value.month : format(new Date(), "MM");
    const year = value ? value.year : format(new Date(), "yyyy");
    setusername(userdata.username);
    getScreenshotData(userdata.username, { year, month });

    return () => {
      setusername(userdata.username);
    };
  }, []);

  // document.addEventListener("click", (evt) => {
  //   const flyoutEl = document.getElementById("mp");
  //   let targetEl = evt.target; // clicked element      
  //   do {
  //     if(targetEl === flyoutEl) {
  //       // This is a click inside, does nothing, just return.
  //       document.getElementById("mp").textContent = "Clicked inside!";
  //       return;
  //     }
  //     // Go up the DOM
  //     targetEl = targetEl.parentNode;
  //   } while (targetEl);
  //   // This is a click outside.      
  //   document.getElementById("mp").textContent = "Clicked outside!";
  // });
  
  // const getorg = async (org) => {
  //   setorgval(org);
  //   org = org.value

  // //   setselectedorg(org);
  // //     getUserData(org);
  // }

  // const reloadFun = ()=> {
  //   getScreenshotData(username, date);
  // }
 

  const getScreenshotData = async (username,{ year, month }) => {
    // console.log(year, month); return
    setLoading(true);
    const myHeaders = new Headers();
    let userdata = JSON.parse(localStorage.getItem("isAuth"));
    // console.log(userdata.usertoken);
    if (userdata != null) {
      let token = userdata.usertoken;
      myHeaders.append("Authorization", token);
    }
    myHeaders.append("Content-Type", "application/json");
    await fetch(BASEURL + `get-client-monthlywork/`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        // date,
        username,
        month,
        year,
        client_id:JSON.parse(localStorage.getItem("isAuth")).id,
        isclient:1
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 201) {
          setusername(username);
          setssdata(res.data);
          seticons(res.icons);
          setAttendance(res.attres);
          if(res.attres && res.attres.length>0){
            let decduration = res.attres.map(item=>timeStringToFloat(item.duration)).reduce((a,b)=>a+b)
            // console.log(decduration);
            var n = new Date(0,0);
            n.setSeconds(+decduration * 60 * 60);
            settotalduration(n?.toTimeString().slice(0, 8));
          }
        } else {
          setssdata([]);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
    loadingContext.done();
  };

  function timeStringToFloat(time) {
    var hoursMinutes = time.split(/[.:]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return hours + minutes / 60;
  }

  // const handledate = (e) => {
  //   let date;
  //   if (e !== null) {
  //     setcurrdate(e);
  //     date = format(new Date(e), "yyyy-MM-dd");
  //   } else {
  //     date = format(new Date(), "yyyy-MM-dd");
  //     setcurrdate(new Date());
  //   }
  //   setdate(date);
  //   getScreenshotData(username, date);
  // };

  function formatTime(seconds) {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.round(seconds % 60);
    return [h, m > 9 ? m : h ? "0" + m : m || "0", s > 9 ? s : "0" + s]
      .filter(Boolean)
      .join(":");
  }



  // const progressbardata = (item) => {
  //   if (
  //     item.clicks === 0 &&
  //     item.keyboardevent === 0 &&
  //     item.mouseevent === 0
  //   ) {
  //     return (
  //       <div className="progress h-2  mr-1 mb-1 mt-1 ">
  //         <div
  //           className="progress-bar"
  //           style={{
  //             background: "rgb(255 0 0)",
  //             fontSize: "5px",
  //             width: "100%",
  //           }}
  //           role="progressbar"
  //           aria-valuenow="0"
  //           aria-valuemin="0"
  //           aria-valuemax="100"
  //         ></div>
  //       </div>
  //     );
  //   }

  //   if (
  //     item.clicks !== 0 &&
  //     item.keyboardevent !== 0 &&
  //     item.mouseevent !== 0
  //   ) {
  //     return (
  //       <div className="progress h-2   mr-1 mb-1 mt-1  ">
  //         <div
  //           className="progress-bar"
  //           style={{ background: "#00a700", fontSize: "5px", width: "100%" }}
  //           role="progressbar"
  //           aria-valuenow="0"
  //           aria-valuemin="0"
  //           aria-valuemax="100"
  //         ></div>
  //       </div>
  //     );
  //   }
  //   if (
  //     item.clicks === 0 &&
  //     item.keyboardevent === 0 &&
  //     item.mouseevent !== 0
  //   ) {
  //     return (
  //       <div className="progress h-2    mr-1 mb-1 mt-1">
  //         <div
  //           className="progress-bar"
  //           style={{ background: "#00a700", fontSize: "5px", width: "40%" }}
  //           role="progressbar"
  //           aria-valuenow="0"
  //           aria-valuemin="0"
  //           aria-valuemax="40"
  //         ></div>
  //       </div>
  //     );
  //   }
  //   if (
  //     item.clicks === 0 &&
  //     item.keyboardevent !== 0 &&
  //     item.mouseevent === 0
  //   ) {
  //     return (
  //       <div className="progress h-2   mr-1 mb-1 mt-1">
  //         <div
  //           className="progress-bar"
  //           style={{ background: "#00a700", fontSize: "5px", width: "40%" }}
  //           role="progressbar"
  //           aria-valuenow="0"
  //           aria-valuemin="0"
  //           aria-valuemax="40"
  //         ></div>
  //       </div>
  //     );
  //   }
  //   if (
  //     item.clicks !== 0 &&
  //     item.keyboardevent === 0 &&
  //     item.mouseevent !== 0
  //   ) {
  //     return (
  //       <div className="progress h-2   mr-1 mb-1 mt-1">
  //         <div
  //           className="progress-bar"
  //           style={{ background: "#00a700", fontSize: "5px", width: "60%" }}
  //           role="progressbar"
  //           aria-valuenow="0"
  //           aria-valuemin="0"
  //           aria-valuemax="60"
  //         ></div>
  //       </div>
  //     );
  //   }
  //   if (
  //     item.clicks !== 0 &&
  //     item.keyboardevent !== 0 &&
  //     item.mouseevent === 0
  //   ) {
  //     return (
  //       <div className="progress h-2   mr-1 mb-1 mt-1">
  //         <div
  //           className="progress-bar"
  //           style={{ background: "#00a700", fontSize: "5px", width: "70%" }}
  //           role="progressbar"
  //           aria-valuenow="0"
  //           aria-valuemin="0"
  //           aria-valuemax="70"
  //         ></div>
  //       </div>
  //     );
  //   }
  //   if (
  //     item.clicks !== 0 &&
  //     item.keyboardevent === 0 &&
  //     item.mouseevent === 0
  //   ) {
  //     return (
  //       <div className="progress h-2   mr-1 mb-1 mt-1">
  //         <div
  //           className="progress-bar"
  //           style={{ background: "#00a700", fontSize: "5px", width: "70%" }}
  //           role="progressbar"
  //           aria-valuenow="0"
  //           aria-valuemin="0"
  //           aria-valuemax="70"
  //         ></div>
  //       </div>
  //     );
  //   }
  //   if (
  //     item.clicks === 0 &&
  //     item.keyboardevent !== 0 &&
  //     item.mouseevent !== 0
  //   ) {
  //     return (
  //       <div className="progress h-2   mr-1 mb-1 mt-1">
  //         <div
  //           className="progress-bar"
  //           style={{ background: "#00a700", fontSize: "5px", width: "70%" }}
  //           role="progressbar"
  //           aria-valuenow="0"
  //           aria-valuemin="0"
  //           aria-valuemax="70"
  //         ></div>
  //       </div>
  //     );
  //   }
  // };


  const getMonthValue = () => {
    let value = JSON.parse(localStorage.getItem("monthyear"));
    const month = value
      ? value.monthname
      : monthYear && monthYear.month
      ? monthYear.month
      : 0;
    const year = value
      ? value.year
      : monthYear && monthYear.year
      ? monthYear.year
      : 0;

    let date = new Date();
    let curryear = date.getFullYear();

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return month && year
      ? `${month}-${year}`
      : monthNames[currmonth - 1] + "-" + curryear;
  };

  const showMonthPicker = (e) => {
    setisVisible(true);
    e.preventDefault();
  };

  const handleOnDismiss = () => {
    setisVisible(false);
  };

  const handleOnChange = (year, month) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let monthname = monthNames[month - 1];
    localStorage.setItem(
      "monthyear",
      JSON.stringify({ year, monthname, month: month })
    );
    setisVisible(false);
    setmonthYear({ year, month: monthname });
    setselectedmonth({ year, month: Number(monthname) });
    getScreenshotData(username,{ year, month });
  };

  return (
    <>
      <div className="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 className="text-lg font-medium mr-auto">Monthly Work</h2>

        <div className="h-auto" id="mp">
          <div className="text-left">
          
          <div className="input-group mr-2 calenderdate">
              <input
                type="text"
                style={{ fontSize: "12px" }}
                onFocus={(e) => showMonthPicker(e)}
                value={getMonthValue()}
                className="form-control calenderdate1"
                id="basic-url"
                aria-describedby="basic-addon3"
              />
              <div className="input-group-append calendaricon">
                <FaCalendar
                  style={{ fontSize: "12px" }}
                  onClick={(e) => showMonthPicker(e)}
                />
              </div>
            </div>

            {loading ? (
              <div className="text-center m-5 p-5">
                <HashLoader
                  color="#5755d9"
                  size={30}
                  style={{ position: "absolute", right: "50%" }}
                />
              </div>
            ) : (
              <ReactMonthPicker
                className="rmp"
                show={isVisible}
                lang={[
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ]}
                value={selectedmonth}
                onChange={handleOnChange}
                onDismiss={handleOnDismiss}
              />
            )}
            
            {/* <button className="ml-1 btn btn-default">
                  <img width={15} onClick={reloadFun} src={reload} alt="" />
                 </button> */}

          </div>
        </div>
      </div>

      <div className="intro-y chat grid grid-cols-12 mt-2">
        <div className="col-span-12 lg:col-span-12 2xl:col-span-12">
          <div className="tab-content">
            <div
              id="chats"
              className="tab-pane active"
              role="tabpanel"
              aria-labelledby="chats-tab"
            >
             
              <div className="chat__chat-list overflow-y-auto scrollbar-hidden ">
                {userdata &&
                  userdata.map((item, i) => {
                    return (
                      <div
                        className={
                          heading === item.name
                            ? "intro-x cursor-pointer box relative flex items-center mt-2 p-2 activeuser"
                            : "intro-x cursor-pointer box relative flex items-center mt-2 p-2"
                        }
                        onClick={() =>
                          getScreenshotData(item.username, date, item.name)
                        }
                        key={i}
                      >
                        <div className="w-12 h-12 flex-none image-fit mr-1 usericon">
                          {
                            <>
                              <div className="rounded-full w-3 bg-blue">
                                {item.name.slice(0, 1)}
                              </div>
                            </>
                          }
                        </div>

                        <div className="ml-2 overflow-hidden">
                          <div className="flex items-center">
                            <div className="font-medium text-left d-flex">
                              {item.name}
                            </div>
                          </div>
                        </div>
                          <div className="ml-2 overflow-hidden">
                            <div className="flex items-center">
                              <div className="font-medium text-left d-flex">
                                {item.isactive === 1 ? 
                                <div
                                  style={{
                                    borderRadius: "50%",
                                    background: "#0ff10f",
                                    width: "10px",
                                    height: "10px",
                                  }}
                                ></div>
                                :
                                <div
                                  style={{
                                    borderRadius: "50%",
                                    background: "red",
                                    width: "10px",
                                    height: "10px",
                                  }}
                                ></div>
                                }
                              </div>
                            </div>
                          </div>
                        
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>

        <div className="intro-y col-span-12 lg:col-span-12 2xl:col-span-12">
          <div className=" box">
            {loading === true ? (
              <div className="mt-5 " style={{ display: "-webkit-inline-box" }}>
                <HashLoader color="#5755d9" size={30} />
              </div>
            ) : (
              <>
                {/* <div
                  className="flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div>
                    <h2 className="intro-y text-lg text-left">{heading}</h2>
                  </div>
                  <div className="my-4 mx-4">
                    <select onChange={getsort} className="form-control">
                      <option>Newest</option>
                      <option defaultValue>Oldest</option>
                    </select>
                  </div>
                </div>
                <hr className="dropdown-divider " /> */}
                <div
                  className=" overflow-y-auto scrollbar h-full flex flex-col"
                  // style={{ maxHeight: "120vh" }}
                >
                 <div className="overflow-x-auto p-5 text-left">
                      <h5><b>Attendance</b></h5>
                                    <div className="table-responsive">
                                        <table className="table table-bordered" style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                <th className="whitespace-nowrap">User</th>
                                                <th className="whitespace-nowrap">Project</th>
                                                <th className="whitespace-nowrap">Activity</th>
                                                <th className="whitespace-nowrap">Memo</th>
                                                <th className="whitespace-nowrap">Date</th>
                                                <th className="whitespace-nowrap">Start Time</th>
                                                <th className="whitespace-nowrap">End Time</th>
                                                <th className="whitespace-nowrap">Total Time</th>
                                                       
                                                </tr>
                                            </thead>
                                            <tbody>
                                            
                        { projatt.length>0 ? projatt?.map(item =>{
                          return (<>
                            <tr>
                            <td>{item.name}</td>
                            <td>{item.project}</td>
                            <td>{item.activity ? item.activity:"-"}</td>
                            <td>{item.memo ? item.memo:"-"}</td>
                            <td>{format(new Date(item.date), "yyyy-MM-dd")}</td>
                            <td>{item.start_time}</td>
                            <td>{item.end_time}</td>
                            <td>{item.duration}</td> </tr>
                            </>
                            )
                        }):<tr> <td colSpan={6}>No data found</td> </tr>
                        }
                                            
                        { projatt.length>0 &&
                          <tr>
                            <td colSpan={7}><b>Total: </b></td>
                            <td><b>{totalduration}</b></td>
                            </tr>                            
                        }
                       
                                            
                                            </tbody>
                                            </table></div></div>
                  
                </div>
              </>
            )}
          </div>
        </div>
      </div>

    </>
  );
};

export default MonthlyAttendance;
